import React, { useContext } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { ImportExport } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import { FetchContext } from "../../context/FetchContext";
import { DateTime } from "luxon";

const UpTimeReportExport = ({ filterObject, byMonth }) => {
  const fetchContext = useContext(FetchContext);

  const asyncFnComputeData = async () => {
    let curStr = DateTime.now();
    let dateParam;
    let selMonthStr = byMonth.split("-");
    let selMonth = Number(selMonthStr[1]);
    if (selMonth === curStr.month) {
      dateParam = curStr.toFormat("dd-MM-yyyy");
    } else {
      dateParam = byMonth;
    }
    const dataArr = [];

    const finalPayload = {
      ocpp_cbid: filterObject?.ocpp_cbid,
      from_date: filterObject?.from_date,
      to_date: filterObject?.to_date,
      export: true,
      range: filterObject?.range,
      with_max: filterObject?.with_max,
      date: dateParam,
    };

    await fetchContext.authAxios
      .post(`/get-up-time-report`, finalPayload)
      .then(({ data }) => {
        const pdata = data.data;
        if (pdata) {
          for (let item of pdata) {
            //dataObj.sessionID = item.id;
            let obj = {
                charger_id:item?.charger_id,
                uptimes:item?.uptimes
            };
            dataArr.push(obj);
          }
        }
      });
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "charger_id", displayName: "charger id" },
    { id: "uptimes", displayName: "uptimes" },
  ];

  return (
    <>
      <CsvDownloader
        filename="UptimeReportDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          <ImportExport />
        </Tooltip>
      </CsvDownloader>
    </>
  );
};

export default UpTimeReportExport;
