import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
formControl: {
  margin: theme.spacing(1),
  width: "20vw"
},
selectEmpty: {
  marginTop: theme.spacing(2)
},
select: {
  //height: "10vh"
},
inputLabel: {
  //fontSize: "4vh",
  alignSelf: "center"
}
}));



const ChargerConnectorSelect = (props) => {
  const classes = useStyles();
  // const connectorType = ['CHADEMO', 'DOMESTIC_A', 'DOMESTIC_B', 'DOMESTIC_C', 'DOMESTIC_D', 'DOMESTIC_E', 'DOMESTIC_F', 'DOMESTIC_G', 'DOMESTIC_H', 'DOMESTIC_I', 'DOMESTIC_J', 'DOMESTIC_K', 'DOMESTIC_L', 'IEC_60309_2_single_16', 'IEC_60309_2_three_16', 'IEC_60309_2_three_32', 'IEC_60309_2_three_64', 'IEC_62196_T1','IEC', 'IEC_62196_T1_COMBO', 'IEC_62196_T2','IEC', 'IEC_62196_T2_COMBO', 'IEC_62196_T3A','IEC', 'IEC_62196_T3C','IEC', 'PANTOGRAPH_BOTTOM_UP', 'J1772', 'CCS'];
  const connectorType = ['CHADEMO', 'J1772', 'CCS', 'CCS A', 'CCS B' ,'NACS','CCS A-1','CCS B-2','CCS A-3','CCS B-4'];
  const { name, label, value, fullWidth=true, error=null, onChange } = props;

  return (
    <FormControl variant="standard" className={classes.formControl}
    style={{ width: "95%" }}
    {...(error && {error:true})}>
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        <MuiSelect
            className={classes.select}
            variant="standard"
            label={label}
            name={name}
            value={value}
            //options={values}
            fullWidth={fullWidth}
            onChange={onChange}>   
            {
              connectorType.map( (item) => (
                <MenuItem value={item}>{item}</MenuItem> 
              ))
            }            
        </MuiSelect>
        {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default ChargerConnectorSelect;
