import { FormControl, FormControlLabel, FormLabel, RadioGroup as MuiRadioGroup, Radio } from '@material-ui/core';
import React from 'react';

const SiteEVSERadioGroup = (props) => {

    const { name, value, onChange } = props;

    return (
        <FormControl>
            <FormLabel>Site/EVSE</FormLabel>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange}>
                {/* <FormControlLabel  value={"1"} control={<Radio />} label={"Administrator"} /> */}
                <FormControlLabel value={"1"} control={<Radio />} label={"Site"} />
                <FormControlLabel value={"2"} control={<Radio />} label={"EVSE"} />
            </MuiRadioGroup>
        </FormControl>
    )
}

export default SiteEVSERadioGroup;