export const formatCreatedAt = (createdAt) => {
    const [datePart, timePart] = createdAt.split(' '); // Split date and time
    const dateObj = new Date(datePart); // Convert the date part to a Date object
  
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for the day
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for the month
      const year = date.getFullYear(); // Get the year
      return `${month}-${day}-${year}`; // Return in MM-dd-yyyy format
    };
  
    return `${formatDate(dateObj)} ${timePart}`; // Format the date and return with time
  };
  