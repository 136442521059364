import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  FormLabel,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import Notification from "../../components/Notification";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";
import Popup from "../../components/Popup";
import CreateModel from "./CreateModel";
import { useIsDesktop } from "../../context/DisplayContext";

const initialFormValues = {
  name: "",
  manager_name: "",
  manager_email: "",
  manager_number: "",
};

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    fontFamily: "Aldrich",
    color: theme.palette.secondary.main,
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    // padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
}));
export default function Manufacturer() {
  const fetchContext = useContext(FetchContext);
  const [manufacturers, setManufacturers] = useState([]);
  const [manufacturersEdit, setManufacturersForEdit] = useState(null);
  const { isDesktop } = useIsDesktop();

  const [page, setPage] = useState(1);
  const [counts, setCounts] = useState(1);
  const [value, setValue] = React.useState("1");
  const [totalRecord, setTotalRecord] = useState(0);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [manufacturerNameError, setManufacturerNameError] = useState("");

  // const [editFormData, setEditFormData] = useState("");
  const [editFormData, setEditFormData] = useState({
    id: "",
    name: "",
    manager_name: "",
    manager_email: "",
    manager_number: "",
  });
  const [editerrors, setediterrors] = useState({
    name: "",
    manager_name: "",
    manager_email: "",
    manager_number: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("manager_name" in fieldValues)
      temp.manager_name = fieldValues.manager_name
        ? ""
        : "This field is required.";
    if ("manager_email" in fieldValues)
      temp.manager_email =
        /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          fieldValues.manager_email
        )
          ? ""
          : "Email is not valid.";
    if ("manager_number" in fieldValues)
      temp.manager_number = /^\d{10}$/.test(fieldValues.manager_number)
        ? ""
        : "Phone number is not valid.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let headCells = [
    { id: "manufacturer", label: "Manufacturer" },
    { id: "models", label: "Charger Models" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "Contact", label: "Contact" },
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const { TblContainer, TblHead } = useTable(headCells);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  // const { values, handleInputChange } = useForm(initialFormValues);
  // const { values, setErrors, handleInputChange, resetForm, errors } = useForm({ name: ''validate });

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFormValues,
    true,
    validate
  );

  useEffect(() => {
    getAll(fetchContext, page);
  }, [fetchContext.authAxios, page]);

  const getAll = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.get(
        `/manufacturers?page=${page}`
      );
      setManufacturers(data.data.data);
      setTotalRecord(data.data.total);

      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoading(true);
    } catch (err) {
      // console.log('Error fetching data:', err);
    }
  };

  const handleChangePage = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    setOpenPopup2(true);
    event.preventDefault();
    fetchContext.authAxios
      .post("/manufacturers", values)
      .then((response) => {
        if (response.status === 200 && response?.data?.status) {
          setNotify({
            isOpen: true,
            message: "Manufacturer added successfully",
            type: "success",
          });
          resetForm();
          setOpenPopup2(false);

          // Fetch latest data after successful submission
          fetchLatestManufacturers();
        } else {
          setNotify({
            isOpen: true,
            message: response?.data?.errors?.name[0] || "Something went wrong",

            type: "error",
          });
        }
      })
      .catch((error) => {
        const code = error && error.response ? error.response.status : 0;
        if (code === 401 || code === 403) {
          console.log("error", code);
        }
        if (code === 422) {
          return error.response;
        } else {
          return Promise.reject(error);
        }
        if (error.status == false) {
          setNotify({
            isOpen: true,
            message:
              "There was an error adding the manufacturer. Please try again.",
            type: "error",
          });
        } else {
          setNotify({
            isOpen: true,
            message:
              "There was an error adding the manufacturer. Please try again.",
            type: "error",
          });
        }
      });
  };

  const fetchLatestManufacturers = () => {
    fetchContext.authAxios
      .get("/manufacturers")
      .then((response) => {
        if (response.status === 200 && response?.data.data) {
          setManufacturers(response.data.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching manufacturers:", error);
      });
  };

  const handleDelete = async (id) => {
    // try {
    //   await fetchContext.authAxios.delete(`/site-groups/${id}`);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }
    fetchContext.authAxios
      .delete(`/manufacturers/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoading(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: data?.data || "Something went wrong",

            type: "error",
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Manufacturer can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);
    // setNotify({
    //     isOpen: true,
    //     message: 'Deleted Successfully',
    //     type: 'error'
    // })
  };

  const handleEditClick = async (id) => {
    setOpenPopup(true);
    try {
      const response = await fetchContext.authAxios.get(`/manufacturers/${id}`);
      if (response.status === 200 && response?.data.data) {
        const { id, name, manager_name, manager_email, manager_number } =
          response.data.data;
        setEditFormData({
          id,
          name,
          manager_name,
          manager_email,
          manager_number,
        });
      }
    } catch (error) {
      console.error("Error fetching manufacturer data:", error);
    }
  };
  // Inside the Form component for the popup
  const handleEditSubmit = (event, id) => {
    event.preventDefault();
    if (
      editFormData.manager_number &&
      !/^\d{10}$/.test(editFormData.manager_number)
    ) {
      setNotify({
        isOpen: true,
        message: "Phone number must be 10 digits.",
        type: "error",
      });
      setediterrors({ ...editerrors, manager_number: "Enter Valid Number" });
      return;
    }
    if (
      editFormData.manager_email &&
      !/^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        editFormData.manager_email
      )
    ) {
      setediterrors({ ...editerrors, manager_email: "Enter Valid Email" });

      setNotify({
        isOpen: true,
        message: "Invalid email address.",
        type: "error",
      });
      return;
    }

    fetchContext.authAxios
      .put(`/manufacturers/${id}`, {
        id: editFormData.id,
        name: editFormData.name,
        manager_name: editFormData.manager_name,
        manager_email: editFormData.manager_email,
        manager_number: editFormData.manager_number,
      })
      .then((response) => {
        if (response.status === 200 && response?.data?.status) {
          setediterrors();
          setNotify({
            isOpen: true,
            message: "Manufacturer updated successfully",
            type: "success",
          });
          fetchLatestManufacturers();
          setOpenPopup(false); // Close the popup after successful submission
        } else {
          setediterrors();
          setNotify({
            isOpen: true,
            message: response?.data?.errors?.name[0] || "Something went wrong",

            type: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating manufacturer:", error);
        setediterrors();
        setNotify({
          isOpen: true,
          message:
            "There was an error updating the manufacturer. Please try again.",
          type: "error",
        });
      });
  };

  const [openPopup2, setOpenPopup2] = useState(false);
  const handleopen = async (id) => {
    setOpenPopup2(true);
  };

  const handle2Close = () => {
    setOpenPopup2(false);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const [openPopup3, setOpenPopup3] = useState(false);
  const handleopenformodels = async (id) => {
    setOpenPopup3(true);
  };

  return (
    <>
      {/* <Toolbar>
        <div className={classes.pageTitle}>
          <Typography variant="h5" component="div">Manufacturers</Typography>
        </div>

        <Notification notify={notify} setNotify={setNotify} />

        {fetchContext.isAdmin &&
          <Controls.Button
            style={{ color: "#a31112", border: "1px solid #a31112" }}
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => handleopen()}
          />

        }
      </Toolbar> */}

      <div className={classes.headerContainer}>
        <div className={classes.pageTitle}>Manufacturers</div>
        <div className={classes.filterContainer}>
          {/* <div className={classes.iconAlignment}> */}
          {fetchContext.isAdmin && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              isPaddingTop={false}
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => handleopen()}
            />
          )}
          {/* </div> */}
        </div>
      </div>

      <Popup
        title="Add Charger Manufacturer"
        openPopup={openPopup2}
        setOpenPopup={setOpenPopup2}
      >
        <Form
          onSubmit={handleSubmit}
          // style={{ flexGrow: 1, minWidth: "800px" }}
        >
          <Grid container spacing={0}>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="name"
                label="Manufacturer"
                value={values.name}
                onChange={handleInputChange}
                error={manufacturerNameError !== ""}
                required
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="manager_name"
                label="Manager Name"
                value={values.manager_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="manager_email"
                label="Manager Email"
                type="email"
                value={values.manager_email}
                error={errors.manager_email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="manager_number"
                label="Manager Contact"
                type="number"
                value={values.manager_number}
                onChange={handleInputChange}
                error={errors.manager_number}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button
              text="Cancel"
              color="default"
              onClick={handle2Close}
            />
          </Grid>
        </Form>
      </Popup>

      <div>
        {/* Your table */}
        <TblContainer>
          <TblHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              ))}
            </TableRow>
          </TblHead>
          {loading ? (
            <TableBody>
              {manufacturers.map((manufacturer) => (
                <TableRow key={manufacturer.id}>
                  <TableCell>{manufacturer.name}</TableCell>

                  <TableCell>
                    {/* Render charger model names with commas */}
                    {manufacturer?.charger_models?.length > 0 &&
                      manufacturer?.charger_models?.map((model, modelIndex) => (
                        <span key={modelIndex}>
                          {model.name}
                          {/* Add a comma if it's not the last model */}
                          {modelIndex !==
                            manufacturer.charger_models.length - 1 && ", "}
                        </span>
                      ))}
                  </TableCell>
                  {/* <TableCell>
        {manufacturer.charger_models.length > 0 ? manufacturer.charger_models[0].name : ""}
      </TableCell> */}
                  <TableCell>{manufacturer.manager_name}</TableCell>
                  <TableCell>{manufacturer.manager_email}</TableCell>
                  <TableCell>{manufacturer.manager_number}</TableCell>

                  {fetchContext.isAdmin && (
                    <TableCell className="text-nowrap">
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => handleEditClick(manufacturer.id)}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(manufacturer.id);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>

        {/* Pagination */}
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </div>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Edit Manufacturer"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form
          onSubmit={handleEditSubmit}
          style={{ flexGrow: 1, minWidth: "800px" }}
        >
          <Grid container spacing={0}>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="name"
                label="Manufacturer"
                value={editFormData.name || ""}
                onChange={(e) => {
                  setEditFormData({ ...editFormData, name: e.target.value });
                  setediterrors();
                }}
                error={editerrors?.name}
                required
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="manager_name"
                label="Manager Name"
                value={editFormData.manager_name || ""}
                onChange={(e) => {
                  setEditFormData({
                    ...editFormData,
                    manager_name: e.target.value,
                  });
                  setediterrors();
                }}
                error={editerrors?.manager_name}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="manager_email"
                label="Manager Email"
                type="email"
                value={editFormData.manager_email || ""}
                onChange={(e) => {
                  setEditFormData({
                    ...editFormData,
                    manager_email: e.target.value,
                  });
                  setediterrors();
                }}
                error={editerrors?.manager_email}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="manager_number"
                label="Manager Contact"
                type="number"
                value={editFormData.manager_number || ""}
                onChange={(e) => {
                  setEditFormData({
                    ...editFormData,
                    manager_number: e.target.value,
                  });
                  setediterrors();
                }}
                error={editerrors?.manager_number}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Button type="submit" text="Submit" />
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={handleClose}
              />
            </Grid>
          </Grid>
        </Form>
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />

    </>
  );
}
