import React from "react";
//import { makeStyles } from "@material-ui/core";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
//import { Country }  from 'country-state-city';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import countries from "i18n-iso-countries";

//const temp = Country.getCountryByCode('US');
//const countries = Country.getAllCountries();

// const useStyles = makeStyles(theme => ({
//   popupIndicator: {
//     fill: "#000000"
//   }
// }));

const ContriesSelect = (props) => {
  //const classes = useStyles();
  const { options, inputValue, onChange, onInputChange, getOptionLabel, selectedOption } = props;
  //const filter = createFilterOptions();
  const isOptionEqualToValue = (option, anotherOption) => option.value === anotherOption.value;
  return (
    <Autocomplete
      id="country-select"
      size="small"
      popupIcon={<ArrowDropDownIcon style={{ fill: "#a31112" }} />}
      value={selectedOption}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      //getOptionDisabled={getOptionDisabled}
      //selectedValues={selectedOptions}
      //label={'Select Charging Stations'}
      autoHighlight
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      //getOptionLabel={(option) => option.name}
      // filterOptions={(options, params) => {
      //   const filtered = filter(options, params);

      //   if (params.inputValue !== '') {
      //     filtered.push({
      //       inputValue: params.inputValue,
      //       title: `Add "${params.inputValue}"`,
      //     });
      //   }

      //   return filtered;
      // }}
      renderOption={(props, option) => {
        let alpha3 = countries.alpha2ToAlpha3(option.isoCode);
        return (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.name} ({alpha3})
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ fill: "#a31112", width: "97.5%" }}
          label="Choose a country"
          required
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          variant="standard"
        />
      )}
    />
  );
};

export default ContriesSelect;
