import React, { useState, useCallback} from 'react';
import { makeStyles } from '@material-ui/core';
//import { makeStyles } from '@mui/styles';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@mui/material';
import Controls from "../../components/controls/Controls";
import CloseIcon from '@material-ui/icons/Close';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';


const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        border: '1px solid #8a0a0b',
        borderRadius: '5px',
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
      // textAlign: 'center',
      // justifyItems: 'center'
      margin: '0 auto'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))

const Confirm = (props) => {

    //const { confirmDialog, setConfirmDialog } = props;
    const { id, title,  subTitle, onConfirmClick } = props;
    const [ anchorEl, setAnchorEl ] = useState();
    const classes = useStyles();
    //const wrapper = createRef();
    // const nodeRef = createRef(null);
    // const modRef = nodeRef.current;
    const onClick = useCallback((event) => {
      //modRef = nodeRef.current;
      //setAnchorEl(event.currentTarget);
      setAnchorEl(id);
    }, [id]);
  
    const onClose = useCallback(() => {
      setAnchorEl(undefined);
    }, []);

    const onConfirm = useCallback(() => {
      onClose();
      onConfirmClick();
    }, [onClose, onConfirmClick]);

    return (
      <>        
        <Button  onClick={onClick} size="small" ><CloseIcon fontSize="small" /></Button>
        <Dialog  onClose={onClose} open={Boolean(anchorEl)} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {title}
                </Typography>
                <Typography variant="subtitle2">
                    {subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <div >
                <Controls.Button
                    text="No"
                    color="default"
                    onClick={onClose} />
                <Controls.Button
                    text="Yes"
                    color="secondary"
                    onClick={() => onConfirm()}                
                />
              </div>

            </DialogActions>
        </Dialog>        
      </>
    )
}

export default Confirm;
