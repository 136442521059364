import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const UserRolesRadioGroup = (props) => {

    const { name, value, onChange } = props;

    return (
        <FormControl>
            <FormLabel>{"Or set User Role as"}</FormLabel>
            <MuiRadioGroup col
                name={name}
                value={value}
                onChange={onChange}>
                {/* <FormControlLabel  value={"1"} control={<Radio />} label={"Administrator"} /> */}
                <FormControlLabel  value={"1"} control={<Radio />} label={"View Only"} />
                <FormControlLabel  value={"2"} control={<Radio />} label={"Manage Only"} />
                <FormControlLabel value={"4"} control={<Radio />} label={"Technician"} />
            </MuiRadioGroup>
        </FormControl>
    )
}

export default UserRolesRadioGroup;
