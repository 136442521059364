import React, { useContext } from "react";
import { FetchContext } from "../../../context/FetchContext";
import RevenueSub from "../RevenueSub";
import RevenueForUser from "./RevenueForUser";

export default function RevenueGraphMain() {
  const fetchContext = useContext(FetchContext);
  return fetchContext?.isAdmin ? <RevenueSub /> : <RevenueForUser />;
  // return fetchContext?.isAdmin ? <RevenueForUser /> : <RevenueForUser />;
}
