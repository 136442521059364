import React, { useContext } from "react";
import { FetchContext } from "../../../context/FetchContext";
import SessionBySites from "../SessionBySites";
import ChargerUsageReport from "../ChargerUsageReport/ChargerUsageReport";

export default function SessionBySitesMain() {
  const fetchContext = useContext(FetchContext);
  // return <SessionBySites />
  return fetchContext?.isAdmin ? <SessionBySites /> : <ChargerUsageReport />;
  // return fetchContext?.isAdmin ? <ChargerUsageReport /> : <ChargerUsageReport />;
}
