import React, { useContext } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { FetchContext } from "../../context/FetchContext";
import { ImportExport } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import { DateTime } from "luxon";

const ExportDataBySites = ({ filterDate, byMonth }) => {
  const fetchContext = useContext(FetchContext);

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const asyncFnComputeData = async () => {
    const dataArr = [];
    const finalPayload = {
      ...filterDate,
      export: true,
      date: byMonth,
    };
    await fetchContext.authAxios.post(`/get-report-by-site`, finalPayload).then(({ data }) => {
      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {
          //dataObj.sessionID = item.id;
          let obj = {
            site: item.site_name,
            amount: formatNumber(item.amount),
            energy: Number(item.energy).toFixed(2),
            duration: item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : "-",
            total: item.total,
            charging_station_name: item.charging_station_name,
            address:  item.address?.replace(/,/g, ''),
            city: item.city,
            state: item.state,
            postal_code: item.postal_code,
            latitude: item.latitude,
            longitude: item.longitude,
            parking_amount: item?.parking_amount ? formatNumber(item?.parking_amount) : 0,
            tax_amount: item?.tax_amount ? formatNumber(item?.tax_amount) : 0,
            net_amount: formatNumber(item?.amount - item?.parking_amount - item?.tax_amount),

          }; 
          dataArr.push(obj);
        }
      }
    });
    return Promise.resolve(dataArr);
  };

  const columns =  [
    { 'id': 'site', 'displayName': 'Site Name' },
    { 'id': 'amount', 'displayName': 'Amount (USD)' },
    { 'id': 'energy', 'displayName': 'Energy Delivered (KWH)' },
    { 'id': 'duration', 'displayName': 'Average Charging Duration (Minutes)' },
    { 'id': 'total', 'displayName': 'Number of Sessions' },
    { 'id': 'charging_station_name', 'displayName': 'Charging Station' },
    { 'id': 'address', 'displayName': 'Address' },
    { 'id': 'city', 'displayName': 'City' },
    { 'id': 'state', 'displayName': 'State' },
    { 'id': 'postal_code', 'displayName': 'Zipcode' },
    { 'id': 'latitude', 'displayName': 'Latitude' },
    { 'id': 'longitude', 'displayName': 'Longitude' },
  ];
  


  const revenueFileName = () => {
    if (filterDate?.range) {
      const from_date = filterDate?.from_date ? filterDate?.from_date : "";
      const from_date_parsedDate = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      const from_date_formattedDate = from_date_parsedDate.toFormat("d LLL yyyy");

      const to_date = filterDate?.to_date ? filterDate?.to_date : "";
      const to_date_parsedDate = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      const to_date_formattedDate = to_date_parsedDate.toFormat("d LLL yyyy");
      // return `Revenue Report (${from_date_formattedDate} to ${to_date_formattedDate})`
      if (from_date_formattedDate !== "Invalid DateTime" && to_date_formattedDate !== "Invalid DateTime") {
        return `RevenueReport (${from_date_formattedDate} to ${to_date_formattedDate})`;
      } else {
        return `RevenueRReport`;
      }
    } else {
      const date = filterDate?.date ? filterDate?.date : "";
      const date_parsedDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      const date_formattedDate = date_parsedDate.toFormat("LLL yyyy");
      // return `Revenue Report (${date_formattedDate})`;
      if (date_formattedDate !== "Invalid DateTime") {
        return `RevenueReport (${date_formattedDate})`;
      } else {
        return `RevenueReport`;
      }
    }
  };

  return (
    <>
      <CsvDownloader
        filename={"SiteDetails"}
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          <ImportExport style={{ fill: "#fff" }} />
        </Tooltip>
      </CsvDownloader>
    </>
  );
};

export default ExportDataBySites;
