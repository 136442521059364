import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        },
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch'
        // },
        '& label.Mui-focused': {
            color: '#000',
        },
        '& .MuiInput-input': {
            //paddingRight: '0',
            paddingLeft: '5px',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#000',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#000',
            },
            '&:hover fieldset': {
                borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#000',
            },
        },
    },  
  formControl: {
    margin: theme.spacing(1),
    //width: "120vw"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center",
    paddingLeft: '5px',
  }
}));

const MonthRangeSelect = (props) => {
    const classes = useStyles();
    const { name, label, value, error=null, onChange, required=false } = props;
    
    const monthsRange = [];
    const start = DateTime.now().minus({ months: 12 });
    const end = DateTime.now();

    const interval = Interval.fromDateTimes(start, end);
  
    function* months() {
      let cursor = interval.start.startOf('month');
      while (cursor < interval.end) {
        yield cursor;
        cursor = cursor.plus({ months: 1 });
      }
    }
  
    for (const m of months(interval)) {
        monthsRange.push(m);
        //monthsRange.push(m.toFormat('dd-MM-yyyy'));   
        //monthsRange.push(m.toFormat('LLLL yyyy'));    
    }

    return (
      <FormControl variant="standard" required={required} className={classes.root}
      style={{ width: "95%" }}
      {...(error && {error:true})}>
          <InputLabel className={classes.inputLabel}>{label}</InputLabel>
          <MuiSelect
              style={{ width: "250px" }}
              variant="standard"
              label={label}
              name={name}
              value={value}
              required={required}
              error={error}
              onChange={onChange}>
            {
                monthsRange.map(
                    (item, index) => (<MenuItem key={index} style={{ width: "250px" }} value={item.toFormat('dd-MM-yyyy')}>{item.toFormat('LLLL yyyy')}</MenuItem>)
                )
            }
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
  )
}

export default MonthRangeSelect;
