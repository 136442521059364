import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import SitesSelect from "../Sites/SitesSelect";
import ManufacturersSelectfrom from "./ManufacturersSelect";
import ChargerTypeSelect from "./ChargerTypeSelect";
import DeviceTypeSelect from "./DeviceTypeSelect";
import ChargerACOutputSelect from "./ChargerACOutputSelect";
import ChargerDCOutputSelect from "./ChargerDCOutputSelect";
import ChargerConnectorSelect from "./ChargerConnectorSelect";
import { AddBoxOutlined, RemoveFromQueueOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { FetchContext } from "../../context/FetchContext";

const useStyles = makeStyles((theme) => ({
  connectorGrid: {
    backgroundColor: theme.palette.secondary.light,
    color: "white",
    borderRadius: "3px",
    padding: "3px 0 2px 9px",
  },
}));

const RemoveConnectorConfirmation = ({ closeConfirmationForm, handleConfirm }) => {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Cancel" color="default" onClick={closeConfirmationForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default RemoveConnectorConfirmation;
