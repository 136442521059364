import axios from 'axios';

const socketFetchHttp = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_URL_HTTP,
  headers: {
    "Content-type": "application/json"
  }
});

export { socketFetchHttp };
