import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DateTime } from "luxon";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FetchContext } from "../../../context/FetchContext";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  newButton: {
    position: "absolute",
    right: "0px",
    //width: '75%'
  },
  exportIcon: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}));

const SiteEnergyUsage = ({ byMonth, filterObject, energyRevenue }) => {
  const [data, setData] = useState();
  const [maxValue, setMaxValue] = useState(250);
  const classes = useStyles();
  const fetchContext = useContext(FetchContext);
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(futureDate);
  const [endDate, setEndDate] = useState(new Date());

  const formatDateToDDMMYYYY = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const initialFValues = {
    from_date: startDate,
    to_date: endDate,
  };

  // const getAllTransactions = async (fetchContext, payload) => {
  //   try {
  //     let updatedPayload = {
  //       range: payload?.range,
  //       date: byMonth,
  //       to_date: payload?.to_date,
  //       from_date: payload?.from_date,
  //     };
  //     const { data } = await fetchContext.authAxios.post("/get-energy-revenue", updatedPayload);
  //     handleFilterData(data.data, byMonth, { range: payload?.range, to_date: payload.to_date, from_date: payload.from_date });
  //   } catch (err) {
  //     console.log("the err", err);
  //   }
  // };

  const findTimezon = (dateTime, timezone) => {
    const fDate = dateTime.split(" ");
    const formattedDateTimeInUTC = `${fDate[0]}T${fDate[1]}.000Z`;
    const dateTimeInstance = DateTime.fromISO(formattedDateTimeInUTC);
    return dateTimeInstance.isValid
      ? timezone
        ? dateTimeInstance.setZone(timezone)
        : dateTimeInstance
      : undefined;
  };

  const handleFilterData = (data, date, { range, to_date, from_date }) => {
    const dailyData = {};
    let start;
    let end;
    let transactions = data;
    // if (!transactions) {
    //   return;
    // }

    if (range) {
      let given_to_Date = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      let to_js_date = given_to_Date.toJSDate();

      let given_from_Date = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      let from_js_date = given_from_Date.toJSDate();

      start = DateTime.fromJSDate(from_js_date);
      end = DateTime.fromJSDate(to_js_date);
    } else {
      let givenDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      let startDate = givenDate.toJSDate();
      let endDate = givenDate.endOf("month").toJSDate();

      start = DateTime.fromJSDate(startDate);
      end = DateTime.fromJSDate(endDate);
    }

    let datetime = start.startOf("day");

    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        usage: 0,
        timezone_duration: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }
    transactions.forEach((transaction) => {
      if (transaction.meter_diff && transaction.meter_diff <= 0) {
        return;
      }
      const key = DateTime.fromSQL(transaction.graph_date)
        .startOf("day")
        .toISO();
      if (key && dailyData[key]) {
        let timezone = transaction?.timezone;
        if (transaction?.session_start && transaction?.session_end) {
          // let start_time = transaction?.session_start ? findTimezon(transaction?.session_start, timezone) : undefined;
          // let end_time = transaction?.session_end ? findTimezon(transaction?.session_end, timezone) : undefined;
          // const start_time_for_diff = DateTime.fromFormat(start_time.toFormat("dd-MM-yyyy HH:mm:ss"), "dd-MM-yyyy HH:mm:ss");
          // const end_time_for_diff = DateTime.fromFormat(end_time.toFormat("dd-MM-yyyy HH:mm:ss"), "dd-MM-yyyy HH:mm:ss");
          // const diffInSeconds = end_time_for_diff.diff(start_time_for_diff, "seconds").toObject().seconds;
          // dailyData[key].timezone_duration += diffInSeconds / 60;

          const sessionStart = DateTime.fromFormat(
            transaction?.session_start,
            "yyyy-MM-dd HH:mm:ss"
          );
          const sessionEnd = DateTime.fromFormat(
            transaction?.session_end,
            "yyyy-MM-dd HH:mm:ss"
          );
          const timeDifferenceInSeconds = sessionEnd.diff(
            sessionStart,
            "seconds"
          ).seconds;

          dailyData[key].timezone_duration += timeDifferenceInSeconds / 60;
        }

        dailyData[key].count++;
        dailyData[key].usage +=
          transaction.meter_diff > 0 ? transaction?.meter_diff / 1000 : 0;
      }
    });
    let updatedData = Object.values(dailyData).reduce(
      (acc, { count, datetime, usage, timezone_duration }) => {
        const dt = DateTime.fromMillis(datetime.ts);
        const month = dt.monthLong;
        return [
          ...acc,
          datetime !== undefined && {
            usage: usage,
            average: count ? getRound(usage, count) : 0,
            count: count,
            timezone_duration:
              timezone_duration > 0 && count > 0
                ? (timezone_duration / count).toFixed(2)
                : "0",
            // timezone_duration: timezone_duration > 0 && count > 0 ? (timezone_duration / total_count).toFixed(2) : "0",
            // timezone_duration: timezone_duration > 0 && count > 0 ? (timezone_duration).toFixed(2) : "0",
            //date: datetime.toLocaleString(DateTime.DATE_SHORT),
            date: datetime,
            month,
          },
        ];
      },
      []
    );
    setData(updatedData);
  };

  useEffect(() => {
    // getAllTransactions(fetchContext, { range: filterObject?.range, from_date: filterObject.from_date, to_date: filterObject.to_date });
    // if (energyRevenue?.length > 0) {
    handleFilterData(energyRevenue, byMonth, {
      range: filterObject?.range,
      from_date: filterObject.from_date,
      to_date: filterObject.to_date,
    });
    // }
  }, [energyRevenue]);

  const headCells = [{ id: "", label: "" }];

  const getRound = (value1, value2) => {
    let response = 0;
    if (Boolean(value1) && Boolean(value2)) {
      if (value2 !== undefined) response = (value1 / value2).toFixed(2);
      else response = value1 / value2;
    }
    return response;
  };

  const getTotal = (data) => {
    let allTotal = 0;
    // let allCount = 0;
    let number_of_days = data?.length;
    let final_average = 0;
    if (data) {
      for (const d of data) {
        if (d.usage > 0) {
          allTotal = allTotal + d.usage;
          // allCount = allCount + parseFloat(d.count);
        }
      }
    }
    final_average =
      allTotal > 0 ? (allTotal / number_of_days).toFixed(2) : "0.00";

    function formatAllTotal(allTotal) {
      let strTotal = allTotal.toString();
      let decimalIndex = strTotal.indexOf(".");
      if (decimalIndex !== -1) {
        return strTotal.slice(0, decimalIndex + 3); // Include up to two digits after decimal
      } else {
        return strTotal;
      }
    }

    return {
      allTotal: formatAllTotal(allTotal),
      // allCount: allCount.toFixed(2),
      average: final_average,
    };
  };

  useEffect(() => {
    if (data) {
      const maxUsage = data.reduce((max, obj) => {
        const usage = parseFloat(obj.timezone_duration);
        return usage > max ? usage : max;
      }, 0);

      const nextMultiple = Math.ceil(maxUsage / 100) * 100;
      setMaxValue(nextMultiple);
    }
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light p-2">
          <p className="py-0 my-0">{`${payload[0].payload.month} ${label}`}</p>
          <p className="py-0 my-0 text-danger">{`Total (kWh) : ${payload[0].value.toFixed(2)}`}</p>
          <p
            className="py-0 my-0"
            style={{ color: "#01b2aa" }}
          >{`Average (kWh) : ${payload[1].value}`}</p>
          <p
            className="py-0 my-0"
            style={{ color: "#0000FF" }}
          >{`Number of Sessions : ${payload[2].value}`}</p>
          <p
            className="py-0 my-0"
            style={{ color: "#581845" }}
          >{`Average Charging Duration (Minutes) : ${payload[3].value}`}</p>
        </div>
      );
    }

    return null;
  };
  
  const formatYAxisTick = (tick) => {
    return Math.ceil(tick);
  };
  const formatTooltip = (value, name) => {
    if (name === 'Total (kWh)') {
      return value.toFixed(2) + ' kWh'; 
    }
    return value;
  };

  return (
    <>
      <div className={classes.pageContent}>
        <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
          <div class="card-body pb-5">
            <div class="card-header row" style={{ background: "#fff" }}>
              <div class="col-md-6">
                <strong class="card-title">Energy Usage </strong>{" "}
                <span class="card-title">(in kilowatt-hour)</span>{" "}
              </div>
              <div class="col-md-3 totalkwh">
                Total <span>{getTotal(data).allTotal}</span>{" "}
              </div>
              <div class="col-md-3 totalkwh">
                Average <span>{getTotal(data).average}</span>
              </div>
            </div>
            <div class="clearfix"></div>
            <Table>
              <TableRow>
                <TableCell>
                  <div className="responsiveCharts">
                    <ResponsiveContainer>
                      <LineChart
                        data={data?.map((dt) => {
                          return {
                            ...dt,
                            date: dt?.date?.c?.day,
                          };
                        })}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 10,
                          bottom: -20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="date" />
                        <YAxis domain={[0, maxValue]} tickCount={11}tickFormatter={formatYAxisTick} />
                        <Line
                          type="monotone"
                          dataKey="usage"
                          name="Total (kWh)"
                          stroke="#a31135"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="average"
                          name="Average (kWh)"
                          stroke="#01b2aa"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="count"
                          name="Number of Sessions"
                          stroke="#0000FF"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="timezone_duration"
                          name="Average Charging Duration (Minutes)"
                          stroke="#581845"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Tooltip  content={<CustomTooltip />} />
                        <Legend wrapperStyle={{ position: "relative" }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </TableCell>
              </TableRow>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteEnergyUsage;
