import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


const DatePicker = (props) => {

    const { name, label, value, minDate, maxDate, disabled = false, onChange, required } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar variant="inline" 
                minDate={minDate}
                maxDate={maxDate}
                label={label}
                //format="MMM/dd/yyyy"
                format="MM-dd-yyyy"
                required={required}
                name={name}
                autoOk={true}
                value={value}
                disabled={disabled}
                onChange={date =>onChange(convertToDefEventPara(name,date))}
                
            />
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker;
