import React, { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import UserRolesRadioGroup from "./UserRolesRadioGroup";
import UserPermissionRadioGroup from "./UserPermissionRadioGroup";
import { useIsDesktop } from "../../context/DisplayContext";
//import MultiSelect from './MultiSelect';
// import ChargingStationCheckboxGroup from './ChargingStationCheckboxGroup';
// import FormHelperText from "@material-ui/core/FormHelperText";
const initialFValues = {
  id: 0,
  //name: '',
  password: "",
};

const SiteGroupForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const fetchContext = useContext(FetchContext);
  const { isDesktop } = useIsDesktop();

  const [options, setOptions] = useState([]);
  const [admin, setAdmin] = useState(recordForEdit !== null && recordForEdit.role_id === 3 ? true : false);
  const [userRole, setUserRole] = useState(recordForEdit !== null ? `${recordForEdit.role_id}` : "1");
  const checkPermissionLevel = (ifEdit) => {
    let ret;
    if (ifEdit !== null) {
      if (recordForEdit.permissions.site_groups) {
        ret = "sgrp";
      }
      if (recordForEdit.permissions.sites) {
        ret = "site";
      }
      if (recordForEdit.permissions.charging_stations) {
        ret = "chst";
      }
      if (recordForEdit.permissions.global) {
        ret = "glbl";
      }
    } else {
      ret = "site";
    }
    return ret;
  };
  const [permissionLevel, setPermissionLevel] = useState(checkPermissionLevel(recordForEdit));
  // const [ permissionLevel, setPermissionLevel ] = useState(
  //   recordForEdit !== null
  //     ? recordForEdit.permissions.sites
  //       ? "site"
  //       : "chst"
  //     : "site"
  // );
  //const [ siteIds, setSiteIds ] = useState([]);
  //const [ chargingStationIds, setChargingStationIds ] = useState([]);
  const [permission, setPermission] = useState({
    site_groups: false,
    sites: true,
    charging_stations: false,
    site_groups_ids: [0],
    sites_ids: [0],
    charging_stations_ids: [0],
    global: false,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const getOptionLabel = (option) => `${option.label}`;
  const getOptionDisabled = (option) => option.value === 1;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) temp.first_name = /^[a-zA-Z\d\s]*$/.test(fieldValues.first_name) ? "" : "Please enter correct first name";
    if ("last_name" in fieldValues) {
      temp.last_name = /^[a-zA-Z\d\s]*$/.test(fieldValues.last_name) ? "" : "Please enter correct last name";
    }

    // temp.last_name = (/^[a-zA-Z\d\s]*$/).test(fieldValues.last_name) ? "" : "Please enter correct last name";
    if ("email" in fieldValues) {
      temp.email = /$^|.+@.+..+/.test(fieldValues.email) ? "" : "Email format is not valid.";
    }
    if ("password" in fieldValues) {
      if (!recordForEdit) {
        const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(fieldValues.password);
        const hasNumber = /\d/.test(fieldValues.password);
        const hasCapitalLetter = /[A-Z]/.test(fieldValues.password);
        const isLengthValid = fieldValues.password ? fieldValues.password.length >= 8 : false;
        temp.password =
          hasSpecialCharacter && hasNumber && hasCapitalLetter && isLengthValid
            ? ""
            : "Password must contain at least one special character,one numeric digit, one uppercase (capital) letter and at least 8 characters long.";
      } else {
        if (fieldValues?.password.trim() !== "") {
          const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(fieldValues.password);
          const hasNumber = /\d/.test(fieldValues.password);
          const hasCapitalLetter = /[A-Z]/.test(fieldValues.password);
          const isLengthValid = fieldValues.password ? fieldValues.password.length >= 8 : false;
          temp.password =
            hasSpecialCharacter && hasNumber && hasCapitalLetter && isLengthValid
              ? ""
              : "Password must contain at least one special character,one numeric digit, one uppercase (capital) letter and at least 8 characters long.";
        } else {
          temp.password = "";
        }
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);

  useEffect(() => {
    const getAllLevels = async () => {
      let fetchURL = "select-sites";
      if (permissionLevel === "sgrp") {
        fetchURL = "select-site-groups";
      }
      if (permissionLevel === "chst") {
        fetchURL = "select-charging-stations";
      }

      try {
        const { data } = await fetchContext.authAxios.get(`/${fetchURL}`);
        // setOptions(data.data.data);
        const tempArr = [];
        for (const item of data.data) {
          const optObj = { value: item.id, label:permissionLevel === "chst" ? `${item.name} - ${item.ocpp_cbid}` : item.name };
          tempArr.push(optObj);
        }
        setOptions(tempArr);
        if (recordForEdit != null) {
          let SiteGroupsIds = recordForEdit.permissions.site_groups_ids;
          let SiteIds = recordForEdit.permissions.sites_ids;
          let ChargingStationIds = recordForEdit.permissions.charging_stations_ids;
          let defaultSelectedIds;
          if (permissionLevel === "sgrp") {
            defaultSelectedIds = tempArr.filter((item) => SiteGroupsIds.some((ids) => item.value === ids));
          }
          if (permissionLevel === "site") {
            defaultSelectedIds = tempArr.filter((item) => SiteIds.some((ids) => item.value === ids));
          }
          if (permissionLevel === "chst") {
            defaultSelectedIds = tempArr.filter((item) => ChargingStationIds.some((ids) => item.value === ids));
          }

          setSelectedOptions(defaultSelectedIds);
        }
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllLevels(fetchContext);
  }, [fetchContext, permissionLevel, recordForEdit]);

  const handleRoles = (event) => {
    setUserRole(event.target.value);
  };

  const handlePermissions = (event) => {
    setPermissionLevel(event.target.value);
    if (event.target.value === "glbl") {
      //setUserRole("4");
      setSelectedOptions([]);
      setPermission({
        site_groups: false,
        sites: false,
        charging_stations: false,
        site_groups_ids: [0],
        sites_ids: [0],
        charging_stations_ids: [0],
        global: true,
      });
    }

    if (event.target.value === "sgrp") {
      setSelectedOptions([]);
      setPermission({
        site_groups: true,
        sites: false,
        charging_stations: false,
        site_groups_ids: [0],
        sites_ids: [0],
        charging_stations_ids: [0],
        global: false,
      });
    }

    if (event.target.value === "site") {
      setSelectedOptions([]);
      setPermission({
        site_groups: false,
        sites: true,
        charging_stations: false,
        site_groups_ids: [0],
        sites_ids: [0],
        charging_stations_ids: [0],
        global: false,
      });
    }

    if (event.target.value === "chst") {
      setSelectedOptions([]);
      setPermission({
        site_groups: false,
        sites: false,
        charging_stations: true,
        site_groups_ids: [0],
        sites_ids: [0],
        charging_stations_ids: [0],
        global: false,
      });
    }
  };

  const onShowHidePermission = (event) => {
    setAdmin((toggle) => !toggle);
    if (event.target.value) {
      setUserRole("3"); //Administrator
    } else {
      setUserRole("1"); //View Only
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      Object.assign(values, {
        role_id: userRole,
        permissions: permission,
      });
      const selectedIds = [];
      if (selectedOptions !== undefined) {
        selectedOptions.forEach((item) => {
          selectedIds.push(item.value);
        });
      } else {
        selectedIds.push(0);
      }

      if (permissionLevel === "sgrp") {
        values.permissions.site_groups = true;
        values.permissions.sites = false;
        values.permissions.charging_stations = false;
        values.permissions.site_groups_ids = selectedIds;
        values.permissions.sites_ids = [0];
        values.permissions.charging_stations_ids = [0];
        values.permissions.global = false;
      }
      if (permissionLevel === "site") {
        values.permissions.site_groups = false;
        values.permissions.sites = true;
        values.permissions.charging_stations = false;
        values.permissions.site_groups_ids = [0];
        values.permissions.sites_ids = selectedIds;
        values.permissions.charging_stations_ids = [0];
        values.permissions.global = false;
      }

      if (permissionLevel === "chst") {
        values.permissions.site_groups = false;
        values.permissions.sites = false;
        values.permissions.charging_stations = true;
        values.permissions.site_groups_ids = [0];
        values.permissions.sites_ids = [0];
        values.permissions.charging_stations_ids = selectedIds;
        values.permissions.global = false;
      }

      if (permissionLevel === "glbl" || userRole === "3") {
        values.permissions.site_groups = false;
        values.permissions.sites = false;
        values.permissions.charging_stations = false;
        values.permissions.site_groups_ids = [0];
        values.permissions.sites_ids = [0];
        values.permissions.charging_stations_ids = [0];
        values.permissions.global = true;
      }
      if (values.password.trim() === "") {
        delete values.password;
      }
      addOrEdit(values, resetForm);
    }
  };

  const retLabel = (pLevel) => {
    if (pLevel === "sgrp") {
      return "Select Site Accounts";
    }
    if (pLevel === "site") {
      return "Select Sites";
    }
    if (pLevel === "chst") {
      return "Select Charging Stations";
    }
  };

  const retPlaceholder = (pLevel) => {
    if (pLevel === "sgrp") {
      return "Placeholder for Site Accounts";
    }
    if (pLevel === "site") {
      return "Placeholder for Sites";
    }
    if (pLevel === "chst") {
      return "Placeholder for Charging Stations";
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container fullWidth>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="first_name"
            label="First Name"
            required
            value={values.first_name}
            onChange={handleInputChange}
            error={errors.first_name}
            fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="last_name"
            label="Last Name"
            required
            value={values.last_name}
            onChange={handleInputChange}
            error={errors.last_name}
            fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="email"
            label="Email"
            required
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
            fullWidth
            style={{ width: "97.5%" }}
          />
        </Grid>
        {/* {!recordForEdit &&  */}
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="password"
            label="Password"
            required={!recordForEdit ? true : false}
            value={values.password}
            onChange={handleInputChange}
            error={errors.password}
            fullWidth
            style={{ width: "97.5%" }}
          />
        </Grid>
        {/* } */}
        {/* {!recordForEdit &&
              <Grid item xs={6} >
                <Controls.Input
                  name="password"
                  label="Password"
                  type="password"
                  required
                  value={values.password}
                  onChange={handleInputChange}
                  error={errors.password}
                  fullWidth
                  style={{ width: "95%" }}
                />
              </Grid>
            } */}

        <Grid item xs={12}>
          {/* <FormHelperText>
                Having all the permissions including creation and removing of other administrators/users.
              </FormHelperText> */}
          <Controls.Checkbox
            name="admin"
            label="Administrator"
            //checked={false}
            value={admin}
            onChange={onShowHidePermission}
          />
        </Grid>

        {!admin && (
          <>
            <Grid item xs={12}>
              <UserRolesRadioGroup
                name="userRole"
                //label="View Only"
                //checked={false}
                value={userRole}
                onChange={handleRoles}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controls.Checkbox
                name="technician"
                label="Technician"
                value={values.technician}
                onChange={handleInputChange}
              />
            </Grid> */}
            <Grid item xs={12}>
              <UserPermissionRadioGroup
                name="permissionLevel"
                //label="View Only"
                //checked={true}
                value={permissionLevel}
                onChange={handlePermissions}
              />
            </Grid>
            {permissionLevel !== "glbl" && (
              <Grid item xs={12}>
                <Controls.MultiSelectRequired
                  items={options}
                  getOptionLabel={getOptionLabel}
                  getOptionDisabled={getOptionDisabled}
                  selectedValues={selectedOptions} // //retLabel//retPlaceholder
                  // label={permissionLevel === 'site' ? 'Select Sites' : 'Select Charging Stations'}
                  // placeholder={permissionLevel === 'site' ? 'Placeholder for Sites' : 'Placeholder for Charging Stations'}
                  label={retLabel(permissionLevel)}
                  placeholder={retPlaceholder(permissionLevel)}
                  selectAllLabel="Select all"
                  onToggleOption={handleToggleOption}
                  onClearOptions={handleClearOptions}
                  onSelectAll={handleSelectAll}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Cancel" color="default" onClick={closeForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SiteGroupForm;
