import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import TaxTypeRadioGroup  from './TaxTypeRadioGroup';
import { useIsDesktop } from '../../context/DisplayContext';
// import ParkingTypeSelect  from './ParkingTypeSelect';

// const useStyles = makeStyles(theme => ({
//   input: {
//     "&:invalid": {
//       padding: '2px',
//       border: "red solid 1px"
//     }
//   },
// }));


const TaxRatesForm = (props) => {
    const { addOrEdit, recordForEdit, closeForm } = props;
    //const classes = useStyles();
    const [ inclusive, setInclusive ] = useState((recordForEdit !== null ) ? `${recordForEdit.inclusive}` : '1');
    const initialFValues = {
      id: 0,
      name: '',
      //type: recordForEdit !== null ? recordForEdit.type : '',
    }
    const { isDesktop } = useIsDesktop();

    // const [ selected, setSelected ] = useState((recordForEdit !== null ? recordForEdit.type : ''));
    // const [ selectedLable, setSelectedLable ] = useState('Authentication Tag');
    // const [ disabledChargingStation, setDisabledChargingStation ] = useState(false);
    // const [ disabledSite, setDisabledSite ] = useState(false);

    //https://stackoverflow.com/questions/9038522/regular-expression-for-any-number-greater-than-0
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = (/^[a-zA-Z\d\s]*$/).test(fieldValues.name) ? "" : "Please enter correct tax detail name";
        if ('tax_percentage' in fieldValues)
            temp.tax_percentage = (/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/).test(fieldValues.tax_percentage)  ?  "" : "Please provide correct tax value."
        //     temp.fixed_fee = (/^(?=.*\d)\d*(?:\.\d\d)?$/).test(fieldValues.fixed_fee)  ?  "" : "Please provide correct value for Fixed Fee."
        // if ('auth_amount' in fieldValues)
        //     temp.auth_amount = (/^(?=.*\d)\d*(?:\.\d\d)?$/).test(fieldValues.auth_amount)  ?  "" : "Please provide correct value for Auth Amount."
        // if ('variable_fee' in fieldValues)
        //     temp.variable_fee = (/^(?=.*\d)\d*(?:\.\d\d)?$/).test(fieldValues.variable_fee)  ?  "" : "Please provide correct value for Variable Fee."
        // if ('parking_fee' in fieldValues)
        //     temp.parking_fee = (/^(?=.*\d)\d*(?:\.\d\d)?$/).test(fieldValues.parking_fee)  ?  "" : "Please provide correct value for Parking Fee."
        // if ('buffer_time' in fieldValues)            
        //     temp.buffer_time = (/^[1-9]\d*$/).test(fieldValues.buffer_time)  ?  "" : "Please provide correct value for Buffer Time."
        // if ('buffer_time' in fieldValues)            
        //     temp.buffer_time = (/^[^.]*$/).test(fieldValues.buffer_time)  ?  "" : "Please provide correct value for Buffer Time."
        
         setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
          Object.assign(values, 
            { 
              'inclusive' : inclusive,              
            }
          );
          addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit, setValues])

    // const changeSelectOptionHandler = (event) => {
    //   values.type = event.target.value;
    //   setSelected(event.target.value); 
    //   setSelectedLable(event.target.value);       
    // };

    // const handleLevel = (event) => {
    //   if(event.target.name === 'site_id'){
    //     setDisabledChargingStation(true);
    //     values.site_id = event.target.value;
    //     values.charging_station_id = null;
    //   } else {
    //     setDisabledSite(true);
    //     values.site_id = null;
    //     values.charging_station_id = event.target.value;
    //   }
    // };
    const handleTax = (event) => {
      setInclusive(event.target.value);
    };

    return (
        <Form onSubmit={handleSubmit}
        
        // style={{ flexGrow: 1, minWidth:'890px' }}
        >
          <Grid container fullWidth >
            <Grid item xs={12} >
              <Controls.Input
                name="name"
                label="Name"
                required
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                fullWidth
                style={{ width: "97.5%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} >
              <Controls.Input
                name="tax_number"
                label="Tax Number"                
                value={values.tax_number}
                onChange={handleInputChange}
                error={errors.tax_number}
                fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} >
              <Controls.Input
                name="tax_percentage"
                label="Tax Value (%)"
                required
                //type="number"
                inputProps={{ min: 0 }}
                value={values.tax_percentage}
                onChange={handleInputChange}
                error={errors.tax_percentage}
                fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={12} >
              <TaxTypeRadioGroup
                name="inclusive"
                //label="View Only"
                //checked={false}
                value={inclusive}
                onChange={handleTax}
              />
            </Grid>



            <Grid item xs={12}>
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit" />
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={closeForm}/>
              </div>
            </Grid>
          </Grid>
        </Form>
    )
}

export default TaxRatesForm;
