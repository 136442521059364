import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import Controls from "../../components/controls/Controls";
import { CircularProgress } from "@mui/material";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center",
  },
}));

const ChargerTypeSelector = (props) => {
  const { value = "", error = null, onChange } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(true);
  const [values, setValues] = useState(['AC', 'DC']);
  const classes = useStyles();


  return (
    <>
      {props.filter ? (
        loaded ? (
          <FormControl variant="standard" className={classes.formControl} style={{ width: "95%" }} {...(error && { error: true })}>
            <InputLabel className={classes.inputLabel}>Charger Type</InputLabel>
            <MuiSelect
              className={classes.select}
              variant="standard"
              label="Charger Type"
              name="charger_type"
              value={value}
              required={props.required}
              onChange={onChange}
            >
              {/* <MenuItem value="None">None</MenuItem> */}
              {values.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        ) : (
          <Controls.Input name="charger_type" label="Charger Type" style={{ width: "95%" }}>
            <CircularProgress size="1rem" />{" "}
          </Controls.Input>
        )
      ) : loaded ? (
        <Controls.Select name="charger_type" label="Charger Type" value={value} required={props.required} onChange={onChange} options={values} error={error} />
      ) : (
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input name="charger_type" label="Charger Type" style={{ width: "95%" }}>
          <CircularProgress size="1rem" />{" "}
        </Controls.Input>
      )}
    </>
  );
};

export default ChargerTypeSelector;
