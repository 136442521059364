import React, { useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';

const ChangePasswordForm = (props) => {
    const { addOrEdit, recordForEdit, closeForm, isCurrentPasswordRequire } = props;
    
    const initialFValues = {
        id: 0,
        name: '',
        status: false,
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        //https://stackoverflow.com/questions/13283470/regex-for-allowing-alphanumeric-and-space

        if ("password" in fieldValues) {
            const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(fieldValues.password);
            const hasNumber = /\d/.test(fieldValues.password);
            const hasCapitalLetter = /[A-Z]/.test(fieldValues.password);
            const isLengthValid = fieldValues.password ? fieldValues.password.length >= 8 : false;
            temp.password = hasSpecialCharacter && hasNumber && hasCapitalLetter && isLengthValid ? "" : "Password must contain at least one special character,one numeric digit, one uppercase (capital) letter and at least 8 characters long.";
          }

        // if ('password' in fieldValues)
        //     temp.password = fieldValues.password ? "" : "Please enter new password."    
        if ('password_confirmation' in fieldValues)
            temp.password_confirmation = fieldValues.password_confirmation ? "" : "Please confirm new password"      
        if ('password' in fieldValues && 'password_confirmation' in fieldValues)
            temp.password_confirmation = fieldValues.password === fieldValues.password_confirmation ? "" : "Confirm password doesn't match New password."  
 
        setErrors({
            ...temp
        })
        
        if (fieldValues === values) 
            return Object.values(temp).every(x => x === "")
    }
    
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit, setValues])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container fullWidth >
                <Grid item xs={12} >
                {isCurrentPasswordRequire && <Controls.Input
                        name="old_password"
                        label="Current Password"
                        required
                        type="password"
                        value={values.old_password}
                        onChange={handleInputChange}
                        error={errors.old_password}
                        fullWidth                        
                        style={{ width: "100%" }}
                    />}
                    <Controls.Input
                        name="password"
                        label="New Password"
                        required
                        type="password"
                        value={values.password}
                        onChange={handleInputChange}
                        error={errors.password}
                        fullWidth                        
                        style={{ width: "100%" }}
                    />
                    <Controls.Input
                        name="password_confirmation"
                        label="Confirm New Password"
                        required
                        type="password"
                        value={values.password_confirmation}
                        onChange={handleInputChange}
                        error={errors.password_confirmation}
                        fullWidth                        
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Controls.Button                            
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                            text="Cancel"
                            color="default"
                            onClick={closeForm}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}

export default ChangePasswordForm;
