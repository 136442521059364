import { useEffect, useState } from "react";
//import './App.css';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  qrModalContain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "25px",
    [theme.breakpoints.between("sm", "md")]: {
      width: "550px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "650px",
    },
  },
}));

const QRCode = (props) => {
  const classes = useStyles();

  const { word, size, bgColor } = props;
  //const [temp, setTemp] = useState("");
  // const [word, setWord] = useState("https://pay.rede.network/main/fde0ecef28ce6420fc6583f83a636d02");
  // const [size, setSize] = useState(200);
  // const [bgColor, setBgColor] = useState("ffffff");
  const [qrCode, setQrCode] = useState("");

  // Changing the URL only when the user
  // changes the input
  useEffect(() => {
    setQrCode(`http://api.qrserver.com/v1/create-qr-code/?data=${word}&size=${size}x${size}&bgcolor=${bgColor}`);
  }, [word, size, bgColor]);

  // Updating the input word when user
  // click on the generate button
  // const handleClick = () => {
  //   setWord(temp);
  // }

  return (
    <div className={classes.qrModalContain}>
      {/* <span style={{paddingBottom: '15px'}}>{word ? word : "There is some issue in fetching the response"}</span> */}
      <div className="input-box">
        {/* <div className="gen">
          <input type="text" onChange={(e) => {setTemp(e.target.value)}} placeholder="Enter text to encode" />
          <button className="button" onClick={handleClick}>Generate</button>
        </div> */}
        {/* <div className="extra">
          <h5>Background Color:</h5>
          <input type="color" onChange={(e) => { setBgColor(e.target.value.substring(1)) }} />
          <h5>Dimension:</h5>
          <input type="range" min="00" max="600"  value={size} onChange={(e) => {setSize(e.target.value)}} />
        </div> */}
      </div>
      <div className="output-box">
        <img src={qrCode} alt="" />
        {/* <a href={qrCode} download="QRCode">
        <button type="button">Download</button>
        </a> */}
      </div>
    </div>
  );
};

export default QRCode;
