import React, { useContext, useEffect, useState } from "react";
import { useForm, Form } from "../../components/useForm";
import { FetchContext } from "../../context/FetchContext";
import { List, ListItem, ListItemText } from "@material-ui/core";
import svgIcon from '../../assets/images/more-icon.svg'

const OptionModal = ({ remainingData }) => {
  // const svgFile = '../'

  return (
    <Form style={{ flexGrow: 1, minWidth: "890px" }}>
      {/* <List style={{ display: "flex" }}> */}
      <div className="grid-container">
        {remainingData?.date?.map((item, index) => (
          // <ListItem key={index}>
          //   <ListItemText primary={item?.name} />
          // </ListItem>
          <div className="text-background">{item?.name} </div>
        ))}
      </div>
      {/* </List> */}
    </Form>
  );
};

export default OptionModal;