import React, { useContext } from 'react';
//import { DateTime } from 'luxon';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';
import { ImportExport  } from '@material-ui/icons';
import { Tooltip  } from '@mui/material';

const ExportDataByGroupsNext = ({ id, filterDate, bymonth }) => {
  const fetchContext = useContext(FetchContext);

  const asyncFnComputeData = async () => {
    const dataArr = [];
    const finalPayload = 
    {
      "charger_group_id": id,
      ...filterDate,
      'export':true,
      date: bymonth
    }

    await fetchContext.authAxios.post(
      `/get-report-group-by-charging-station-charger-group-id`,finalPayload
    )
    .then(({ data }) => {
      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {
          //dataObj.sessionID = item.id;  
          let obj = { 
            site: item.site_name,
            charger_group_name: item.charger_group_name,
            charging_station_name: item.charging_station_name,
            energy: Number(item.energy).toFixed(2),
            duration: item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : '-',
            total: item.total,
            amount: Number(item.amount).toFixed(2),
            address: item.address?.replace(/,/g, ''),
            city: item.city,
            state: item.state,
            postal_code: item.postal_code,
            latitude: item.latitude,
            longitude: item.longitude,          
          }; 
          dataArr.push(obj);
        }
      }
    })
    return Promise.resolve(dataArr);
  };  

  const columns = [
    { 'id': 'site', 'displayName': 'Site Name' },
    { 'id': 'charging_station_name', 'displayName': 'Charging Station' },
    { 'id': 'charger_group_name', 'displayName': 'Charger Group Name' },
    { 'id': 'amount', 'displayName': 'Amount (USD)' },
    { 'id': 'energy', 'displayName': 'Energy Delivered (KWH)' },
    { 'id': 'duration', 'displayName': 'Average Charging Duration (Minutes)' },
    { 'id': 'total', 'displayName': 'Number of Sessions' },
    { 'id': 'address', 'displayName': 'Address' },
    { 'id': 'city', 'displayName': 'City' },
    { 'id': 'state', 'displayName': 'State' },
    { 'id': 'postal_code', 'displayName': 'Zipcode' },
    { 'id': 'latitude', 'displayName': 'Latitude' },
    { 'id': 'longitude', 'displayName': 'Longitude' },
  ];
  


  return (
    <>
      <CsvDownloader 
        filename="ChargerGroupDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns} 
        datas={asyncFnComputeData}>
          <Tooltip title="Export / Download">
            <ImportExport />
          </Tooltip> 
      </CsvDownloader>
    </>
  );

};

export default ExportDataByGroupsNext;
