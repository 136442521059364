import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, makeStyles, TableSortLabel } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(1),
        '& thead th': {
            fontWeight: '500',
            color: '#fff',
            backgroundColor: theme.palette.secondary.light,
        },
        '& tbody td': {
            fontWeight: '400',
        },
        '& tbody tr': {
            borderBottom: 'solid 1px #E0E0E0',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
    actionButtonCell: {
        textAlign: 'right',
        paddingRight: '30px',
    },
    firstCol: {
        paddingRight: 0,
        width: '1px',
    },
}))
const useTable = ( headCells ) => {
    const classes = useStyles();
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()
    const TblContainer = props => (
        <div style={{overflowX: "auto", overflowY: "hidden"}}>
            <Table className={classes.table}>
                {props.children}
            </Table>
        </div>
    )
    const TblHead = props => {
        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === "asc";
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }
        return (<TableHead>
            <TableRow>
                {
                    headCells.map((headCell, i) => (
                        <TableCell key={i}
                            className={ headCell.label === '' ? classes.firstCol : '' && headCell.label === 'Actions' ? classes.actionButtonCell : '' }
                            sortDirection={orderBy === headCell.id ? order : false}
                            >
                            {headCell.label}                                
                        </TableCell>))
                }
            </TableRow>
        </TableHead>)
    }
    return {
        TblContainer,
        TblHead,
    }
}
export default useTable;
