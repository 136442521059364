import ActionButton from "./ActionButton";
import SearchWithSelect from "./SearchWithSelect";
import Button from "./Button";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Input from "./Input";
import MultiSelect from "./MultiSelect";
import MultiSelectRequired from "./MultiSelectRequired";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import TimePicker from './TimePicker';


const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    MultiSelect,
    TimePicker,
    SearchWithSelect,
    MultiSelectRequired
}

export default Controls;