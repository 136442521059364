import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import SitesSelect from "../Sites/SitesSelect";
import ManufacturersSelectfrom from "./ManufacturersSelect";
import ChargerTypeSelect from "./ChargerTypeSelect";
import DeviceTypeSelect from "./DeviceTypeSelect";
import ChargerACOutputSelect from "./ChargerACOutputSelect";
import ChargerDCOutputSelect from "./ChargerDCOutputSelect";
import ChargerConnectorSelect from "./ChargerConnectorSelect";
import { AddBoxOutlined, RemoveFromQueueOutlined } from "@material-ui/icons";
//import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { FetchContext } from "../../context/FetchContext";
import Popup from "../../components/Popup";
import RemoveConnectorConfirmation from "./RemoveConnectorConfirmation";
import Notification from "../../components/Notification";
import ChargerModelsSelect from "./ChargerModelsSelect";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  connectorGrid: {
    backgroundColor: theme.palette.secondary.light,
    color: "white",
    borderRadius: "3px",
    padding: "3px 0 2px 9px",
  },
}));

const ChargingStationForm = (props) => {
  const classes = useStyles();
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const { addOrEdit, recordForEdit, closeForm } = props;
  const [connectorList, setConnectorList] = useState([{ id: 0, sequence_number: 1, type: "CHADEMO", amperage: 0, voltage: 0, max_power: 0, connector_output: 0 }]);
  const [selected, setSelected] = useState(recordForEdit !== null ? (recordForEdit.charger_type === "AC" ? "AC" : "DC") : null);
  const [disabledCommissionedAt, setDisabledCommissionedAt] = useState(true);
  const [disabledChargerOutput, setDisabledChargerOutput] = useState(recordForEdit !== null ? false : true);
  //setDisabledChargerOutput
  const [minDate, setMinDate] = useState(null);
  //const [ selected, setSelected ] = useState("");
  const [locationCoordinates, setLocationCoordinates] = useState({ latitude: "", longitude: "" });
  //const [ installedAt, setinstalledAt ] = useState(new Date());
  const fetchContext = useContext(FetchContext);
  const [chargerTypeLoaded, setChargerTypeLoaded] = useState(false);
  const [chargerTypeOptions, setChargerTypeOptions] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { isDesktop } = useIsDesktop();

  const initialFValues = {
    id: 0,
    name: "",
    charger_type: recordForEdit !== null ? recordForEdit.charger_type : "AC",
    charger_output: recordForEdit !== null ? recordForEdit.charger_output : null,
    connectors: [{ id: 0, sequence_number: 1, type: "CHADEMO", amperage: 0, voltage: 0, max_power: 0, connector_output: 0 }],
    //connectors: [{id: 0, type: 'CHADEMO', amperage: 0, voltage: 0, max_power: 0}],
    coordinates: { latitude: "", longitude: "" },
 
  };

  const changeSelectOptionHandler = (event) => {
    values.charger_type = event.target.value;
    values.charger_output = null;
    setSelected(event.target.value);
    setDisabledChargerOutput(false);
  };
  const handleChargerOutputDisable = (event) => {
    changeSelectOptionHandler(event);
  };

  const closeConfirmationForm = () => {
    setConfirmationOpen(false);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    ///^[a-z\d\-_\s]+$/i --- Alpha numeric with space  '-' and '_'
    if ("name" in fieldValues) temp.name = /^[a-zA-Z\d\s]*$/.test(fieldValues.name) ? "" : "Please enter correct charging station name";
    if ("ocpp_cbid" in fieldValues) temp.ocpp_cbid = /^[a-zA-Z0-9\-_]*$/.test(fieldValues.ocpp_cbid) ? "" : "Please provide correct OCPP-CBID value.";
    if ("latitude" in fieldValues)
      temp.latitude = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,12})?$/.test(fieldValues.latitude) ? "" : "Please provide correct location latitude value.";
    if ("longitude" in fieldValues)
      temp.longitude = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,12})?$/.test(fieldValues.longitude) ? "" : "Please provide correct location longitude value.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const formattedValue = (dateStr) => {
    const formateType = "yyyy-MM-dd HH:mm:ss";
    if (DateTime.fromJSDate(dateStr).toFormat(formateType) !== "Invalid DateTime") {
      return DateTime.fromJSDate(dateStr).toFormat(formateType);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      values.coordinates = locationCoordinates;
      let str1 = formattedValue(values.installed_at);
      let str2 = formattedValue(values.commissioned_at);

      if (values.simultaneous_charging) values.simultaneous_charging = "Yes";
      else values.simultaneous_charging = "No";

      if (values.dual_connector) values.dual_connector = "Yes";
      else values.dual_connector = "No";

      if (recordForEdit != null) {
        if (str1) {
          values.installed_at = str1;
        } else {
          values.installed_at = recordForEdit.installed_at;
        }

        if (str2) {
          values.commissioned_at = str2;
        } else {
          values.commissioned_at = recordForEdit.commissioned_at;
        }
        addOrEdit(recordForEdit.ocpp_cbid, values, resetForm);
      } else {
        values.installed_at = str1;
        values.commissioned_at = str2;
        addOrEdit("", values, resetForm);
      }
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setLocationCoordinates(JSON.parse(recordForEdit.coordinates));

      if (recordForEdit.simultaneous_charging === "Yes") {
        recordForEdit.simultaneous_charging = true;
      }
      if (recordForEdit.simultaneous_charging === "No") {
        recordForEdit.simultaneous_charging = false;
      }

      if (recordForEdit.dual_connector === "Yes") {
        recordForEdit.dual_connector = true;
      }
      if (recordForEdit.dual_connector === "No") {
        recordForEdit.dual_connector = false;
      }

      setValues({
        ...recordForEdit,
      });
      setConnectorList(recordForEdit.connectors);
    }
  }, [recordForEdit, setValues]);

  const handleChange = (event, index, id) => {
    let { name, value } = event.target;
    const list = [...connectorList];
    if (id !== 0) {
      list[index][id] = id;
    }

    if (name === 'amperage' || name === 'voltage' || name === 'max_power' || name === 'connector_output') {
      if (value !== "" && !/^\d+(\.\d{0,2})?$/.test(value)) {
          return;
      }
  }
  
    if (name !== "type") {
      // Convert value to a float if it's not an empty string
      if (value !== "") {
        value = parseFloat(value);
      }

      // if (value === "" || value < 0) {
      //   value = 0;
      // }
    }
    list[index][name] = value;
    setConnectorList(list);
    values.connectors = connectorList;
  };
  const handleKeyDown = (event) => {
    // Prevent typing the minus key or the plus key
    if (event.key === "-" || event.key === "Minus" || event.key === "+" ||event.key === "." || event.key === "Plus"  ||
    event.key.includes("Arrow")) {
      event.preventDefault();
    }
  };
  
  // handle click event of the Remove button
  const handleRemoveClick = (data) => {
    setSelectedData(data);
    if (data?.id === 0) {
      const list = [...connectorList];
      list.splice(data.index, 1);
      setConnectorList(list);
    } else {
      setConfirmationOpen(true);
    }
  };

  const handleAddClick = (seqNo) => {
    const num = seqNo + 1;
    setConnectorList([...connectorList, { id: 0, sequence_number: num, type: "CHADEMO", amperage: 0, voltage: 0, max_power: 0, connector_output: 0 }]);
  };

  const handleCoordinatesChange = (event) => {
    const { name, value } = event.target;
    validate({ [name]: value });
    const list = { ...locationCoordinates };
    list[name] = value;
    setLocationCoordinates(list);
  };

  const handleDisable = (event) => {
    handleInputChange(event);
    setMinDate(event.target.value);
    setDisabledCommissionedAt(false);
  };

  const getAllChargerPowers = async () => {
    try {
      const { data } = await fetchContext.authAxios.post("/select-charger-outputs", {
        type: values.charger_type,
      });
      setChargerTypeOptions(data?.data.sort((a, b) => parseInt(a.name.replace("KW", "")) - parseInt(b.name.replace("KW", ""))));
      setChargerTypeLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  const handleConfirm = () => {
    if (selectedData?.index) {
      fetchContext.authAxios
        .post(`/delete-connector`, { id: selectedData?.id, sequence_number: selectedData?.sequence_number })
        .then((res) => {
          if (res?.data?.status) {
            const list = [...connectorList];
            list.splice(selectedData.index, 1);
            setConnectorList(list);
            setConfirmationOpen(false);
            setSelectedData(null);
            setValues({ ...values, connectors: list });
            setNotify({
              isOpen: true,
              message: res?.data?.data,
              type: "success",
            });
          } else {
            setNotify({
              isOpen: true,
              message: Boolean(res?.data?.errors?.id?.length) ? res?.data?.errors?.id[0] : "Something went wrong",
              type: "error",
            });
            setConfirmationOpen(false);
            setSelectedData(null);
          }
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: `Something went wrong`,
            type: "error",
          });
        });
    }
  };

  useEffect(() => {
    setChargerTypeLoaded(false);
    getAllChargerPowers(fetchContext);
  }, [values.charger_type]);

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="name"
              label="Charging Station Name"
              required
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <SitesSelect value={values.site_id} error={errors.site_id} required onChange={handleInputChange} />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="ocpp_cbid"
              label="OCPP CBID"
              required
              value={values.ocpp_cbid}
              onChange={handleInputChange}
              error={errors.ocpp_cbid}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="owner"
              label="Owner"
              value={values.owner}
              onChange={handleInputChange}
              error={errors.owner}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <ChargerTypeSelect
              label="Charger Type"
              name="charger_type"
              value={selected}
              error={errors.charger_type}
              required
              onChange={handleChargerOutputDisable}
              //onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            {selected === "AC" ? (
              <ChargerACOutputSelect
                label="Charger Output"
                name="charger_output"
                value={values.charger_output}
                error={errors.charger_output}
                required
                loaded={chargerTypeLoaded}
                options={chargerTypeOptions}
                disabled={disabledChargerOutput}
                onChange={handleInputChange}
              />
            ) : (
              <ChargerDCOutputSelect
                label="Charger Output"
                name="charger_output"
                value={values.charger_output}
                error={errors.charger_output}
                required
                loaded={chargerTypeLoaded}
                options={chargerTypeOptions}
                disabled={disabledChargerOutput}
                onChange={handleInputChange}
              />
            )}
          </Grid>
          {/* <Grid item xs={isDesktop ? 6 : 12} >
                    <Controls.Select 
                        label="Charger Output"
                        name="charger_output"
                        value={values.charger_output}  
                        error={errors.charger_output} 
                        options={chargerType}
                        required
                        onChange={handleInputChange}
                        //disabledChargerOutput
                    />
                </Grid> */}

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.DatePicker
              name="installed_at"
              label="Installed At"
              value={values.installed_at ? values.installed_at : null}
              onChange={handleDisable}
              error={errors.installed_at}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.DatePicker
              name="commissioned_at"
              label="Commissioned At"
              minDate={minDate}
              value={values.commissioned_at ? (values.commissioned_at > values.installed_at ? values.commissioned_at : minDate) : null}
              onChange={handleInputChange}
              error={errors.commissioned_at}
              disabled={disabledCommissionedAt}
              style={{ width: "95%" }}
            />
          </Grid>
          {/* <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="payment_interface"
              label="Payment Interface"
              value={values.payment_interface}
              onChange={handleInputChange}
              error={errors.payment_interface}
              style={{ width: "95%" }}
            />
          </Grid> */}

          <Grid item xs={isDesktop ? 6 : 12}>
            <ManufacturersSelectfrom value={values.manufacturer_id} error={errors.manufacturer_id} required={true} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <ChargerModelsSelect
              label="Models"
              name="charger_model"
              value={values.charger_model}
              error={errors.charger_model}
              onChange={handleInputChange}
              valuesData={values}
              setValues={setValues}
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="floor_location"
              label="Floor Location"
              value={values.floor_location}
              onChange={handleInputChange}
              error={errors.floor_location}
              style={{ width: "95%" }}
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="serial_number"
              label="Serial Number"
              value={values.serial_number}
              onChange={handleInputChange}
              error={errors.serial_number}
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="latitude"
              label="Latitude"
              value={locationCoordinates.latitude}
              onChange={(e) => handleCoordinatesChange(e)}
              error={errors.latitude}
              required
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="longitude"
              label="Longitude"
              value={locationCoordinates.longitude}
              onChange={(e) => handleCoordinatesChange(e)}
              error={errors.longitude}
              required
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <DeviceTypeSelect label="Device Type" name="device_type" value={values.device_type} error={errors.device_type} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="device_serial_number"
              label="Device Serial Number"
              value={values.device_serial_number}
              onChange={handleInputChange}
              error={errors.device_serial_number}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="note"
              label="Notes"
              value={values.note}
              onChange={handleInputChange}
              error={errors.note}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Checkbox name="simultaneous_charging" label="Simultaneous Charging" value={values.simultaneous_charging} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Checkbox name="ctep" label="CTEP" value={values?.ctep} onChange={handleInputChange} />
          </Grid>

          {/* <Grid item xs={6}>
            <Controls.Checkbox name="dual_connector" label="Dual Connector" value={values.dual_connector} onChange={handleInputChange} />
          </Grid> */}

          <Grid item xs={12} className={classes.connectorGrid}>
            <Typography>Connectors </Typography>
          </Grid>
          {connectorList?.map((x, i) => (
            <>
              {/* <Grid item xs={2}>
                <Controls.Input
                  name="sequence_number"
                  label="Sequence No."
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 10,
                      min: 1,
                    },
                  }}
                  value={x.sequence_number}
                  onChange={(e) => handleChange(e, i)}
                />
              </Grid> */}
              <Grid item xs={isDesktop ? 2 : 12} style={{ flexBasis: "5.1%", display:'flex', justifyContent: 'center', alignItems:'center' }}>
                <div style={{ position: "relative", float: "left", fontWeight: 700, padding: 0, margin:'10px' }}>({x.sequence_number})</div>
              </Grid>

              <Grid item xs={isDesktop ? 2 : 12}>
                <ChargerConnectorSelect name="type" label="Type" value={x.type} onChange={(e) => handleChange(e, i)} />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 12}> 
                <Controls.Input name="amperage" label="Amperage" onKeyDown={handleKeyDown} type="number" value={x.amperage} onChange={(e) => handleChange(e, i)} />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 12}>
                <Controls.Input name="voltage" label="Voltage" onKeyDown={handleKeyDown}  type="number" value={x.voltage} onChange={(e) => handleChange(e, i)} />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 12}>
                <Controls.Input name="max_power" label="Max Power" onKeyDown={handleKeyDown}  type="number" value={x.max_power} onChange={(e) => handleChange(e, i)} />
              </Grid>
              <Grid item xs={isDesktop ? 1 : 12}>
                <Controls.Input name="connector_output" label="Output" type="number" onKeyDown={handleKeyDown} value={x.connector_output} onChange={(e) => handleChange(e, i)} />
              </Grid>
              <Grid item xs={2} container direction="row" alignItems="flex-end" justify="left">
              {i !== 0 && (
                <Grid item xs={2} container direction="row" alignItems="flex-end" justify="left" style={{marginRight: '7px'}}>
                  {/* // {connectorList.length !== 1 && (x.id === 0 || i === 1) && ( */}
                  <Controls.ActionButton onClick={() => handleRemoveClick({ ...x, index: i })} >
                    <RemoveFromQueueOutlined />
                  </Controls.ActionButton>
                </Grid>
              )}
              {connectorList?.length - 1 === i && connectorList?.length < 10 && (
                <Grid item xs={2} container direction="row" alignItems="flex-end" justify="left" style={{marginLeft: '7px'}}>
                  <Controls.ActionButton onClick={() => handleAddClick(connectorList?.length)}>
                    <AddBoxOutlined />
                  </Controls.ActionButton>
                </Grid>
              )}
                </Grid>
            </>
          ))}
              {connectorList?.length < 1 && recordForEdit?.connectors.length === 0 && (
                <Grid item xs={2} container direction="row" alignItems="flex-end" justify="left" style={{marginLeft: '7px'}}>
                  <Controls.ActionButton onClick={() => handleAddClick(connectorList?.length)}>
                    <AddBoxOutlined />
                  </Controls.ActionButton>
                </Grid>
              )}
          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Submit" />
              <Controls.Button text="Cancel" color="default" onClick={closeForm} />
            </div>
          </Grid>
        </Grid>
      </Form>
      <Popup title={"Are you sure ?"} openPopup={confirmationOpen} setOpenPopup={setConfirmationOpen}>
        <RemoveConnectorConfirmation closeConfirmationForm={closeConfirmationForm} handleConfirm={handleConfirm} />
      </Popup>
    </>
  );
};

export default ChargingStationForm;
