import React, { useContext } from "react";
import { DateTime } from "luxon";
import CsvDownloader from "react-csv-downloader";
import { FetchContext } from "../../../context/FetchContext";
import { ImportExport } from "@material-ui/icons";
import { Tooltip } from "@mui/material";

const ExportDataRevenueSubForUser = ({ data, formatNumberWithLeadingZero }) => {
  const fetchContext = useContext(FetchContext);

  function fetchConvertedDate(dateValue, timezone) {
    const dt = DateTime.fromISO(dateValue.replace(" ", "T"), { zone: "UTC" });

    const dtConverted = dt.setZone(timezone);
    return dtConverted.toLocaleString(DateTime.DATE_SHORT) + " " + dtConverted.toLocaleString(DateTime.TIME_SIMPLE);
  }

  const asyncFnComputeData = async () => {
    const dataArr = [];
    if (Boolean(data?.length)) {
      for (let index = 0; index < data?.length; index++) {
        let item = data[index];
        let obj = {
          serial_number: index + 1,
          date: `${formatNumberWithLeadingZero(item?.date?.c?.month)}/${formatNumberWithLeadingZero(item?.date?.c?.day)}/${item?.date?.c?.year} `,
          site_payout: `$${Number(item.site_payout).toFixed(2) || 0}`,
        };
        dataArr.push(obj);
      }
    }
    return Promise.resolve(dataArr);
  };

  const columns = [
    // { id: "serial_number", displayName: "Serial Number" },
    { id: "date", displayName: "Date" },
    { id: "site_payout", displayName: "Site Payout" },
  ];

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear() % 100;
  let finalDate = `${month}-${day}-${year}`;

  return (
    <>
      <CsvDownloader
        filename={`Revenue Detail (${finalDate})`}
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          <ImportExport />
        </Tooltip>
      </CsvDownloader>
    </>
  );
};

export default ExportDataRevenueSubForUser;
