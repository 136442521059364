import { useState, useContext, createContext, useEffect } from "react";
const DesktopContext = createContext({});

export const DesktopProvider = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [forHeaderOnly, setForHeaderOnly] = useState(window.innerWidth > 990);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
      setForHeaderOnly(window.innerWidth > 990)
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <DesktopContext.Provider value={{ isDesktop, forHeaderOnly }}>{children}</DesktopContext.Provider>;
};

export const useIsDesktop = () => useContext(DesktopContext);
