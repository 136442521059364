import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Controls from "../../components/controls/Controls";
import { FetchContext } from '../../context/FetchContext';

const PricePlanSelectAC = (props) => {
  const { value = '', error = null, onChange, siteGroupSelected = null } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  const [currentPricePlan, setCurrentPricePlan] = useState(value);

  useEffect(() => {
    const getAllSites = async () => {

      try {
        const { data } = await fetchContext.authAxios.get(
          '/select-price-plans'
        );
        if (data.data && data.data.length > 0) {

          let temp = [{ id: "none", name: "None" }];
          data.data.map((item) => {
            return temp.push(item);
          });
          setValues(temp);
          setLoaded(true);
        }

      } catch (err) {
        console.log('the err', err);
      }
    };

    getAllSites(fetchContext);

  }, [fetchContext]);

  useEffect(() => {
    if (siteGroupSelected.ac_price_plan_id) {
      setCurrentPricePlan(siteGroupSelected.ac_price_plan_id)
    }

    if (value) {
      setCurrentPricePlan(value);
    }
  }, [siteGroupSelected, value]);

  return (
    <>
      {loaded
        ?
        <Controls.Select
          name="ac_price_plan_id"
          label="Price Plan AC"
          value={currentPricePlan}
          //required={true}
          onChange={onChange}
          options={values}
          error={error}
          style={{ width: "100%" }}
        />
        :
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input name="ac_price_plan_id" label="Price Plan AC" style={{ width: "97.5%" }}><CircularProgress size="1rem" /> </Controls.Input>
      }

    </>

  )
}

export default PricePlanSelectAC;
