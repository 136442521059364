import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const TaxTypeRadioGroup = (props) => {

    const { name, value, onChange } = props;

    return (
        <FormControl>
            <FormLabel>{"Tax Addition as"}</FormLabel>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange}>
                {/* <FormControlLabel  value={"1"} control={<Radio />} label={"Administrator"} /> */}
                <FormControlLabel  value={"1"} control={<Radio />} label={"Inclusive"} />
                <FormControlLabel  value={"0"} control={<Radio />} label={"Exclusive"} />
            </MuiRadioGroup>
        </FormControl>
    )
}

export default TaxTypeRadioGroup;
