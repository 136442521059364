import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from 'react';

const TimePicker = (props) => {
  const { name, label, value, disabled = false, onChange, required, error = null, helperText = '' } = props;

  const setSecondsToZero = (date) => {
    const newDate = new Date(date);
    newDate.setSeconds(0);
    return newDate;
  };

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value
    }
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        disableToolbar
        variant="inline"
        label={label}
        placeholder="HH:MM:SS"
        name={name}
        autoOk={true}
        value={value}
        required={required}
        disabled={disabled}
        format="HH:mm:ss"
        ampm={false}
        onChange={(date) => onChange(convertToDefEventPara(name, setSecondsToZero(date)))}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        keyboardIcon={<AccessTimeIcon />}
        error={Boolean(error)} // Apply error prop here
        helperText={error ? helperText : ''} // Apply helperText prop here
        FormHelperTextProps={{
          error: Boolean(error), // Ensure helper text is styled for error
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
