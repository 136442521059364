import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Controls from "./../../components/controls/Controls";
import { FetchContext } from "../../context/FetchContext";
import CloseIcon from "@mui/icons-material/Close";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function ConnectorsModal({
  handleRemoteStart,
  remoteStartSequenceNumber,
  setRemoteStartSequenceNumber,
  chargingStation,
  remoteStartDialog,
  setRemoteStartDialog,
}) {
  const [open, setOpen] = React.useState(remoteStartDialog);
  const [connectors, setConnectors] = React.useState([]);
  const fetchContext = React.useContext(FetchContext);

  const handleClose = () => {
    setOpen(!remoteStartDialog);
    setRemoteStartDialog(!remoteStartDialog);
  };

  const handleSubmit = () => {
    handleRemoteStart(chargingStation.ocpp_cbid, remoteStartSequenceNumber);
    setOpen(!remoteStartDialog);
    setRemoteStartDialog(false);
  };

  const getStatus = () => {
    fetchContext.authAxios
      .get(`/charging-station-connectors/${chargingStation.id}`)
      .then(({ data }) => {
        setConnectors(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  return (
    <FormControl className="w-100">
      <Typography variant="h6" component="div">
        Available Connectors
      </Typography>
      <RadioGroup
        aria-labelledby="connectors-group-label"
        value={remoteStartSequenceNumber}
        onChange={(e) => setRemoteStartSequenceNumber(e.target.value)}
        name="connector_id"
      >
        {connectors &&
          connectors.map((item) => {
            return (
              <FormControlLabel
                value={item.sequence_number}
                control={
                    <Radio
                    disabled={!(item.status === "AVAILABLE" || item.status === "PREPARING")}
                  />
                  
                }
                label={`Connector ${item.sequence_number} ${
                  item.status === "AVAILABLE" ? "" : `(${item.status})`
                }`}
              />
            );
          })}
      </RadioGroup>
      <div className="w-100 row justify-content-center">
        <Controls.Button
          type="submit"
          text="Submit"
          disabled={connectors.every((item) =>!(item.status === "AVAILABLE" || item.status === "PREPARING"))}
          onClick={handleSubmit}
        />
      </div>
    </FormControl>
  );
}
