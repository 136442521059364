import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../components/controls/Controls";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { useForm } from "../../components/useForm";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const SiteSelect = (props) => {
  const classes = useStyles();
  //const connectorType = ['CHADEMO', 'DOMESTIC_A', 'DOMESTIC_B', 'DOMESTIC_C', 'DOMESTIC_D', 'DOMESTIC_E', 'DOMESTIC_F', 'DOMESTIC_G', 'DOMESTIC_H', 'DOMESTIC_I', 'DOMESTIC_J', 'DOMESTIC_K', 'DOMESTIC_L', 'IEC_60309_2_single_16', 'IEC_60309_2_three_16', 'IEC_60309_2_three_32', 'IEC_60309_2_three_64', 'IEC_62196_T1','IEC', 'IEC_62196_T1_COMBO', 'IEC_62196_T2','IEC', 'IEC_62196_T2_COMBO', 'IEC_62196_T3A','IEC', 'IEC_62196_T3C','IEC', 'PANTOGRAPH_BOTTOM_UP'];
  const { value, error = null, onChange, disabled, isMultiple, recordForEdit, setDropdownValues, dropdownValues } = props;
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm();

  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const noneObj = { "id": null, "name": "None" };

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-sites");
        setDropdownValues(data.data);
        setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  // const handleClick = () => {
  //   if (value?.length === dropdownValues?.length) {
  //     setValues({ ...values, site_id: [] });
  //   } else {
  //     setValues({ ...values, site_id: dropdownValues });
  //   }
  // };

  return (
    <>
      {loaded ? (
        <FormControl variant="standard" className={classes.formControl} style={{ width: "95%" }} {...(error && { error: true })}>
          <InputLabel className={classes.inputLabel} id="demo-mutiple-chip-label">
            Sites
          </InputLabel>
          <Select
            className={classes.select}
            variant="standard"
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            name={isMultiple ? "site_id" : "site"}
            multiple={isMultiple}
            input={<Input id="select-multiple-chip" />}
            value={value}
            disabled={disabled}
            //options={dropdownValues}
            //fullWidth={fullWidth}
            onChange={onChange}
            renderValue={
              isMultiple
                ? (selected) => {
                    return (
                      <div className={classes.chips}>
                        {selected?.map((value) => (
                          <Chip key={value?.name} label={value?.name} className={classes.chip} />
                        ))}
                      </div>
                    );
                  }
                : (selected) => {
                    return selected.name;
                  }
            }
          >
            {!isMultiple
              ? 
              (
                [
                  <MenuItem key="none" value={noneObj}>None</MenuItem>,
                  ...dropdownValues.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))
                ]
              )
              : [{ name: "Select All", data: dropdownValues }, ...dropdownValues].map((item) => {
                  return (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox checked={item.name === "Select All" ? dropdownValues?.length === value?.length : value?.filter((it) => it.id == item.id)?.length > 0} />
                      <ListItemText primary={item?.name} />
                    </MenuItem>
                  );
                })}
            {/* <MenuItem value={{ name: "selectAll", data: dropdownValues }}>
              <Checkbox checked={dropdownValues?.length === value?.length} />
              <ListItemText primary={"Select All"} />
            </MenuItem>
            {[{ name: "selectAll", data: dropdownValues }, ...dropdownValues].map((item) => (
              <MenuItem key={item.id} value={item}>
                <Checkbox checked={item.name === "selectAll" ? dropdownValues?.length === value?.length : value?.filter((it) => it.id === item.id)?.length > 0} />
                <ListItemText primary={item?.name} />
              </MenuItem>
            ))} */}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      ) : (
        <Controls.Input name="site_id" label="Sites" style={{ width: "95%" }}>
          {" "}
        </Controls.Input>
      )}
    </>
  );
};

export default SiteSelect;
