import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@material-ui/core";
import { FetchContext } from "../../../context/FetchContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center",
  },
}));

const ChargerGroupSelect = (props) => {
  const classes = useStyles();
  const { name, label, value, error = null, onChange, required = false, disabled = false, values } = props;

  const fetchContext = useContext(FetchContext);
  // const [values, setValues] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   const getAllChargingStations = async () => {
  //     try {
  //       const { data } = await fetchContext.authAxios.get("/select-charger-groups");
  //       setValues(data.data);
  //       setLoaded(true);
  //     } catch (err) {
  //       console.log("the err", err);
  //     }
  //   };
  //   getAllChargingStations(fetchContext);
  // }, [fetchContext]);

  // console.log("values", values);

  return (
    <FormControl variant="standard" required={required} className={classes.formControl} style={{ width: "95%" }} {...(error && { error: true })}>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <MuiSelect
        //style={{ width: "250px" }}
        className={classes.select}
        variant="standard"
        label={label}
        name={name}
        value={value}
        required={required}
        error={error}
        disabled={disabled}
        //options={values}
        //fullWidth={fullWidth}
        onChange={onChange}
      >
        <MenuItem value={null} style={{ width: "95%" }}>
          None
        </MenuItem>
        {values.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ChargerGroupSelect;
