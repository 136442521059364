import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import EditIcon from "../../assets/images/edit.png";
import DeleteIcon from "../../assets/images/delete.png";
import Controls from "../../components/controls/Controls";

// List of days in a week
const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const WeeklySchedule = ({weekSchedule,setWeekSchedule,handleSubmit}) => {

  const [values, setValues] = useState({
    start_time: null, // Will receive Date objects
    end_time: null, // Will receive Date objects
    variable_fee: "",
  });

  const [editingSlot, setEditingSlot] = useState(null); // To track the slot being edited

  const [errors, setErrors] = useState({
    start_time: false,
    end_time: false,
    variable_fee: false,
    overlap: false,
    timeOrder: false, // Error for invalid time order (start time > end time)
  });

  const [activeAccordion, setActiveAccordion] = useState("Monday"); // Default open to Monday

  const handleInputChange = (e) => {
    setErrors({
      start_time: false,
      end_time: false,
      variable_fee: false,
      overlap: false,
      timeOrder: false,
    });
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const timeToMinutes = (time) => {
    if (time instanceof Date) {
      return time.getHours() * 60 + time.getMinutes();
    }
    return 0; // Return 0 if time is invalid
  };

  const validateForm = () => {
    const { start_time, end_time, variable_fee } = values;
    let valid = true;

    const newErrors = {
      start_time: !start_time,
      end_time: !end_time,
      variable_fee: !variable_fee,
      overlap: false,
      timeOrder: false, // New error field for invalid time order
    };

    if (!start_time || !end_time || !variable_fee) {
      valid = false;
    }

    if (
      start_time &&
      end_time &&
      timeToMinutes(end_time) <= timeToMinutes(start_time)
    ) {
      newErrors.timeOrder = true; // Set error for invalid time order
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const isTimeOverlap = (existingSlots, startTime, endTime) => {
    const start = timeToMinutes(startTime);
    const end = timeToMinutes(endTime);

    return existingSlots.some((slot) => {
      const [slotStart, slotEnd] = slot.time.split(" - ").map((time) => {
        const [hours, minutes] = time.split(":");
        return parseInt(hours) * 60 + parseInt(minutes);
      });
      return start < slotEnd && end > slotStart;
    });
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const addSlot = (day) => {
    const { start_time, end_time, variable_fee } = values;

    if (!validateForm()) {
      return; // Don't proceed if the form is invalid
    }

    const startTimeFormatted = formatTime(start_time);
    const endTimeFormatted = formatTime(end_time);

    // Temporarily remove the editing slot if it exists
    let existingSlots = weekSchedule[day];
    if (editingSlot) {
      existingSlots = existingSlots.filter(
        (slot) => slot.id !== editingSlot.id
      );
    }

    // Check for time overlap on the same day with the remaining slots
    if (isTimeOverlap(existingSlots, start_time, end_time)) {
      setErrors({ ...errors, overlap: true });
      return;
    }

    const newSlot = {
      id: editingSlot ? editingSlot.id : existingSlots.length + 1, // Use existing id if editing
      time: `${startTimeFormatted} - ${endTimeFormatted}`,
      fee: `${variable_fee}$`,
    };

    setWeekSchedule((prev) => ({
      ...prev,
      [day]: editingSlot
        ? prev[day].map((slot) => (slot.id === editingSlot.id ? newSlot : slot))
        : [...prev[day], newSlot],
    }));

    // Reset the form and errors after successful submission
    resetForm();
  };

  const resetForm = () => {
    setValues({ start_time: null, end_time: null, variable_fee: "" });
    setErrors({
      start_time: false,
      end_time: false,
      variable_fee: false,
      overlap: false,
      timeOrder: false,
    });
    setEditingSlot(null); // Clear editing state
  };

  const editSlot = (slot) => {
    setErrors({
      start_time: false,
      end_time: false,
      variable_fee: false,
      overlap: false,
      timeOrder: false,
    });
    setValues({
      start_time: new Date(`1970-01-01T${slot.time.split(" - ")[0]}:00`),
      end_time: new Date(`1970-01-01T${slot.time.split(" - ")[1]}:00`),
      variable_fee: slot.fee.replace("$", ""),
    });
    setEditingSlot(slot); // Set the slot being edited
  };

  const deleteSlot = (day, id) => {
    setErrors({
      start_time: false,
      end_time: false,
      variable_fee: false,
      overlap: false,
      timeOrder: false,
    });
    setWeekSchedule({
      ...weekSchedule,
      [day]: weekSchedule[day].filter((slot) => slot.id !== id),
    });
  };

  const handleAccordionToggle = (day) => {
    setActiveAccordion(activeAccordion === day ? "" : day);
  };


  const handleCancelEdit = () => {
    resetForm(); // Reset the form when canceling the edit
  };

  return (
    <div className="container mt-5">
      <div className="accordion" id="weeklySchedule">
        {weekdays.map((day) => (
          <div className="accordion-item" key={day}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${
                  activeAccordion === day ? "" : "collapsed"
                }`}
                type="button"
                onClick={() => handleAccordionToggle(day)}
              >
                {day.toUpperCase()}
              </button>
            </h2>
            <div
              id={day.toLowerCase()}
              className={`accordion-collapse collapse ${
                activeAccordion === day ? "show" : ""
              }`}
            >
              <div className="accordion-body row">
                <div className="d-flex w-100 gap-1">
                  {weekSchedule[day].map((slot) => (
                    <div className="" key={slot.id}>
                      <div className="time-slot d-flex">
                        <span className="slottime">{slot.time}</span>
                        <span className="fw-bold Variablefee">{slot.fee}</span>
                        <div className="actionicon">
                          <button
                            className="btn-icon editicon"
                            onClick={() => editSlot(slot)} // Trigger edit on click
                          >
                            <img src={EditIcon} alt="Edit" />
                          </button>
                          <button
                            className="btn-icon delete"
                            onClick={() => deleteSlot(day, slot.id)}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  {weekSchedule[day].length === 1 && (
                    <>
                      {/* Render two divs for the single slot */}
                      <div className="" key={weekSchedule[day][0].id}></div>
                    </>
                  )}
                </div>
                <div className="clearfix"></div>
                <div className="add-form mt-3">
                  <div className="row d-flex justify-content-center">
                    {/* <div className="col-auto addslottext">
                      Add slot for {day}:
                    </div> */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Controls.TimePicker
                          name="start_time"
                          label="Start Time"
                          value={values.start_time}
                          onChange={handleInputChange}
                          error={errors.start_time}
                          helperText={
                            errors.start_time && "Start time is required"
                          }
                          // required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controls.TimePicker
                          name="end_time"
                          label="End Time"
                          value={values.end_time}
                          onChange={handleInputChange}
                          error={errors.end_time || errors.timeOrder}
                          helperText={
                            errors.end_time
                              ? "End time is required"
                              : errors.timeOrder
                              ? "End time must be after the start time"
                              : ""
                          }
                          // required
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controls.Input
                          name="variable_fee"
                          label="Variable Fee ($)"
                          value={values.variable_fee}
                          onChange={handleInputChange}
                          error={errors.variable_fee}
                          helperText={
                            errors.variable_fee && "Variable fee is required"
                          }
                          // required
                          fullWidth
                        />
                      </Grid>
                      {errors.overlap && (
                        <Grid item xs={12}>
                          <span className="text-danger">
                            Time slot overlaps with an existing slot
                          </span>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={1}>
                        <Button
                          className="btn btn-add"
                          onClick={() => addSlot(day)}
                        >
                          {editingSlot?.id ? "Update" : "Add"}{" "}
                          {/* Change button text based on state */}
                        </Button>
                      </Grid>
                      {editingSlot && ( // Show cancel button only when editing
                        <Grid item xs={12} sm={1}>
                          <button
                            className="btn btn-cancel mx-3 btn-icon"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </button>
                        </Grid>
                      )}
                    </Grid>
        {/* <button onClick={handleSubmit}>submit</button> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklySchedule;
