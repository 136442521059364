import React, { useState, useContext, useEffect } from 'react';
import { FetchContext } from '../../context/FetchContext';
import Controls from "../../components/controls/Controls";
import { CircularProgress } from '@mui/material';

const SitesSelect = (props) => {
  const { value = '', error = null, onChange, style } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          '/select-sites'
        );
        setValues(data.data);
        setLoaded(true);
      } catch (err) {
        console.log('the err', err);
      }
    };

    getAllSites(fetchContext);

  }, [fetchContext]);

  return (
    <>
      {loaded
        ?
        <Controls.Select
          name="site_id"
          label="Site"
          value={value}
          required={props.required}
          onChange={onChange}
          options={values}
          error={error}
          style={style}
        />
        :
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input required={props.required} name="site_id" label="Site" style={{ width: "95%" }}><CircularProgress size="1rem" /> </Controls.Input>
      }

    </>

  )
}

export default SitesSelect;