import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Controls from "./../../components/controls/Controls";
import { FetchContext } from "../../context/FetchContext";
import CloseIcon from "@mui/icons-material/Close";
import { socketFetch } from "../../services/socket";
import { useRef } from "react";
import { useState } from "react";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function TriggerModal({
  handleTriggerStatus,
  triggerStartNumber,
  settriggerStartNumber,
  chargingStation,
  triggerpopup,
  setTriggerPopup,
  onClose,
  setNotify,
}) {
  const [open, setOpen] = React.useState(triggerpopup);
  const [connectors, setConnectors] = React.useState([]);
  const fetchContext = React.useContext(FetchContext);

  const handleClose = () => {
    setOpen(!triggerpopup);
    setTriggerPopup(!triggerpopup);
  };
  const abortController = useRef(new AbortController());

  const handleSubmit = () => {
    // handleTriggerStatus(chargingStation?.ocpp_cbid, triggerStartNumber);
    setOpen(!triggerpopup);
    setTriggerPopup(false);
    socketFetch
    .post(`/triggerstatus`, {
      charger_id: chargingStation?.ocpp_cbid,
      connector: Number(triggerStartNumber),  // Use the first connector if only one
    })
    .then(({ data }) => {
      if (abortController.current.signal.aborted) {
        setNotify({
          isOpen: true,
          message: `There is some issue, please try again`,
          type: "error",
        });
        onClose();
        return;
      }
      setNotify({
                isOpen: true,
                message: data.message.status ? data.message.status :data.message,
                type: data.message.status ? "success" : "error",
              });
      //  if (data?.message?.status) {
      //         setNotify({
      //           isOpen: true,
      //           message: data.message.status,
      //           type: "success",
      //         });
      //       } else {
      //         setNotify({
      //           isOpen: true,
      //           message: data.message,
      //           type: "error",
      //         });
      //       }
            onClose();
          })
    .catch((err) => {
      setNotify({
        isOpen: true,
        message: `There is some issue, please try again: ${err.response.data.message}`,
        type: "error",
      });
      onClose();
      if (abortController.current.signal.aborted) {
        return;
      }
    });
  };

  const getStatus = () => {
      fetchContext.authAxios.get(
          `/charging-station-connectors/${chargingStation?.id}`
      ).then(({ data }) => {
          setConnectors(data.data);
      })
          .catch((err) => {
              console.error(err);
          })
  };

  React.useEffect(() => {
      getStatus();
  }, []);

  return (
    <FormControl className="w-100">
      <Typography variant="h6" component="div">
        Available Connectors
      </Typography>
      <RadioGroup
        aria-labelledby="connectors-group-label"
        value={triggerStartNumber}
        onChange={(e) => settriggerStartNumber(e.target.value)}
        name="connector_id"
      >
        {connectors &&
          connectors.map((item) => {
            return (
              <FormControlLabel
                value={item.sequence_number}
                control={<Radio 
                  // disabled={item.status !== "AVAILABLE"} 
                  />}
                label={`Connector ${item.sequence_number} ${
                  item.status === "AVAILABLE" ? "" : `(${item.status})`
                }`}
              />
            );
          })}
      </RadioGroup>
      <div className="w-100 row justify-content-center">
        <Controls.Button
          type="submit"
          text="Submit"
          // disabled={connectors.every((item) => item.status !== "AVAILABLE")}
          onClick={handleSubmit}
        />
      </div>
    </FormControl>
  );
}
