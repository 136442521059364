import axios from 'axios';
import React, { createContext, useContext } from 'react';
import { AuthContext } from './AuthContext';

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  //const adminStatus = authContext.authState.user.role_id === 3 ? true : false;

  // const [ isAdmin, setIsAdmin ] = useState(false);
  // const [ isManage, setIsManage ] = useState(false);
  // const [ userName, setUserName ] = useState('');


  //let isAdmin = authContext.authState.user.role_id === 3 ? true : false;
  //let isManage = authContext.authState.user.role_id === 2 ? true : false;
  //let userName = authContext.authState.user.first_name;

  const name = localStorage.getItem('userName');
  const role = localStorage.getItem('userRole');
  const chStPermission = localStorage.getItem('userPermissionChSt');
  const isAdmin = role === "3" ? true : false;
  //const isView = (role === "1" || role === "4") ? true : false;
  const isView = role === "1"  ? true : false;
  const isTechnician = role === "4" ? true : false;
  const isChStPermission = chStPermission === "true" ? true : false;
  const userName = name;

  // useEffect(() => { 
  //   const name = localStorage.getItem('userName');
  //   const role = localStorage.getItem('userRole');

  //   // for (const [key, value] of Object.entries(user)) {
  //   // }

  //   const isAdmin = role === 3 ? true : false;
  //   const isManage = role === 2 ? true : false;
  //   const userName = name;
  // }, []);


  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  authAxios.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${authContext.authState.token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const code =
        error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log('error', code);
      }
      if (code === 422) {
        return error.response;
      } else {
        return Promise.reject(error);
      }
      //return Promise.reject(error.response);

    }
  );

  return (
    <Provider
      value={{
        authAxios,
        isAdmin,
        isView,
        isChStPermission,
        isTechnician,
        userName
      }}

    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };

