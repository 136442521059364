import * as echarts from 'echarts'; // Import ECharts library
import 'echarts/lib/chart/bar'; // Import the specific chart type you need
import 'echarts/lib/component/title'; // Import necessary components
import React, { useEffect, useRef } from 'react';


function ChargingPeriod(props) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    const colorList = [
      '#8A0A0B',
      '#8A0A0B',
      '#8A0A0B',
      '#8A0A0B',
      '#8A0A0B',
      '#8A0A0B'
    ];

    const data = props.chargingSchedulePeriodGraphData.map(function (item, index) {
      return {
        value: item,
        itemStyle: {
          color: '#8A0A0B'
        }
      };
    });

    let option = {
      title: {
        text: 'Chart Preview',
        left: 'start'
      },
      tooltip: {
        formatter: function (params) {
          const tooltipContent = document.createElement('div');

          // Create custom DOM structure
          const fromTime = document.createElement('p');
          fromTime.style.color = '#8A0A0B'; // Customize styles
          fromTime.innerText = `From: ${parseInt(params.value[0])}:${parseInt((params.value[0] - parseInt(params.value[0])) * 60)}`;

          const toTime = document.createElement('p');
          toTime.innerText = `To: ${parseInt(params.value[1])}:${parseInt((params.value[1] - parseInt(params.value[1])) * 60)}`;

          const limitData = document.createElement('p');
          limitData.innerText = `Limit: ${params.value[2]} A`;

          tooltipContent.appendChild(fromTime);
          tooltipContent.appendChild(toTime);
          tooltipContent.appendChild(limitData);

          // return `From: ${parseInt(params.value[0])}:${parseInt((params.value[0] - parseInt(params.value[0])) * 60)} \n
          // To: ${parseInt(params.value[1])}:${parseInt((params.value[1] - parseInt(params.value[1])) * 60)}\n
          // Limit: ${params.value[2]} A
          // `;
          return tooltipContent.outerHTML;
        }
      },
      xAxis: {
        scale: true
      },
      yAxis: {},
      series: [
        {
          type: 'custom',
          renderItem: function (params, api) {
            var yValue = api.value(2);
            var start = api.coord([api.value(0), yValue]);
            var size = api.size([api.value(1) - api.value(0), yValue]);
            var style = api.style();
            return {
              type: 'rect',
              shape: {
                x: start[0],
                y: start[1],
                width: size[0],
                height: size[1]
              },
              style: style
            };
          },
          label: {
            show: true,
            position: 'top'
          },
          dimensions: ['From', 'To', 'Limit'],
          encode: {
            x: [0, 1],
            y: 2,
            tooltip: [0, 1, 2],
            itemName: 3
          },
          data: data
        }
      ]
    };

    chartInstance.setOption(option); // Set chart options

    return () => {
      chartInstance.dispose(); // Clean up on component unmount
    };
  }, [props.chargingSchedulePeriodGraphData]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
}

export default ChargingPeriod;
