import React, { useContext, useEffect, useState } from "react";
//import { FetchContext } from '../../context/FetchContext';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Form, useForm } from "../../../components/useForm";
import { FetchContext } from "../../../context/FetchContext";
import Controls from "../../../components/controls/Controls";
import useTable from "../../../components/useTable";
import { Bar, BarChart } from "recharts";
import { useIsDesktop } from "../../../context/DisplayContext";
import { TYPE_1, TYPE_2, TYPE_3 } from "../../Reports/RevenueReport/RevenueType";
import Loader from "../../../components/Loader";
import ExportDataRevenueSub from "../ExportDataRevenueSub";
import ExportDataRevenueSubForUser from "./ExportDataRevenueSubForUser";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    // position: "absolute",
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  newButton: {
    // position: "absolute",
    right: "0px",
    //width: '75%'
  },
  formAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  exportIcon: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}));

const RevenueForUser = () => {
  const [data, setData] = useState();
  const [maxValue, setMaxValue] = useState(250);
  const dateStr = DateTime.now();
  const selectedMonth = dateStr.toFormat(`01-${"MM-yyyy"}`);
  const classes = useStyles();
  // const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const fetchContext = useContext(FetchContext);
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(futureDate);
  const [endDate, setEndDate] = useState(new Date());
  const { isDesktop } = useIsDesktop();

  // const start = DateTime.now().minus({ days: 30 });
  // const end = DateTime.now();

  const initialFValues = {
    from_date: startDate,
    to_date: endDate,
  };

  const formatDateToDDMMYYYY = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getAllTransactions = async (fetchContext, payload) => {
    try {
      let updatedPayload = {
        range: payload?.range,
        to_date: formatDateToDDMMYYYY(payload?.to_date),
        from_date: formatDateToDDMMYYYY(payload?.from_date),
      };
      const { data } = await fetchContext.authAxios.post("/get-energy-revenue", updatedPayload);
      setLoaded(true);
      handleFilterData(data.data, { to_date: payload.to_date, from_date: payload.from_date });
    } catch (err) {
      console.log("the err", err);
    }
  };

  const sitePayout = (item, licensing_fee, final_amount, processing_fee) => {
    if(final_amount < 0){
      return 0;
    }
    // let fees = item?.processing_fee;
    if (item?.agreement_type) {
      switch (item?.agreement_type) {
        case TYPE_1:
          return licensing_fee;
        case TYPE_2:
          return final_amount;
        case TYPE_3:
          return item?.amount - item?.tax_amount - processing_fee;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const handleFilterData = (data, valuesdd) => {
    const dailyData = {};
    let start = DateTime.fromJSDate(valuesdd.from_date);
    let end = DateTime.fromJSDate(valuesdd.to_date);
    let transactions = data;
    if (!transactions) {
      return;
    }

    // const dailyData = {
    //   datetime: DateTime,
    //   count: 0,
    //   usage: 0
    // };

    let datetime = start.startOf("day");
    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        amount: 0,
        site_payout: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }


    transactions.forEach((transaction) => {
      if (!transaction.amount && transaction.amount < 0) {
        return;
      }

      // if (!transaction.meter_end || !transaction.meter_start) {
      //   return;
      // }

      const key = DateTime.fromSQL(transaction.graph_date).startOf("day").toISO();
      // if (key && dailyData[key]) {
      //   let processing_fee = 0;
      //   if (transaction?.processing_fee_type === "percentage") {
      //     processing_fee = (transaction?.amount - transaction?.tax_amount) * (transaction?.processing_fee / 100);
      //   }
      //   let licensing_fee = transaction?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0);
      //   let final_amount = transaction?.amount - transaction?.tax_amount - processing_fee - licensing_fee;
      //   dailyData[key].count++;
      //   dailyData[key].amount += transaction.amount;
      //   console.log("sitePayout(transaction, licensing_fee, final_amount, processing_fee)", sitePayout(transaction, licensing_fee, final_amount, processing_fee));
      //   dailyData[key].site_payout += sitePayout(transaction, licensing_fee, final_amount, processing_fee);
      // }
      // if (key && dailyData[key]) {
      //   // if (transaction?.connector?.charging_stations?.site?.processing_fee?.length > 0) {
      //   let processing_fee = transaction?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => {
      //     if (current?.processing_fee_type === "percentage") {
      //       return acc + (transaction?.amount - transaction?.tax_amount) * (current?.processing_fee / 100);
      //     } else {
      //       return acc + current?.processing_fee * transaction?.total;
      //     }
      //   }, 0);

      //   // let licensing_fee = transaction?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0);
      //   let licensing_fee = transaction?.connector?.charging_stations?.site?.processing_fee?.reduce(
      //     (acc, current) => acc + current?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0),
      //     0
      //   );
      //   let final_amount = transaction?.amount - transaction?.tax_amount - processing_fee - licensing_fee;
      //   // }
      //   dailyData[key].count++;
      //   dailyData[key].amount += transaction.amount;
      //   dailyData[key].site_payout += sitePayout(transaction, licensing_fee, final_amount, processing_fee);
      // }
      if (key && dailyData[key]) {
        // if (transaction?.connector?.charging_stations?.site?.processing_fee?.length > 0) {
        // let processing_fee = transaction?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => {
        // let processing_fee = 0;
        // if (transaction?.processing_fee_type === "percentage") {
        //   processing_fee = transaction?.amount <= 0 ? 0 :  (transaction?.amount - transaction?.tax_amount) * (transaction?.processing_fee / 100);
        // }
        // // }, 0);

        // // let licensing_fee = transaction?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0);
        // let licensing_fee = transaction?.amount <= 0 ? 0 : transaction?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0);
        // let final_amount = transaction?.amount <= 0 ? 0 : transaction?.amount - transaction?.tax_amount - processing_fee - licensing_fee;
        // // }
        dailyData[key].count++;
        dailyData[key].amount += transaction.amount;
        // dailyData[key].site_payout += transaction?.amount <= 0 ? 0 : sitePayout(transaction, licensing_fee, final_amount, processing_fee);
        dailyData[key].site_payout += transaction?.amount <= 0 ? 0 : transaction.processingFee.site_payout      }
    });
console.log("dailyData",dailyData);

    setData(
      Object.values(dailyData).reduce((acc, { count, datetime, amount, site_payout }) => {
        const dt = DateTime.fromMillis(datetime.ts);
        const month = dt.monthLong;

        return [
          ...acc,
          datetime !== undefined && {
            amount: amount.toFixed(2),
            average: count ? getRound(amount, count) : 0,
            average_site_payout: count ? getRound(site_payout, count) : 0,
            site_payout: site_payout.toFixed(2),
            month,
            date: datetime,
          },
        ];
      }, [])
    );
  };

  useEffect(() => {
    getAllTransactions(fetchContext, { range: true, from_date: values.from_date, to_date: values.to_date });
  }, [fetchContext]);

  const headCells = [{ id: "", label: "" }];

  const { TblContainer } = useTable(headCells);

  const getRound = (value1, value2) => {
    let response = 0;
    if (value2 !== undefined) response = (value1 / value2).toFixed(2);
    else response = value1 / value2;

    return response;
  };

  const getTotal = (data) => {
    let allTotal = 0;
    // let allAverage = 0;
    let number_of_days = data?.length;
    let final_average = 0;
    if (data) {
      for (const d of data) {
        // let field = d.amount;
        let field = fetchContext?.isAdmin ? d.amount : d.site_payout;
        if (Number(field) > 0) {
          allTotal = allTotal + parseFloat(field);
        }
      }
    }
    final_average = allTotal > 0 ? (allTotal / number_of_days).toFixed(2) : "0.00";
    return {
      allTotal: allTotal.toFixed(2),
      // allAverage: allAverage.toFixed(2)
      average: final_average,
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    //if ('name' in fieldValues)
    //temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    setErrors,
    errors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  useEffect(() => {
    if (data) {
      const maxUsage = data.reduce((max, obj) => {
        const usage = parseFloat(obj.amount);
        return usage > max ? usage : max;
      }, 0);

      const nextMultiple = Math.ceil(maxUsage / 10) * 10;
      setMaxValue(nextMultiple);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStartDate(DateTime.fromJSDate(values.from_date));
    setEndDate(DateTime.fromJSDate(values.to_date));
    let updatedPayload = {
      range: true,
      from_date: values.from_date,
      to_date: values.to_date,
    };
    getAllTransactions(fetchContext, updatedPayload);
  };

  const onClear = () => {
    let updatedPayload = {
      range: false,
      from_date: futureDate,
      to_date: new Date(),
    };
    getAllTransactions(fetchContext, updatedPayload);
    setValues({
      from_date: futureDate,
      to_date: new Date(),
    });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light p-2">
          <p className="py-0 my-0">{`${payload[0].payload.month} ${label}`}</p>
          <p className="py-0 my-0 text-danger">{`Site Payout : $ ${payload[0]?.payload?.site_payout}`}</p>
        </div>
      );
    }
    return null;
  };

  function formatNumberWithLeadingZero(number) {
    if (number >= 1 && number <= 9) {
      return `0${number}`;
    } else {
      return `${number}`;
    }
  }

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar> */}
        <div className={classes.formAlignment}>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Revenue Graph
            </Typography>
          </div>

          <div className={classes.newButton}>
            <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <Controls.DatePicker
                    name="from_date"
                    label="From Date"
                    value={values.from_date}
                    onChange={handleInputChange}
                    error={errors.from_date}
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <Controls.DatePicker
                    name="to_date"
                    label="To Date"
                    value={values.to_date}
                    minDate={values.from_date}
                    onChange={handleInputChange}
                    error={errors.to_date}
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={isDesktop ? 3 : 6}>
                  <div>
                    <Controls.Button type="submit" text="Fetch" size="small" />
                    <Controls.Button text="Clear" color="default" size="small" onClick={onClear} />
                  </div>
                </Grid>
                <Grid item xs={isDesktop ? 0 : 6}>
                  <div className={classes.exportIcon}>
                    <ExportDataRevenueSubForUser data={data} formatDateToDDMMYYYY={formatDateToDDMMYYYY} formatNumberWithLeadingZero={formatNumberWithLeadingZero} />
                  </div>
                </Grid>
              </Grid>
            </Form>
          </div>
        </div>
        {/* </Toolbar> */}
        <br />
        <br />
        <br />
        <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
          <div class="card-body">
            <div class="card-header row" style={{ background: "#fff" }}>
              <div class="col-md-6">
                <strong class="card-title">Revenue </strong> <span class="card-title">(USD)</span>{" "}
              </div>
              {/* <div class="col-md-3 totalkwh">&nbsp;</div> */}
              <div class="col-md-3 totalkwh">
              Total <span>{getTotal(data).allTotal}</span>{" "}
              </div>
              <div class="col-md-3 totalkwh">
                {" "}
                Average <span>{getTotal(data).average}</span>
              </div>
            </div>
            <div class="clearfix"></div>
            <div className="d-flex flex-row">
              <div
                style={{
                  writingMode: "vertical-rl",
                  transform: "rotate(-180deg)",
                  width: "fit-content",
                }}
                className="text-vertical text-center"
              >
                Site Payout (USD)
              </div>
              <Table>
                <TableRow>
                  <TableCell>
                    <div className="responsiveCharts">
                      <ResponsiveContainer>
                        <BarChart
                          // width={560}
                          // height={400}
                          data={data?.map((dt) => {
                            return {
                              ...dt,
                              date: dt?.date?.c?.day,
                            };
                          })}
                          margin={{
                            top: 0,
                            right: 0,
                            left: -9,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="1 1" />
                          <XAxis dataKey="date" />
                          <YAxis domain={[0, maxValue]} tickCount={11} />
                          {/* <YAxis /> */}
                          {/* <Bar type="monotone" dataKey="revenue" stackId="a" fill="#4d32de"  />
                        <Bar type={cardinal} dataKey="average" stackId="a" fill="#fb8840"  /> */}
                          <Tooltip content={<CustomTooltip />} />
                          {/* <Legend wrapperStyle={{ position: 'relative' }} /> */}

                          <Bar type="monotone" dataKey="site_payout" fill="#a11011" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </TableCell>
                </TableRow>
              </Table>
            </div>
            <div className="text-center pt-0 mt-0">
              <span style={{ fontSize: "24px", lineHeight: 1.3, color: "rgb(161, 16, 17)" }}>&#9632;</span> <span>Date</span>
            </div>
          </div>
        </div>

        <TblContainer>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblCellInside}>#</TableCell>
              <TableCell className={classes.tblCellInside}>Date</TableCell>
              <TableCell className={classes.tblCellInside}>Site Payout</TableCell>
            </TableRow>
          </TableHead>
          {loaded ? (
            <TableBody>
              {data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{`${formatNumberWithLeadingZero(item?.date?.c?.month)}/${formatNumberWithLeadingZero(item?.date?.c?.day)}/${
                    item?.date?.c?.year
                  } `}</TableCell>
                  <TableCell>$ {Number(item.site_payout).toFixed(2) || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
      </Paper>
    </>
  );
};

export default RevenueForUser;