import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center"
  }
}));

const ChargingStationStatusSelect = (props) => {
    const classes = useStyles();
    const { name, label, value, error=null, onChange, required=false } = props;

    return (
      <FormControl variant="standard" required={required} className={classes.formControl}
      style={{ width: "95%" }}
      {...(error && {error:true})}>
          <InputLabel className={classes.inputLabel}>{label}</InputLabel>
          <MuiSelect
              //style={{ width: "250px" }}
              className={classes.select}
              variant="standard"
              label={label}
              name={name}
              value={value}
              required={required}
              error={error}
              //options={values}
              //fullWidth={fullWidth}
              onChange={onChange}>
            <MenuItem value='None' style={{ width: "95%" }}>None</MenuItem> 
            <MenuItem value='AVAILABLE' style={{ width: "95%" }}>AVAILABLE</MenuItem> 
            <MenuItem value='UNAVAILABLE' style={{ width: "95%" }}>UNAVAILABLE</MenuItem> 
            <MenuItem value='OCCUPIED' style={{ width: "95%" }}>OCCUPIED</MenuItem> 
            <MenuItem value='FAULTED' style={{ width: "95%" }}>FAULTED</MenuItem>
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
  )
}

export default ChargingStationStatusSelect;
