import React from "react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";



const Button = (props) => {
  const { text, size, color, variant, disabled = false, onClick, isPaddingTop = true, ...other } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      ...(isPaddingTop && { marginTop: theme.spacing(3) }),
      marginLeft: theme.spacing(1),
    },
    label: {
      textTransform: "none",
    },
  }));
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "small"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
      disabled={disabled}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
};

export default Button;
