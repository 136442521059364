import { Paper, TableBody, TableCell, TableRow, Toolbar, Typography, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Popup from "../../components/Popup";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import SiteGroupForm from "./SiteGroupForm";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Filter from "./Filter";
import {
  BrowserRouter as Router,
  //HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    // position: "absolute",
    // right: "10px",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    //position: 'absolute',
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  link: {
    color: "black !important", // Set the color to red and use !important
    textDecoration: "none", // Remove underline from the link
  },
  searchIcon: {
    marginTop: "25px",
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  exportIcon: {
    //position: 'absolute',
    marginTop: "25px",
    //right: '200px',
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    alignItems: "center",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
    },
  },
}));

const SiteGroups = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showSearch, setShowSearch] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    is_search: !showSearch,
    export: false,
  });
  const headCells = [
    { id: "name", label: "Site Account Name" },
    // { id: 'ac_price_plan', label: 'Price Plan AC' },
    // { id: 'price_plan', label: 'Price Plan DC' },
    // { id: 'tax_rates', label: 'Tax Rates' },
    //{ id: 'actions', label: '', disableSorting: true }
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.get(`/site-groups?page=${page}`);
      setRecords(data.data.data);
      setTotalRecord(data.data.total);

      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, page);
  }, [fetchContext, page]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const handleSubmit = (values) => {
    // try {
    //   await fetchContext.authAxios.post('/site-groups', values);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }
    fetchContext.authAxios
      .post("/site-groups", values)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Site Account can't be created as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Site Account can't be created as: ${err}`,
          type: "error",
        });
      });
  };

  const handleEdit = (id, values) => {
    // try {
    //   await fetchContext.authAxios.put(`/site-groups/${id}`, values);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }
    fetchContext.authAxios
      .put(`/site-groups/${id}`, values)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Site Account can't be edited as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Site Account can't be edited as: ${err}`,
          type: "error",
        });
      });
  };

  const handleDelete = async (id) => {
    // try {
    //   await fetchContext.authAxios.delete(`/site-groups/${id}`);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }
    fetchContext.authAxios
      .delete(`/site-groups/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Site Account can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Site Account can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };

  const addOrEdit = (siteGroup, resetForm) => {
    if (siteGroup.id === 0) handleSubmit(siteGroup);
    else handleEdit(siteGroup.id, siteGroup);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // setNotify({
    //     isOpen: true,
    //     message: 'Submitted Successfully',
    //     type: 'success'
    // })
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const closeForm = () => {
    setOpenPopup(false);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);
    // setNotify({
    //     isOpen: true,
    //     message: 'Deleted Successfully',
    //     type: 'error'
    // })
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1);
    setFilterObject({
      search_keyword: null,
      is_search: !showSearch,
      export: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    filterAction(fetchContext, page, values);
  };

  const filterAction = async (fetchContext, page, filterObject) => {
    fetchContext.authAxios
      .post(`filter-site-groups?page=${page}`, filterObject)
      .then(({ data }) => {
        setRecords(data.data.data);
        setTotalRecord(data.data.total);
        
        if (data.data.total && data.data.total > 20) {
          let count = Math.ceil(data.data.total / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
      
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Site Accounts
            </Typography>
          </div>
          {fetchContext.isAdmin && (
            <div className={classes.searchIcon} onClick={onShowHideSearch}>
              {showSearch ? (
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Remove Search">
                  <SearchOffIcon />
                </Tooltip>
              )}
            </div>
          )}
          {fetchContext.isAdmin && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar> */}
        <div className={`row ${classes.headerContainer}`}>
          <div className="col">
            <div className={classes.pageTitle}>
              {/* <Typography variant="h5" component="div"> */}
                Site Accounts
              {/* </Typography> */}
            </div>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            <div className="col" style={{ display: "inline-block" }}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            <div className="col" style={{ display: "inline-block" }}>
              {fetchContext.isAdmin && (
                <Controls.Button
                  style={{ color: "#a31112", border: "1px solid #a31112" }}
                  text="Add New"
                  variant="outlined"
                  isPaddingTop={false}
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!showSearch && <Filter showSearch={showSearch} setFilterObject={setFilterObject} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearch} />}

        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Link to={`/sites/${encodeURIComponent(item.name)}`} className={classes.link}>
                      {item.name}
                    </Link>
                  </TableCell>

                  {/* <TableCell>{item.ac_price_plan_id && item.ac_price_plan_id !== null ? item.ac_price_plan.name : '--'}</TableCell>
                  <TableCell>{item.price_plan_id && item.price_plan_id !== null ? item.price_plan.name : '--'}</TableCell>
                  <TableCell>
                    {item.tax_details.length > 0
                      ?
                      item.tax_details.map(val => (`${val.name}, `))
                      :
                      '--'
                    }
                  </TableCell> */}
                  {fetchContext.isAdmin && (
                    <TableCell class="text-nowrap">
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(item.id);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Site Account" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <SiteGroupForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default SiteGroups;
