import React from 'react';
//import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
//import { Country }  from 'country-state-city';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";

//const temp = Country.getCountryByCode('US');
//const countries = Country.getAllCountries();


// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     width: "20vw"
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   },
//   select: {
//     //height: "10vh"
//   },
//   inputLabel: {
//     fontSize: "4vh",
//     alignSelf: "center"
//   }
// }));

const TimezonesSelect = (props) => {
    //const classes = useStyles();
    const { options, inputValue, onChange, onInputChange, getOptionLabel, selectedOption } = props;
    //const filter = createFilterOptions();
    const isOptionEqualToValue = (option, anotherOption) =>
      option.value === anotherOption.value;
    return (
      <Autocomplete
      id="timezonde-select"
      size="small"
      popupIcon={<ArrowDropDownIcon style={{fill: "#a31112"}}/>}
      value={selectedOption}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      //getOptionDisabled={getOptionDisabled}
      //selectedValues={selectedOptions}
      //label={'Select Charging Stations'}      
      autoHighlight
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {/* <img
            loading="lazy"
            width="20"            
            src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
            alt=""
          /> */}
          {`GMT ${option.currentTimeFormat}`}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ width: "97.5%" }}
          label="Choose a timezone"
          required
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          variant="standard"
        />
      )}
    />
  );
}

export default TimezonesSelect;
