import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const UserPermissionRadioGroup = (props) => {

    const { name, value, onChange } = props;

    return (
        <FormControl>
            <FormLabel>{"Define further permission level by"}</FormLabel>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange}>
                {/* <FormControlLabel  value={"1"} control={<Radio />} label={"Administrator"} /> */}
                <FormControlLabel  value={"sgrp"} control={<Radio />} label={"Site Accounts"} />
                <FormControlLabel  value={"site"} control={<Radio />} label={"Sites"} />
                <FormControlLabel  value={"chst"} control={<Radio />} label={"Charging Stations"} />
                <FormControlLabel  value={"glbl"} control={<Radio />} label={"All"} />
            </MuiRadioGroup>
        </FormControl>
    )
}

export default UserPermissionRadioGroup;
