import { Button, Grid, Paper, TableBody, TableCell, TableRow, Toolbar, Typography, makeStyles, styled, LinearProgress } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import { socketFetch } from "../../services/socket";
import Confirm from "../ChargingStations/Confirm";
import SitesSelect from "../Sites/SitesSelect";
import SiteEVSERadioGroup from "../LoadBalancing/SiteEVSERadioGroup";
import ManufacturersSelect from "../ChargingStations/ManufacturersSelect";
import ChargerTypeSelect from "../ChargingStations/ChargerTypeSelect";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import axios from "axios";
import AWS from "aws-sdk";
import ChargingStationSelect from "../RFID/ChargingStationSelect";
import ChargingStationSelector from "../ChargingStations/ChargingStationSelect";
import ChargerTypeSelector from "../ChargingStations/ChargerTypeSelector";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  button: {
    marginRight: theme.spacing(1), // Adjust spacing as needed
  },
  icon: {
    marginRight: theme.spacing(1), // Adjust spacing as needed
  },
  fileDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: theme.spacing(1),
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  fileDetailItem: {
    marginRight: theme.spacing(1), // Add space between paragraphs
  },
  highlightedText: {
    fontWeight: "bold", // Make text bold
    color: theme.palette.secondary.main, // Change text color to blue (you can adjust this as needed)
  },
  buttonAlign: {
    display: "flex",
    gap: "18px",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "10px",
  },
  uploadedText: {
    marginLeft: "10px",
    paddingTop: "6px",
  },
  linearProgress: {
    width: "500px",
    marginLeft: "10px",
  },
  fileError: {
    color: "red",
  },
  uploadFile: {
    display: "none",
  },
  responsieScreen: {
    [theme.breakpoints.between("xs","sm")]: {
        width: 'auto'
    },
    [theme.breakpoints.between("sm","md")]: {
        width: '800px'
    },
    [theme.breakpoints.between("md","lg")]: {
        width: '1000px'
    },
  },
}));

function formatTimestampToISO(timestampString) {
  try {
    const parsedDate = DateTime.fromJSDate(new Date(timestampString));
    const isoDate = parsedDate.toISO();
    return isoDate;
  } catch (error) {
    console.error("Error parsing date:", error.message);
    return null;
  }
}

const FirmReportUpload = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [siteEvse, setSiteEvse] = useState("2");
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [loaded, setLoaded] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(null);

  const [dateCompareError, setDateCompareError] = useState(false);
  const [timeCompareError, setTimeCompareError] = useState(false);

  const [payload, setPayload] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { isDesktop } = useIsDesktop();

  const abortController = useRef(new AbortController());

  let headCells = [
    { id: "charging_profile_id", label: "Charging Profile ID" },
    { id: "charger_id", label: "Charger ID" },
    { id: "charging_schedule_period", label: "Charging Schedule Period" },
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const getAllProfiles = async (fetchContext) => {
    try {
      const { data } = await fetchContext.authAxios.get("/get-charging-profile");
      setLoaded(true);
      setProfiles(data.data);
    } catch (err) {}
  };

  const { TblContainer, TblHead } = useTable(headCells);

  const initialFValues = {
    start_schedule: "",
  };

  const validate = async (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const fileValidate = (values) => {
    let errors = {};
    let formIsValid = true;

    if (!values?.file) {
      formIsValid = false;
      errors["file"] = "*Please upload File";
    }
    if (!values?.file?.size) {
      formIsValid = false;
      // errors["file"] = "*File size exceeds the limit of 500MB.";
      errors["file"] = "*File is Mandatory";
    }
    setFileError(errors);
    return formIsValid;
  };

  const handleSubmit = async (type) => {
    if (fileValidate(values)) {
      setUploading(true);
      let payload = {
        // charging_station_id: values?.charging_station?.id,
        manufacturer_id: values?.manufacturer_id,
        charger_type: values?.charger_type,
        firmware_version: values?.firmware_version,
        file: values?.file,
      };

      const apiUrl = process.env.REACT_APP_API_URL;
      let token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", values?.file);
      // formData.append("charging_station_id", values?.charging_station_id);
      formData.append("manufacturer_id", values?.manufacturer_id);
      formData.append("charger_type", values?.charger_type);
      formData.append("firmware_version", values?.firmware_version);
      formData.append("password", values?.password_data);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      await axios
        .create({
          baseURL: apiUrl,
          headers: {
            "Content-type": "application/json",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
          },
        })
        .post("/firmware-files", formData, { headers })
        .then((response) => {
          if (response.status === 200 && response?.data?.status) {
            setUploading(false);
            setValues({
              manufacturer_id: undefined,
              charger_type: undefined,
              firmware_version: "",
              // charging_station_id: undefined,
              file: null,
            });
            setNotify({
              isOpen: true,
              message: `File Uploded Successfully`,
              type: "success",
            });
          } else {
            setNotify({
              isOpen: true,
              message: `Something went wrong`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          setUploading(false);
          console.log(error);
        });
    }
  };

  // const handlePeriodChange = (event) => {
  //   let { name, value } = event.target;
  //   if (name === "limit") {
  //     setValues({ ...values, limit: Math.min(Math.max(parseInt(value) || 0, 0), 120) });
  //   }
  // };

  const handleSiteEVSE = (event) => {
    setSiteEvse(event.target.value);
  };

  useEffect(() => {
    setPayload({
      connector_id: values.connector_id,
      charger_id: values.charger_id ? values.charger_id.id : null,
      start_date: formatTimestampToISO(values.start_date),
      end_date: formatTimestampToISO(values.end_date),
      start_time: formatTimestampToISO(values.start_time),
      end_time: formatTimestampToISO(values.end_time),
      limit: parseInt(values.limit),
      charging_rate_unit: "A",
    });
  }, [values]);

  useEffect(() => {
    if (new Date(values.start_date) > new Date(values.end_date)) {
      setDateCompareError(true);
    } else {
      setDateCompareError(false);
    }
    if (new Date(values.start_time) > new Date(values.end_time)) {
      setTimeCompareError(true);
    } else {
      setTimeCompareError(false);
    }
  }, [values]);

  const onChange = (event) => {
    const file = event.target.files[0];
    // setSelectedFile(file);
    fileValidate({ file: event.target.files[0] });
    setValues({ ...values, file: event.target.files[0] });
  };

  const formatFileSize = (sizeInBytes) => {
    return (sizeInBytes / (1024 * 1024)).toFixed(2);
  };

  const uploadToS3 = () => {
    setUploading(true);
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_BUCKET_NAME;
    const fileName = values?.file?.name;
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: values?.file,
    };

    const managedUpload = s3.upload(params);

    managedUpload.on("httpUploadProgress", (progressData) => {
      const uploadedBytes = progressData.loaded;
      const totalBytes = progressData.total;
      const uploadPercentage = Math.round((uploadedBytes / totalBytes) * 100);
      setProgress(uploadPercentage);
    });

    managedUpload.send((err, data) => {
      if (err) {
        console.error("Error uploading file:", err);
        alert("Error uploading file");
      } else {
        alert("File uploaded successfully!");
      }
      setUploading(false);
    });
  };
  
  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Upload Firmware
            </Typography>
          </div>
        </Toolbar>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          // style={{ flexGrow: 1, minWidth: "800px" }}
          className={classes.responsieScreen}
>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <ManufacturersSelect
                label="Charger Manufacturer"
                name="manufacturer_id"
                value={values.manufacturer_id}
                error={errors.manufacturer_id}
                required={true}
                filter={true}
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <ChargerTypeSelector
                label="Charger Type"
                name="charger_type"
                value={values.charger_type}
                error={errors.charger_type}
                required={true}
                filter={true}
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} style={{paddingLeft: '8px'}}>
              <Controls.Input
                name="firmware_version"
                label="Firmware Tag"
                required={true}
                type="text"
                value={values.firmware_version}
                onChange={handleInputChange}
                style={{ width: "95%" }}
              />
            </Grid>
            {!isDesktop && <Grid item xs={4}>
              &nbsp;
            </Grid>}
            <Grid item xs={isDesktop ? 6 : 12} style={{paddingLeft: '8px'}}>
              <Controls.Input
                name="password_data"
                label="Password"
                required={true}
                type="text"
                value={values.password_data}
                onChange={handleInputChange}
                style={{ width: "95%" }}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <ChargingStationSelector
                label="Charger Manufacturer"
                name="charging_station_id"
                value={values.charging_station_id}
                error={errors.charging_station_id}
                required={true}
                filter={true}
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid> */}
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonAlign}>
                <div>
                  <input
                    // accept="image/*" // Specify accepted file types if needed (e.g., 'image/*', 'application/pdf')
                    id="file-upload"
                    type="file"
                    className={classes.uploadFile}
                    onChange={onChange}
                  />
                  <label htmlFor="file-upload">
                    <Button variant="contained" component="span" className={classes.button}>
                      <CloudUploadOutlinedIcon className={classes.icon} />
                      {"Upload File Here"}
                    </Button>
                  </label>
                  {fileError && <span className={classes.fileError}>{fileError["file"]}</span>}
                </div>
                {values?.file && (
                  <div className={classes.fileDetails}>
                    <p className={classes.fileDetailItem}>
                      <span className={classes.highlightedText}>File:</span> {values?.file?.name || ""}
                    </p>
                    <p className={classes.fileDetailItem}>
                      <span className={classes.highlightedText}>Size:</span> {formatFileSize(values?.file?.size)} MB
                    </p>
                  </div>
                )}
              </div>
              {/* selectedFile && */}
              {uploading && (
                <div>
                  <LinearProgress variant="determinate" value={uploadProgress} className={classes.linearProgress} />
                  <p className={classes.uploadedText}>{uploadProgress}% uploaded</p>
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <div>
                <Controls.Button type="submit" text="Submit" />
                <Controls.Button
                  text="Clear"
                  color="default"
                  onClick={(e) => {
                    // e.preventDefault();
                    setValues({
                      manufacturer_id: undefined,
                      charger_type: undefined,
                      firmware_version: "",
                      // charging_station_id: undefined,
                      file: null,
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Form>

        {/* <TblContainer>
          <TblHead />
          <TableBody>
            {loaded ? (
              profiles.map((item) => (
                <TableRow>
                  <TableCell>{item.charging_profile_id}</TableCell>
                  <TableCell>{item.charger_id}</TableCell>
                  <TableCell>{item.charging_schedule_period}</TableCell>
                  {!fetchContext.isView && (
                    <TableCell class="text-nowrap">
                      <Confirm
                        id={item.charging_profile_id}
                        title={"Are you sure to clear this profile?"}
                        subTitle={"You can't undo this operation"}
                        onConfirmClick={() => {
                          handleSubmit(true, {
                            charger_id: item.charger_id,
                            charging_profile_id: item.charging_profile_id,
                            connector_id: item.connector_id ? item.connector_id : 1,
                          });
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <Loader />
            )}
          </TableBody>
        </TblContainer> */}
      </Paper>
    </>
  );
};

export default FirmReportUpload;
