import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import Controls from "../../components/controls/Controls";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center"
  }
}));

const ChargerACOutputSelect = (props) => {
  const classes = useStyles();
  //const temp = null;
  const { name, label, value, loaded, options, error=null, disabled = false, onChange, required=false } = props;

    return (<>
      {loaded
          ?
      <FormControl variant="standard" required={required} className={classes.formControl}
      style={{ width: "95%" }}
      {...(error && {error:true})}>
          <InputLabel className={classes.inputLabel}>{label}</InputLabel>
          <MuiSelect
              className={classes.select}
              variant="standard"
              label={label}
              name={name}
              value={value}
              required={required}
              error={error}
              disabled={disabled}
              onChange={onChange}>

            {
              options.map( (item) => (
               ( item.type=== 'AC' ? <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem> : null)
              ))
            }  
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl> :
          <Controls.Input name={name} label={label} style={{ width: "95%" }}> </Controls.Input>
          }</>)
}

export default ChargerACOutputSelect;
