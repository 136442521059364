import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Divider from "@mui/material/Divider";
import { DateTime } from "luxon";
import { useIsDesktop } from "../../context/DisplayContext";
import SitesSelect from "./SessionBySite/SitesSelect";
import SitesGroupSelect from "./SessionBySite/SitesGroupSelect";
import ChargerGroupSelect from "./SessionBySite/ChargerGroupSelect";
import { FetchContext } from "../../context/FetchContext";

const Filter = (props) => {
  const { onFilter, oncancle, filterObject, setFilterObject } = props;
  const { isDesktop } = useIsDesktop();
  const [chargerGroupData, setChargerGroupData] = useState([]);
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);

  const initialFValues = {
    search_keyword: filterObject.search_keyword,
    is_search: true,
    export: false,
    from_date: null,
    to_date: null,
    site_id: null,
    site_group_id: null,
    charger_group_id: null,
  };

  const validate = (values) => {};

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterObject(values);
    onFilter(values);
  };

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-charger-groups");
        setChargerGroupData(data.data);
        setLoaded(true)
      } catch (err) {
        console.log("the err", err);
      }
    };
    getAllChargingStations(fetchContext);
  }, [fetchContext]);


  return (
    // <Box sx={{ flexGrow: 1 }}>
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Divider style={{ width: "100%", color: "#808080" }} />
        {!props.showSearch ? (
          <Grid item xs={isDesktop ? 3 : 12}>
            <Controls.Input name="search_keyword" label="Search" value={values.search_keyword} onChange={handleInputChange} style={{ width: "95%" }} />
          </Grid>
        ) : (
          <>
            <Grid item xs={isDesktop ? 3 : 12}>
              <SitesSelect
                //label="Sites"
                //name="site_id"
                // disabled={values?.site_group_id || values?.charger_group_id ? true : false}
                disabled={values.site_group_id && values.site_group_id !== 'None' ? true : false}
                value={values.site_id}
                error={errors.site_id}
                //required
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <SitesGroupSelect
                //label="Sites"
                //name="site_id"
                // disabled={values.site_id || values.charger_group_id ? true : false}
                // disabled={values.site_id && values.charger_group_id !== 'None' ? true : false}
                disabled={values.site_id && values.site_id !== 'None' ? true : false}
                value={values.site_group_id}
                error={errors.site_group_id}
                //required
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
            {/* {chargerGroupData?.length > 0 && <Grid item xs={isDesktop ? 4 : 12}>
              <ChargerGroupSelect
                label="Charger Group"
                name="charger_group_id"
                disabled={values.site_id || values.site_group_id ? true : false}
                value={values.charger_group_id}
                error={errors.charger_group_id}
                values={chargerGroupData}
                //required
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>} */}
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.DatePicker
                name="from_date"
                label="From Date"
                value={values.from_date}
                onChange={handleInputChange}
                error={errors.from_date}
                //fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.DatePicker
                name="to_date"
                label="To Date"
                minDate={values.from_date}
                value={values.to_date}
                // maxDate={DateTime.fromJSDate(values.from_date).plus({ months: 2 }).toJSDate()}
                onChange={handleInputChange}
                error={errors.to_date}
                //fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={isDesktop ? 3 : 12}>
          <div>
            <Controls.Button type="submit" text={!props.showSearch ? "Search" : "Filter"} size="small" />
            <Controls.Button text="Cancel" color="default" onClick={oncancle} size="small" />
          </div>
        </Grid>
      </Grid>
    </Form>
    // </Box>
  );
};

export default Filter;
