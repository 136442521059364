import { Grid, Paper, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
// import { Grid, Paper, Toolbar, Typography, makeStyles } from "@material-ui/core";
// import { DateTime } from "luxon";
import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
// import { Form, useForm } from "../../components/useForm";
import useTable from "../../components/useTable";
import SessionGraphTable from "./SessionGraphTable";
import SessionsPie from "./SessionsPie";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    // position: "absolute",
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  newButton: {
    // position: "absolute",
    // right: "10px",
    //width: '75%'
  },
  formAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const SessionGraph = () => {
  const classes = useStyles();

  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(futureDate);
  const [endDate, setEndDate] = useState(new Date());
  const [loaded, setLoaded] = useState(false);
  const [dateForTable, setDateForTable] = useState(null);
  const { isDesktop } = useIsDesktop();

  const formatDateToDDMMYYYY = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  // const headCells = [{ id: "", label: "" }];
  const headCells = [
    { id: "finished", label: "FINISHED" },
    { id: "", label: "" },
    { id: "failed", label: "FAILED" },
    { id: "", label: "" },
  ];
  const { TblContainer } = useTable(headCells);

  const [filterData, setFilterData] = useState({
    // range: true,
    graph_type: "range",
    from_date: formatDateToDDMMYYYY(startDate),
    to_date: formatDateToDDMMYYYY(endDate),
  });

  const initialFValues = {
    start_date: startDate,
    end_date: endDate,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    //if ('name' in fieldValues)
    //temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    setErrors,
    errors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStartDate(DateTime.fromJSDate(values.start_date));
    setEndDate(DateTime.fromJSDate(values.end_date));
    setFilterData({
      // range: true,
      graph_type: "range",
      from_date: formatDateToDDMMYYYY(values.start_date),
      to_date: formatDateToDDMMYYYY(values.end_date),
    });
  };

  const onClear = () => {
    setFilterData({
      graph_type: "range",
      from_date: formatDateToDDMMYYYY(futureDate),
      to_date: formatDateToDDMMYYYY(new Date()),
    });
    setValues({
      start_date: futureDate,
      end_date: new Date(),
    });
  };

  const duplicateObject = {
    FINISHED: {
      FREE: 2815,
      REMOTE_TRANSACTION: 4864,
      NAYAX: 749,
    },
    FAILED: {
      FREE: 1418,
      REMOTE_TRANSACTION: 1209,
    },
    ACTIVE: {
      FREE: 3,
      NAYAX: 1,
      REMOTE_TRANSACTION: 5,
    },
  };

  const dummyArray = [
    {
      FINISHED: [{ FREE: 2815 }, { REMOTE_TRANSACTION: 4864 }, { NAYAX: 749 }],
    },
    {
      FAILED: [{ FREE: 1418 }, { REMOTE_TRANSACTION: 1209 }],
    },
    {
      ACTIVE: [{ FREE: 3 }, { NAYAX: 1 }, { REMOTE_TRANSACTION: 5 }],
    },
  ];

  const handleCallbackData = (data) => {
    setDateForTable(data);
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <div className={classes.formAlignment}>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Session Graph
            </Typography>
          </div>
          <div className={classes.newButton}>
            <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <Controls.DatePicker
                    name="start_date"
                    label="From Date"
                    value={values.start_date}
                    onChange={handleInputChange}
                    error={errors.start_date}
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <Controls.DatePicker
                    name="end_date"
                    label="To Date"
                    value={values.end_date}
                    minDate={values.start_date}
                    onChange={handleInputChange}
                    error={errors.end_date}
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <div>
                    <Controls.Button type="submit" text="Fetch" size="small" />
                    <Controls.Button text="Clear" color="default" size="small" onClick={onClear} />
                  </div>
                </Grid>
              </Grid>
            </Form>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="row my-3">
          <div class="col-md">
            <SessionsPie graphType={null} filterData={filterData} handleCallbackData={handleCallbackData} />
          </div>
        </div>
        <br />
        <br />
        <br />
        <SessionGraphTable sessionsCountData={dateForTable} setSessionsCountData={setDateForTable} />
      </Paper>
    </>
  );
};

export default SessionGraph;
