import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import { socketFetch } from "../../services/socket";
import { socketFetchHttp } from "../../services/socketHttp";
import { useIsDesktop } from "../../context/DisplayContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  configurationContain: {
    [theme.breakpoints.between("sm", "md")]: {
      minWidth: "550px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      minWidth: "550px",
    },
  },
}));

const ChargingStationCongigurationForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const currentPath = window.location.href;
  const [configuration, setConfiguration] = useState();
  const abortController = useRef(new AbortController());
  //const [ initialFValues, setInitialFValues ] = useState();
  const initialFValues = null;
  const [resErrors, setResErrors] = useState(null);
  const [changeConfiguration, setChangeConfiguration] = useState();
  const { isDesktop } = useIsDesktop();
  const classes = useStyles();

  useEffect(() => {
    abortController.current = new AbortController();
    return () => {
      abortController.current.abort();
    };
  }, []);

  useEffect(() => {
    if (currentPath.includes("https://")) {
      socketFetch
        .get(`/getconfiguration/${recordForEdit?.ocpp_cbid}`)
        //.then(({ data }) => {
        .then((response) => {
          if (abortController.current.signal.aborted) {
            return;
          }
          if (response && response.data !== 0) {
            setConfiguration(response?.data?.configurationKey);
          } else {
            setResErrors("Not able to fetch configurations");
          }
        })
        .catch((err) => {
          if (abortController.current.signal.aborted) {
            return;
          }
          if (typeof err == "object") {
            let errMessage = err.message;
            setResErrors(errMessage);
          } else {
            setResErrors("Unknown error: ", err);
          }
        });
    } else {
      socketFetchHttp
        .get(`/getconfiguration/${recordForEdit?.ocpp_cbid}`)
        //.then(({ data }) => {
        .then((response) => {
          if (abortController.current.signal.aborted) {
            return;
          }
          if (response && response.data !== 0) {
            setConfiguration(response?.data?.configurationKey);
          } else {
            setResErrors("Not able to fetch configurations");
          }
        })
        .catch((err) => {
          if (abortController.current.signal.aborted) {
            return;
          }
          if (typeof err == "object") {
            let errMessage = err?.message;
            setResErrors(errMessage);
          } else {
            setResErrors("Unknown error: ", err);
          }
        });
    }
  }, [recordForEdit, currentPath]);

  const getErrorView = () => {
    return (
      <div style={{ fontSize: 17 }}>
        There is some issue, please try again: <strong>{resErrors}</strong>
        {/* <button onClick={() => getConfigurations(recordForEdit.ocpp_cbid)}>
          Try again
        </button> */}
      </div>
    );
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ('name' in fieldValues)
    //     temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    //setValues,
    errors,
    setErrors,
    //handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    //if (validate()) {
    addOrEdit(recordForEdit.ocpp_cbid, changeConfiguration, resetForm);
    //}
    //submitConfigurations(configuration, recordForEdit.ocpp_cbid);
  };

  const handleChange = (event, index, readOnly, key) => {
    const { name, value } = event.target;
    const list = [...configuration];
    list[index][name] = value;
    setConfiguration(list);
    setChangeConfiguration({ key: key, readonly: readOnly, value: value });
  };
  return (
    <>
      {/* {configuration ? <div>Success</div> : <div>No Success</div>} */}
      <Form onSubmit={handleSubmit}>
        <Grid container fullWidth className={classes.configurationContain}>
          {!resErrors ? (
            configuration ? (
              configuration.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <Controls.Input
                    label={item.key}
                    name="value"
                    disabled={item.readonly === true || item.readonly === "true" ? true : false}
                    value={item.value}
                    onChange={(e) => handleChange(e, index, item.readonly, item.key)}
                    //error={errors.item.key}
                    fullWidth
                    style={{ width: "95%" }}
                  />
                </Grid>
              ))
            ) : (
              <div>
                <i class="fas fa-spinner fa-spin"></i>
                Results are loading...
              </div>
            )
          ) : (
            getErrorView()
          )}
          {configuration && (
            <Grid item xs={12}>
              <div>
                <Controls.Button type="submit" text="Submit" />
                <Controls.Button text="Cancel" color="default" onClick={closeForm} />
              </div>
            </Grid>
          )}
        </Grid>
      </Form>
    </>
  );
};

export default ChargingStationCongigurationForm;
