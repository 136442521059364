import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import useTable from "../../components/useTable";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
// import Popup from "../../components/Popup";
// import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../components/Loader";
import ExportData from "./ExportData";
import ExportDataBySitesNext from "./ExportDataBySitesNext";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Paper, TableBody, TableCell, Toolbar, Typography, Tooltip, TableRow } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
//import { ExpandTable } from '../../components/controls/ExpandTable';
import { Link, makeStyles } from "@material-ui/core";
//import MonthRangeSelect  from './MonthRangeSelect';
import { ArrowBack } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
//import { DateTime } from 'luxon';
import { InfoOutlined } from "@material-ui/icons";
import { useParams, useHistory } from "react-router-dom";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
//import SessionsPopUp from './SessionsPopUp';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    //width: '75%'
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  exportIcon: {},
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  headerContainer: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
}));

const SessionBySitesNext = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const history = useHistory();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  //const dateStr = DateTime.now();
  //const selectedMonth = dateStr.toFormat(`01-${'MM-yyyy'}`);
  const [page, setPage] = useState(1);
  //const [byMonth, setByMonth] = useState(selectedMonth);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  //const [openPopup, setOpenPopup] = useState(false)
  //const [transactionId, setTransactionId] = useState(0)
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const { id } = useParams();
  const { bymonth } = useParams();
  const [totalRecord, setTotalRecord] = useState(0);

  let splitted_param = window?.location?.pathname.split("/")[1];

  const headCells = [
    { id: "", label: "" },
    { id: "site_name", label: "Site Name" },
    { id: "charging_station", label: "Charging Station" },
    { id: "energy", label: "Energy (kWH)" },
    { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "", label: "" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, siteId, byMonth, filterDate, isFilter) => {
    // {
    // "site_id":siteId,
    // "date":byMonth,
    // 'export':false
    // }

    let finalPayload = isFilter
      ? {
          site_id: siteId,
          date: null,
          export: false,
          range: isFilter,
          to_date: filterDate?.to_date,
          from_date: filterDate?.from_date,
        }
      : {
          site_id: siteId,
          date: byMonth,
          export: false,
          range: isFilter,
        };

    try {
      const { data } = await fetchContext.authAxios.post(`/get-report-group-by-charging-station?page=${page}`, finalPayload);
      //let res = Object.values(data.data);
      setRecords(data.data.data);
      setTotalRecord(data.data.total);
      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    if (bymonth.includes("to")) {
      // let datePattern = /\d{2}-\d{2}-\d{4}/g;
      let filterString = bymonth.split("to");
      if (filterString?.length === 2) {
        setToDate(filterString[1]);
        setFromDate(filterString[0]);
        let dates = {
          to_date: filterString[1],
          from_date: filterString[0],
        };
        setIsFilter(true);
        getAll(fetchContext, page, id, bymonth, dates, true);
      }
    } else {
      setIsFilter(false);
      getAll(fetchContext, page, id, bymonth, null, false);
    }
  }, [fetchContext, page, id, bymonth]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  // const openInPopup = item => {
  //     setTransactionId(item)
  //     setOpenPopup(true)
  // }
  // const changeSelectOptionHandler = (event) => {
  //   let report_month = event.target.value;
  //   setLoaded(false);
  //   setByMonth(report_month);
  //   //setSelected(event.target.value);
  // };
  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Sessions by Site
            </Typography>
          </div>
          <div className={classes.filterIcon}>
            <Tooltip title="Browse Back">
              <ArrowBack onClick={() => history.goBack()} />
            </Tooltip>
          </div>
          <div className={classes.exportIcon}>
            <ExportDataBySitesNext siteId={id} byMonth={bymonth} filterDate={{ to_date: toDate, from_date: fromDate, range: isFilter }} />
          </div>
        </Toolbar> */}
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>{fetchContext?.isAdmin  ? 'Sessions by Site' : 'Charger Usage Report'}</div>
          <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              <div className={classes.filterIcon}>
                <Tooltip title="Browse Back">
                  <ArrowBack onClick={() => history.goBack()} />
                </Tooltip>
              </div>
              <div className={classes.exportIcon}>
                <ExportDataBySitesNext siteId={id} byMonth={bymonth} filterDate={{ to_date: toDate, from_date: fromDate, range: isFilter }} />
              </div>
            </div>
          </div>
        </div>
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item, index) => (
                <TableRow key={index}>
                  <TableCell />
                  <TableCell>{item.site_name}</TableCell>
                  <TableCell>{item.charging_station_name}</TableCell>
                  <TableCell>{Number(item.energy).toFixed(2)}</TableCell>
                  <TableCell>{item.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : "-"}</TableCell>
                  <TableCell>{item.total}</TableCell>
                  <TableCell>
                    <Tooltip title="View More">
                      <Link
                        component={RouterLink}
                        to={`/sites-details/${item.site_id}/${item.charging_station_id}/${toDate && fromDate ? `${fromDate}to${toDate}` : bymonth}`}
                      >
                        <InfoOutlined />
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <ExportData
                      siteId={item.site_id}
                      csId={item.charging_station_id}
                      byMonth={bymonth}
                      isFilter={isFilter}
                      filterDate={{ to_date: toDate, from_date: fromDate }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      {/* <Popup
                title="Meter Values"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <SessionsPopUp transaction_id={transactionId} />
            </Popup> */}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default SessionBySitesNext;
