//import type { ChargingStation, Connector } from '@evrange/api-spec';
import { Paper, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
//import { PlaceSharp } from '@material-ui/icons';
//import GoogleMapReact from 'google-map-react';
//import { curveCardinal } from 'd3-shape';
import { DateTime, Interval } from 'luxon';
import { useHistory } from 'react-router-dom';
//import { Area, AreaChart } from 'recharts';
//import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
//import DashboardChargingStationStatuses from './DashboardChargingStationStatuses';
//import InfoWindow from '../component/InfoWindow';
import ShowMarkers from './Marker';
//import footerBg from '../../assets/images/footerbg.jpg';
//import dashBg from '../../assets/images/dashbg.jpg';
import { ChStStatusContext } from '../../App/App';
import dash1 from '../../assets/images/boxicon1.png';
import dash2 from '../../assets/images/boxicon2.png';
import dash3 from '../../assets/images/boxicon3.png';
import dash4 from '../../assets/images/boxicon4.png';
import footerLogo from '../../assets/images/logo.png';
import { FetchContext } from '../../context/FetchContext';
import Displacement from './Displacement';
import Energy from './Energy';
import Revenue from './Revenue';
import SessionsPie from './SessionsPie';
//import upArrow from '../../assets/images/uparrow.png';
//import downArrow from '../../assets/images/downarrow.png';

const convertChartData = (inputData) => {
  const result = [];
  const categories = new Set();

  for (const status in inputData) {
    const statusData = inputData[status];

    if (status.toUpperCase() === "ACTIVE") {
      continue;
    }

    for (const category in statusData) {
      categories.add(category);
    }
  }

  const categoryArray = Array.from(categories);

  categoryArray.forEach((category) => {
    const newData = { name: category };

    for (let status in inputData) {
      if (status.toUpperCase() === "ACTIVE") {
        continue;
      }

      const statusData = inputData[status];
      if (status === "FINISHED") {
        status = "Finished";
      }
      if (status === "FAILED") {
        status = "Failed";
      }
      newData[status] = statusData[category] || 0;
    }

    result.push(newData);
  });

  return result;
}

const Dashboard = () => {
  let reloadInterval = 5000;
  const fetchContext = useContext(FetchContext);
  const { flState, setFlState } = useContext(ChStStatusContext);
  const classes = useStyles();
  const history = useHistory();
  const [transactions, setTransactions] = useState([])
  const [result, setChargingStations] = useState([]);
  const [dashboardCount, setDashboardCount] = useState([]);
  //const [sessionsGraphData, setSessionsGraphData] = useState([]);
  const daysRange = [];
  const start = DateTime.now().minus({ days: 30 });
  const end = DateTime.now();
  //const end = DateTime.now().minus({ days: 25 });
  const interval = Interval.fromDateTimes(start, end);
  const [mapKey, setMapKey] = useState('');

  function* days() {
    let cursor = interval.start.startOf('day');
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }

  for (const d of days(interval)) {
    daysRange.push(d.day);
  }

  const getKey = async (fetchContext) => {
    try {
      const { data } = await fetchContext.authAxios.get("/get-key");
      setMapKey(data.data);
    } catch (err) {
      console.log("the err", err);
      //return '';
    }
  };


  const getAllTransactions = async (fetchContext) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        fetchContext?.isAdmin ? 'get-energy-revenue-for-admin' : '/get-energy-revenue', { range: true, to_date: end.toFormat("dd-MM-yyyy"), from_date: start.toFormat("dd-MM-yyyy") }
      );
      setTransactions(data.data);
    } catch (err) {
      console.log('the err', err);
    }
  };

  useEffect(() => {
    if (flState === undefined) {
      setFlState('fsn');
    }
    getAllTransactions(fetchContext);

  }, [fetchContext, flState, setFlState]);

  // const getAllChargingStations = async (fetchContext) => {
  //   try {
  //     const { data } = await fetchContext.authAxios.get(
  //       '/charging-station-status'
  //     );
  //     setChargingStations(data.data);
  //   } catch (err) {
  //   }
  // };

  useEffect(() => {
    let connectorTimeoutId;
    //let isMounted = true;
    const abortCtrl = new AbortController();
    const abortCtrlOpts = { signal: abortCtrl.signal };

    const getStatus = () => {
      fetchContext.authAxios
        .get(`/get-charging-station-count`, abortCtrlOpts)
        .then(({ data }) => {
          if (abortCtrl.signal.aborted) {
            return;
          }

          setDashboardCount(data);

          if (reloadInterval) {
            connectorTimeoutId = setTimeout(() => getStatus(), reloadInterval);
          }
        })
        .catch((err) => {
          console.log("errrr",err);
          if (abortCtrl.signal.aborted) {
            return;
          }
      // history.push("/");

          console.error(err);
        });
    };

    getStatus();

    return () => {
      //clearTimeout(connectorTimeoutId);
      //isMounted = false;
      abortCtrl.abort();
      if (connectorTimeoutId) {
        clearTimeout(connectorTimeoutId);
      }
    };
  }, [fetchContext.authAxios, reloadInterval]);

  const getStatus = () => {
    fetchContext.authAxios
      .get(`/charging-station-status`)
      .then(({ data }) => {

        setChargingStations(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    // getAllChargingStations(fetchContext);
    getStatus()
    getKey(fetchContext);
  }, [fetchContext]);

  const [newData,  setNewData] = useState({
     total : 0,
     Occupied : 0,
     Available : 0,
     Unavailable : 0,
     Faulted : 0
  });

  useEffect(() => {
    let totalSum = 0;
    let occupiedCount = 0;
    let availableCount = 0;
    let unavailableCount = 0;
    let faultedCount = 0;

    dashboardCount?.forEach(item => {
      totalSum += item.total;

      switch (item.charger_status) {
        case 'OCCUPIED':
          occupiedCount += item.total;
          break;
        case 'AVAILABLE':
          availableCount += item.total;
          break;
        case 'UNAVAILABLE':
          unavailableCount += item.total;
          break;
        case 'FAULTED':
          faultedCount += item.total;
          break;
        default:
          break;
      }
    });

    // Update newData state with calculated values
    setNewData({
      total: totalSum,
      Occupied: occupiedCount,
      Available: availableCount,
      Unavailable: unavailableCount,
      Faulted: faultedCount
    });
  }, [dashboardCount]);



  // if (result !== undefined) {
  //   for (let i = 0; i < result.length; i++) {
  //     if (result[i] !== undefined && result[i].connectors.length > 0) {
  //       if (result[i].connectors.some((x) => x.status === 'FAULTED')) {
  //         Faulted = Faulted + 1;
  //       }
  //       if ((result[i].connectors.every((x) => (x.status !== 'CHARGING' && x.status !== 'PREPARING' && x.status !== 'FINISHING' && x.status !== 'FAULTED'))) && (result[i].connectors.some((x) => (x.status === 'AVAILABLE')))) {
  //         Available = Available + 1;
  //       }
  //       if (result[i].connectors.every((x) => x.status === 'UNAVAILABLE')) {
  //         Unavailable = Unavailable + 1;
  //       }
  //       if ((result[i].connectors.some((x) => (x.status === 'CHARGING' || x.status === 'PREPARING' || x.status === 'FINISHING' || x.status === 'SUSPENDEDEV' || x.status === 'SUSPENDEDEVSE'))) && (result[i].connectors.every((x) => (x.status !== 'FAULTED')))) {
  //         Occupied = Occupied + 1;
  //       }
  //     }
  //   }
  // }

  const yearDataChart = [
    { name: 'REMOTE_TRANSACTION', Finished: 432, Failed: 172 },
    { name: 'NAYAX', Finished: 355, Failed: 0 },
    { name: 'FREE', Finished: 254, Failed: 1 },
    { name: 'RFID', Finished: 123, Failed: 23 },
  ];


  const monthDataChart = [
    { name: 'REMOTE_TRANSACTION', Finished: 0, Failed: 0 },
    { name: 'NAYAX', Finished: 0, Failed: 0 },
    { name: 'FREE', Finished: 0, Failed: 0 },
    { name: 'RFID', Finished: 0, Failed: 0 },
  ];


  const todayDataChart = [
    { name: 'REMOTE_TRANSACTION', Finished: 0, Failed: 0 },
    { name: 'NAYAX', Finished: 0, Failed: 0 },
    { name: 'FREE', Finished: 0, Failed: 0 },
    { name: 'RFID', Finished: 0, Failed: 0 },
  ];

  const goToChSt = (fStatus) => {
    setFlState(fStatus);
    history.push(`/charging-stations`);
  };

  const handleCallbackData = () => { }

  return (
    <>
      <Paper className={classes.dashbg} >
        <div class="container-fluid">
          <div class="row my-3"><div class="col-md-12">&nbsp;</div></div>
          <div class="row my-3">

            <div class="col-md-3">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <div class="row align-items-center" onClick={() => goToChSt('AVAILABLE')} style={{ cursor: 'pointer' }}>
                    <div class="col-md-12">
                      <div class="boxhead">Available / Chargers</div>
                    </div>
                    <div class="col-md-8">
                      <div class="clearfix"></div>
                      <div class="countnumber">{newData?.Available} / {newData?.total}</div>
                    </div>
                    <div class="col-md-4" >
                      <img src={dash2} className='boxicon' alt="Total Chargers" />
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <div class="row align-items-center" onClick={() => goToChSt('OCCUPIED')} style={{ cursor: 'pointer' }}>
                    <div class="col-md-12">
                      <div class="boxhead">Occupied</div>
                    </div>
                    <div class="col-md-8">
                      <div class="clearfix"></div>
                      <div class="countnumber">{newData?.Occupied}</div>
                    </div>
                    <div class="col-md-4" >
                      <img src={dash3} className='boxicon' alt="Occupied Chargers" />
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <div class="row align-items-center" onClick={() => goToChSt('UNAVAILABLE')} style={{ cursor: 'pointer' }}>
                    <div class="col-md-12">
                      <div class="boxhead">Unavailable</div>
                    </div>
                    <div class="col-md-8">
                      <div class="clearfix"></div>
                      <div class="countnumber">{newData?.Unavailable}</div>
                    </div>
                    <div class="col-md-4" >
                      <img src={dash4} className='boxicon' alt="Unavailable Chargers" />
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <div class="row align-items-center" onClick={() => goToChSt('FAULTED')} style={{ cursor: 'pointer' }}>
                    <div class="col-md-12">
                      <div class="boxhead">Faulty</div>
                    </div>
                    <div class="col-md-8">
                      <div class="clearfix"></div>
                      <div class="countnumber">{newData?.Faulted}</div>
                    </div>
                    <div class="col-md-4"  >
                      <img src={dash1} className='boxicon' alt="Faulty Chargers" />
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row my-3">
            <div class="col-md-6">
              <Energy transactions={transactions} start={start} end={end} />
            </div>
            <div class="col-md-6">
              <Revenue transactions={transactions} start={start} end={end} />
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-4">
              <SessionsPie dataChart={yearDataChart} transactions={transactions} graphType={'MONTHLY'} handleCallbackData={handleCallbackData} />
            </div>
            <div class="col-md-4">
              <SessionsPie dataChart={monthDataChart} transactions={transactions} graphType={'WEEKLY'} handleCallbackData={handleCallbackData} />
            </div>
            <div class="col-md-4">
              <SessionsPie dataChart={todayDataChart} transactions={transactions} graphType={'TODAY'} handleCallbackData={handleCallbackData} />
            </div>
          </div>

          <Displacement />

          <div class="row my-3">
            <div class="col-md-12">
              <div class="card shadow mb-4" style={{ borderRadius: '10px' }}>
                <div class="card-body">
                  <div class="row ">
                    <div class="col-md-12">
                      <div style={{ height: '330px', width: '100%', border: '0px solid #000', position: 'relative' }}>
                        {result && mapKey &&
                          <ShowMarkers chargingStations={result} mapKey={mapKey} />
                        }
                      </div>
                    </div>
                    {/* <div class="col-md-3">                  
                    <table class="tablelocation">
                    <thead>
                      <tr>
                        <th width="80%">Location</th>
                        <th width="20%">Chargers</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      <tr>
                        <td>Wisconsin</td>
                        <td>258</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>                   */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row" style={{ paddingBottom: "15px" }}>
            <div class="col-md-1"><img src={footerLogo} alt="Logo" className={classes.logo} /></div>
            <div class="col-md-11" style={{ display: "table", textAlign: "center" }}><span style={{ display: "table-cell", verticalAlign: "middle" }}>© {new Date().getFullYear()} RED E EV Charging. All Rights Reserved.</span></div>
          </div>
        </div>

        {/* <div className={classes.footerbg}>
          © 2022 RED E EV Charging. All Rights Reserved. 
      </div> */}
      </Paper>
    </>
  );
};

export default Dashboard;

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2), // Prevent the FAB overlapping pagination elements
    marginBottom: theme.spacing(1), // Prevent the FAB overlapping pagination elements
    marginLeft: theme.spacing(2),
    //width: '99%',
    //paddingleft: theme.spacing(2), // Prevent the FAB overlapping pagination elements
  },
  txtOrange: {
    color: '#faa51a',
    fontWeight: 700,
  },
  txtBlue: {
    color: '#a70f11',
    fontWeight: 500,
  },
  tableActions: {
    paddingRight: 0,
    whiteSpace: 'nowrap',
    width: 1,
    '& > button': {
      marginRight: theme.spacing(2),
    },
  },
  logo: {
    display: 'block',
    margin: `${theme.spacing(1)}px auto`,
    maxWidth: 100,
  },
  dashbg: {
    // backgroundImage: `url(${dashBg})`,
    // backgroundRepeat: "repeat-x",
    // backgroundPosition: "top",
    backgroundColor: '#ffffff',
    //borderWidth: 5,
    //borderRadius: 5,
    marginTop: 1,
    paddingTop: 5,
    //boxShadow: "20px 0 0 0 #000",
    //height: '100vh',
  },
  // status1: { 
  //   backgroundImage: `url(${dash1})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "left top",
  // },
  // status2: { 
  //   backgroundImage: `url(${dash2})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "left top",
  // },
  // status3: { 
  //   backgroundImage: `url(${dash3})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "left top",
  // },
  // status4: { 
  //   backgroundImage: `url(${dash4})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "left top",
  // },
  footerbg: {
    textAlign: 'right',
    height: '224px',
    lineHeight: '200px',
    color: '#fff',
    fontSize: '13px',
    width: '100%',
    // [theme.breakpoints.down('lg')]: {
    //   backgroundColor: '#d90f0f',
    //   textAlign: 'center',
    //   padding: '30px 0',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   backgroundImage: `url(${footerBg})`,      
    //   paddingRight:'200px',
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "left top",
    // },

  },
}));

