import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core";

import { Grid } from "@mui/material";

export const useForm = (initialFValues, validateOnChange = false, validate) => {
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }
    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }
    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    }
}
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1),
            [theme.breakpoints.between("xs", "sm")]: {
                minWidth: '95%%',
                margin: theme.spacing(1),
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: '80%',
                margin: theme.spacing(1),
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: '80%',
                margin: theme.spacing(1),
              },
        },
        '& .MuiFormControl-fullWidth':{
            [theme.breakpoints.between("xs", "sm")]: {
                width: '96%',
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: '100%',
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: '100%',
              },
        },
        '& .MuiInputBase-root': {
            lineHeight: '1.2976em',
        },
        '& label.Mui-focused': {
            color: '#000',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#000',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#000',
            },
            '&:hover fieldset': {
                borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#000',
            },
        },
    },
    grid: {
        background: "#fffff",
        
        
    },
    loginGrid: { background: "#fffff" },
}))
export const Form = (props) => {
    const classes = useStyles();
    const { children, loginScreen = false, ...other } = props;
    return (
            <Grid 
                container
                spacing={0}
                className={loginScreen ? classes.loginGrid : classes.grid}
                
                
            >
                <form className={classes.root} autoComplete="off" {...other}>
                    {props.children}
                </form>
            </Grid>
    )
}

