import React, { useContext } from "react";
import { DateTime } from "luxon";
import CsvDownloader from "react-csv-downloader";
import { FetchContext } from "../../context/FetchContext";
import { ImportExport } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import { formatMaxPower } from "../../components/FormatMaxPower";

const ExportData = ({ siteId, csId, byMonth, isFilter, filterDate }) => {
  const fetchContext = useContext(FetchContext);
  let splitted_param = window?.location?.pathname.split("/")[1];

function fetchConvertedDate(dateValue, timezone) {
  const dt = DateTime.fromISO(dateValue.replace(' ', 'T'), { zone: 'UTC' });

  const dtConverted = dt.setZone(timezone);

  // Return date in short format and time in 24-hour format with seconds
  return dtConverted.toLocaleString(DateTime.DATE_SHORT) + ' ' + dtConverted.toFormat('HH:mm:ss');
}


  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const asyncFnComputeData = async () => {
    const dataArr = [];
    const finalPayload = isFilter
      ? {
          site_id: siteId,
          charging_station_id: csId,
          date: byMonth,
          export: true,
          range: isFilter,
          to_date: filterDate?.to_date,
          from_date: filterDate?.from_date,
        }
      : {
          site_id: siteId,
          charging_station_id: csId,
          date: byMonth,
          range: isFilter,
          export: true,
        };
    await fetchContext.authAxios.post(`/get-report-by-site-id-charging-station-id`, finalPayload).then(({ data }) => {
      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {
          //dataObj.sessionID = item.id;
          let obj = {
            sessionID: item.id,
            chargingStation: item.name,
            site: item.site_name,
            connectorID: item.sequence_number,
            type: `${item.type} ${item.type === "RFID" ? (item?.rfid_tag ? `(${item?.rfid_tag?.name})` : "" ): (item.type === "REMOTE_TRANSACTION" ? (item?.ocpi_session ? `(${item?.ocpi_session?.party_name})` :`(${item.start_type})`) : "")}`,
            status: item.status,
            energyDelivered: item.meter_end !== null && item.meter_start !== null ? ((item.meter_end - item.meter_start) / 1000).toFixed(2) : "",
            startedAt: item.session_start ? fetchConvertedDate(item.session_start, item.timezone) : "",
            endedAt: item.session_end ? fetchConvertedDate(item.session_end, item.timezone) : "",
            duration: item.session_end && item.session_start ? DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat("hh:mm:ss") : "",
            pluggedIn: item.plug_in ? fetchConvertedDate(item.plug_in, item.timezone) : "",
            pluggedOut: item.plug_out ? fetchConvertedDate(item.plug_out, item.timezone) : "",
            // pluggedDuration: item.plug_in && item.plug_out ? DateTime.fromSQL(item.plug_out).diff(DateTime.fromSQL(item.plug_in)).toFormat('hh:mm:ss') : '',
            startSOC: item.soc_start,
            endSOC: item.soc_end,
            //paymentReference: '',
            maxPower:  formatMaxPower(item?.max_power, item?.manufacturer_id),
            amount: item?.amount ? item.amount : 0,
            total: item.total,
            address: item.address?.replace(/,/g, ''),
            city: item.city,
            state: item.state,
            postal_code: item.postal_code,
            latitude: item.latitude,
            longitude: item.longitude,
            parking_amount: item?.parking_amount ? formatNumber(item?.parking_amount) : 0,
            tax_amount: item?.tax_amount ? formatNumber(item?.tax_amount) : 0,
            net_amount: formatNumber(item?.amount - item?.parking_amount - item?.tax_amount),
            // averagePower: item.average_power,
            // maxVoltage: item.max_voltage,
            // averageVoltage: item.average_voltage,
            // petroleumDisplacement: item.meter_end !== null && item.meter_start !== null ? (((item.meter_end - item.meter_start) / 1000) / 6).toFixed(2) : '',
            // greenhouseGas: item.meter_end !== null && item.meter_start !== null ? (((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2) : '',
          };
          dataArr.push(obj);
        }
      }
    });
    return Promise.resolve(dataArr);
  };

  const columns =
    [
          { id: "sessionID", displayName: "Session ID" },
          { id: "chargingStation", displayName: "Charging Station" },
          { id: "site", displayName: "Site Name" },
          { id: "connectorID", displayName: "Connector ID" },
          { id: "type", displayName: "Type" },
          { id: "status", displayName: "Status" },
          { id: "energyDelivered", displayName: "Energy Delivered (KWH)" },
          { id: "startedAt", displayName: "Started At" },
          { id: "endedAt", displayName: "Ended At" },
          { id: "duration", displayName: "Duration" },
          { id: "pluggedIn", displayName: "Plugged In" },
          { id: "pluggedOut", displayName: "Plugged Out" },
          // { 'id': 'pluggedDuration', 'displayName': 'Plugged Duration' },
          { id: "startSOC", displayName: "Start SOC" },
          { id: "endSOC", displayName: "End SOC" },
          //{ 'id': 'paymentReference', 'displayName': 'Payment Reference' },
          { id: "maxPower", displayName: "Max Power" },
          // { 'id': 'averagePower', 'displayName': 'Average Power' },
          // { 'id': 'maxVoltage', 'displayName': 'Max Voltage' },
          // { 'id': 'averageVoltage', 'displayName': 'Average Voltage' },
          // { 'id': 'petroleumDisplacement', 'displayName': 'Petroleum Displacement (US gal)' },
          // { 'id': 'greenhouseGas', 'displayName': 'Greenhouse Gas (kg)' },
          { id: "amount", displayName: "Amount (USD)" },
          { id: "address", displayName: "Address" },
          { id: "city", displayName: "City" },
          { id: "state", displayName: "State" },
          { id: "postal_code", displayName: "Zipcode" },
          { id: "latitude", displayName: "Latitude" },
          { id: "longitude", displayName: "Longitude" },
        ];

  const revenueFileName = () => {
    if (isFilter) {
      const from_date = filterDate?.from_date ? filterDate?.from_date : "";
      const from_date_parsedDate = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      const from_date_formattedDate = from_date_parsedDate.toFormat("d LLL yyyy");

      const to_date = filterDate?.to_date ? filterDate?.to_date : "";
      const to_date_parsedDate = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      const to_date_formattedDate = to_date_parsedDate.toFormat("d LLL yyyy");


      if (from_date_formattedDate !== "Invalid DateTime" && to_date_formattedDate !== "Invalid DateTime") {
        return `RevenueReport (${from_date_formattedDate} to ${to_date_formattedDate})`;
      } else {
        return `RevenueRReport`;
      }
    } else {
      const date = filterDate?.date ? filterDate?.date : byMonth ? byMonth : "";
      const date_parsedDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      const date_formattedDate = date_parsedDate.toFormat("LLL yyyy");
      if (date_formattedDate !== "Invalid DateTime") {
        return `RevenueReport (${date_formattedDate})`;
      } else {
        return `RevenueReport`;
      }
    }
  };

  return (
    <>
      <CsvDownloader
        filename="SiteDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          <ImportExport />
        </Tooltip>
      </CsvDownloader>
    </>
  );
};

export default ExportData;
