import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import { Grid, Typography } from "@material-ui/core";
import { useForm, Form } from "../../components/useForm";
import { useIsDesktop } from "../../context/DisplayContext";

export default function GetDiagnosticsDateSelection({ closeForm, onDiagnostics }) {
  const { isDesktop } = useIsDesktop();

  const initialFValues = {
    from_date: null,
    to_date: null,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("from_date" in fieldValues) temp.from_date = fieldValues.from_date ? "rrrrrrr" : "From Date is Require";

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validate()) {
      onDiagnostics(values);
    // }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.DatePicker
              name="from_date"
              label="From Date"
              required
              value={values.from_date}
              onChange={handleInputChange}
              error={errors.from_date}
              //fullWidth
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.DatePicker
              name="to_date"
              required
              label="To Date"
              minDate={values.from_date}
              // maxDate={DateTime.fromJSDate(values.from_date).plus({ months: 6 }).toJSDate()}
              value={values.to_date}
              onChange={handleInputChange}
              error={errors.to_date}
              //fullWidth
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Submit" />
              <Controls.Button text="Cancel" color="default" onClick={closeForm} />
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
