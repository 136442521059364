import React from "react";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Divider from "@mui/material/Divider";
import { useIsDesktop } from "../../context/DisplayContext";
const Filter = (props) => {
  const { onFilter, oncancle, filterObject, setFilterObject } = props;
  const { isDesktop } = useIsDesktop();
  const initialFValues = {
    search_keyword: filterObject.search_keyword,
    is_search: true,
    export: false,
  };
  const validate = (values) => {};
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterObject(values);
    onFilter({ search_keyword: values?.search_keyword });
  };
  return (
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Divider style={{ width: "100%", color: "#808080" }} />
        <Grid item xs={isDesktop ? 3 :12}>
          <Controls.Input name="search_keyword" label="Search" value={values.search_keyword} onChange={handleInputChange} style={{ width: "95%" }} />
        </Grid>
        <Grid item xs={isDesktop ? 3 :12}>
          <div>
            <Controls.Button type="submit" text={!props.showSearch ? "Search" : "Filter"} size="small" />
            <Controls.Button text="Cancel" color="default" onClick={oncancle} size="small" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};
export default Filter;
