import React, { useState } from "react";
//import { Grid } from '@material-ui/core';
//import { Box, Grid } from "@mui/material";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Divider from "@mui/material/Divider";
import SiteSelect from "./SiteSelect";
import ChargingStationSelect from "./ChargingStationSelect";
import AuthTypeSelect from "./AuthTypeSelect";
import { useIsDesktop } from "../../context/DisplayContext";

const Filter = (props) => {
  const { onFilter, oncancle, filterObject, setFilterObject, showSearch } = props;
  const [dropdownValues, setDropdownValues] = useState([]);
  const [dropdownChargingValues, setDropdownChargingValues] = useState([]);
  const { isDesktop } = useIsDesktop();
  const initialFValues = {
    name: filterObject.name,
    authentication_tag: filterObject.authentication_tag,
    authentication_type: filterObject.authentication_type,
    site: filterObject.site,
    charging_station: filterObject.charging_station,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      onFilter(values);
    }
  };

  return (
    // <Box sx={{ flexGrow: 1 }}>
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Divider style={{ width: "100%", color: "#808080" }} />
        {props.showSearch ? (
          <>
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.Input
                name="name"
                label="Name"
                //required
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                //fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <AuthTypeSelect
                label="Authentication Type"
                name="authentication_type"
                value={values.authentication_type}
                error={errors.type}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.Input
                name="authentication_tag"
                label={"Authentication Tag"}
                value={values.authentication_tag}
                onChange={handleInputChange}
                error={errors.token}
                fullWidth
                style={{ width: "95%" }}
              />
            </Grid>

            <Grid item xs={isDesktop ? 3 : 12}>
              <SiteSelect
                //label="Sites"
                //name="site_id"
                value={values?.site?.id}
                error={errors.site}
                disabled={false}
                style={{ width: "95%" }}
                onChange={handleInputChange}
                setDropdownValues={setDropdownValues}
                dropdownValues={dropdownValues}
                isMultiple={false}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <ChargingStationSelect
                //name="charging_station_id"
                //label="Charging Station"
                value={values.charging_station}
                error={errors.charging_station}
                //required
                disabled={false}
                onChange={handleInputChange}
                setDropdownChargingValues={setDropdownChargingValues}
                dropdownChargingValues={dropdownChargingValues}
                style={{ width: "95%" }}
                isMultiple={false}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={isDesktop ? 3 : 12}>
            <Controls.Input name="search_keyword" label="Search" value={values.search_keyword} onChange={handleInputChange} style={{ width: "95%" }} />
          </Grid>
        )}
        <Grid item xs={isDesktop ? 3 : 12}>
          <div>
            <Controls.Button type="submit" text={!props.showSearch ? "Search" : "Filter"} size="small" />
            <Controls.Button text="Cancel" color="default" size="small" onClick={oncancle} />
          </div>
        </Grid>
      </Grid>
    </Form>
    // </Box>
  );
};

export default Filter;
