import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  expanded: {
    padding: 1,
  },
  root: {
    '& > td': {
      '&:first-of-type': {
        paddingRight: 0,
        width: '1px',
      },
    },
  },
  
  
  
});


export const ExpandTable = ({ children, expandedContent, onCollapse, onExpand, sx }) => {

  const [ expanded, setExpanded ] = useState(false);
  const styles = useStyles();
  const onClick = useCallback(() => {
    setExpanded((current) => !current);
  }, []);

  const colSpan = useMemo(() => children.length + 1, [ children ]);

  useEffect(() => {
    if (expanded) {
      if (onExpand) {
        onExpand();
      }
    } else {
      if (onCollapse) {
        onCollapse();
      }
    }
  }, [ expanded, onCollapse, onExpand ]);

  const TransitionComponent = useMemo(() => {
    const TransitionComponent = forwardRef((props, ref) => (
      <TableRow>
        <TableCell colSpan={colSpan} sx={styles.expanded}>
          <div {...props} ref={ref} />
        </TableCell>
      </TableRow>
    ));
    TransitionComponent.displayName = 'ForwardRef<TableRow>';
    return TransitionComponent;
  }, [ colSpan, styles ]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton color="primary" onClick={onClick}>
            {expanded ? <KeyboardArrowUp style={{fill: '#a31112'}} /> : <KeyboardArrowDown style={{fill: '#a31112'}} />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      <Collapse component={TransitionComponent} in={expanded} unmountOnExit>
        {expandedContent}
      </Collapse>
    </>
  );

};
