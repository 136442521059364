import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const BlankRows = ({ recLength }) => {
  // Determine how many table rows to render based on records.length
  let rowsToRender = null;

  if (recLength === 1) {
    rowsToRender = (
      <>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
      </>
    );
  } else if (recLength === 2) {
    rowsToRender = (
      <>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
      </>
    );
	} else if (recLength === 3) {
    rowsToRender = (
      <>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
      </>
    );
  } else {
    // Default case when records.length !== 2 or 3
    rowsToRender = (
      <></>
    );
  }

  return (
		<>
			{rowsToRender}
		</>
		
  );
}

export default BlankRows;
