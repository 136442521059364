import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FormLabel, Grid, Paper, TableBody, TableCell, TableRow, Toolbar, Typography, makeStyles } from "@material-ui/core";
import CreateModel from "./CreateModel";
import Manufacturing from "./Manufacturing";

// const useStyles = makeStyles((theme) => ({
//   tblCellInside: {
//     fontWeight: "500",
//     color: "#fff",
//     backgroundColor: "#8a0a0b",
//     cursor: "pointer",
//   },
//   pageContent: {
//     margin: theme.spacing(2),
//     padding: theme.spacing(1),
//   },
//   searchInput: {
//     width: "75%",
//   },
//   pageTitle: {
//     [theme.breakpoints.between("xs", "sm")]: {
//       fontSize: "16px",
//     },
//     [theme.breakpoints.between("sm", "md")]: {
//       fontSize: "20px",
//     },
//     [theme.breakpoints.between("md", "lg")]: {
//       fontSize: "24px",
//     },
//     color: theme.palette.secondary.main,
//     // left: "10px",
//     "& .MuiTypography-subtitle2": {
//       opacity: "0.6",
//     },
//     inactiveTabLabel: {
//       color: "#8a0a0b",
//       fontFamily: "Aldrich  !important",
//       textTransform: "none !important",
//       fontSize: "18px !important",

//       "&:hover": {
//         opacity: "1", // Change opacity on hover
//       },
//     },
//     activeIndicator: {
//       backgroundColor: "#8a0a0b !important",
//       color: "#8a0a0b !important",

//       // Set the underline color for active tab
//     },
//   },
//   actionButtonCell: {
//     textAlign: "right",
//     paddingRight: "10px",
//   },
//   indicator: {
//     backgroundColor: "red !important", // Set the underline color for active tab
//   },
//   searchIcon: {
//     color: theme.palette.secondary.main,
//     cursor: "pointer",
//   },
//   tabLabel: {
//     color: "#8a0a0b",
//     fontFamily: "Aldrich",
//     textTransform: "none",
//     fontSize: "20px",

//     "&.Mui-selected": {
//       color: "#8a0a0b !important",
//       fontWeight: 400,
//       fontFamily: "Aldrich",
//       textTransform: "none",
//       fontSize: "18px",
//     },
//   },
//   headerContainer: {
//     padding: "18px 0",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   filterIcon: {
//     color: theme.palette.secondary.main,
//     cursor: "pointer",
//   },
//   filterContainer: {
//     [theme.breakpoints.between("xs", "sm")]: {
//       textAlign: "end",
//       alignItems: "center",
//     },
//     [theme.breakpoints.between("sm", "md")]: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       gap: 10,
//     },
//     [theme.breakpoints.between("md", "lg")]: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       gap: 10,
//     },
//   },
// }));
const useStyles = makeStyles(theme => ({
  pageContent: {
      margin: theme.spacing(2),
      padding: theme.spacing(1)
  },
  searchInput: {
      width: '75%'
  },
  newButton: {
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
      color: theme.palette.secondary.main,
      position: 'absolute',
      left: '10px',
      '& .MuiTypography-subtitle2': {
          opacity: '0.6'
      }
  },
  inactiveTabLabel: {
      color: '#8a0a0b',
      fontFamily: 'Aldrich  !important',
      textTransform: 'none !important',
      fontSize: '18px !important',

      '&:hover': {
          opacity: '1' // Change opacity on hover
      }
  },
  activeIndicator: {
      backgroundColor: '#8a0a0b !important' ,
      color: '#8a0a0b !important',

      // Set the underline color for active tab
  },
  actionButtonCell: {
      textAlign: 'right',
      paddingRight: '10px',
  },
  indicator: {
      backgroundColor: 'red !important', // Set the underline color for active tab
  },
  headerContainer: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  tabLabel: {
      color: '#8a0a0b',
      fontFamily: 'Aldrich',
      textTransform: "none",
      fontSize: "20px",

      '&.Mui-selected': {
          color: '#8a0a0b !important',
          fontWeight: 400,
          fontFamily: 'Aldrich',
          textTransform: "none",
          fontSize: "18px",
      },
  }

}));
export default function ButtonGroupMM() {
  const [value, setValue] = React.useState("1");
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              {/* <TabList onChange={handleChange} aria-label="lab API tabs example" classes={{ indicator: classes.indicator }}>
                                <Tab className={classes.tabLabel} label="Add Manufacturer" value="1" />
                                <Tab className={classes.tabLabel} label="Add Charger Model" value="2" />
                            </TabList> */}

              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                classes={{ indicator: classes.activeIndicator }} // Apply activeIndicator class to the indicator
              >
                <Tab
                  className={value === "1" ? classes.tabLabel : classes.inactiveTabLabel} // Apply different class based on tab state
                  label="Add Manufacturer"
                  value="1"
                />
                <Tab
                  className={value === "2" ? classes.tabLabel : classes.inactiveTabLabel} // Apply different class based on tab state
                  label="Add Charger Model"
                  value="2"
                />
              </TabList>
            </Box>

            <TabPanel value="1">
              <Manufacturing />
            </TabPanel>

            <TabPanel value="2">
              <CreateModel />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
}
