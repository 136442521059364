import { FormLabel, Grid, Paper, TableBody, TableCell, TableRow, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from 'react';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import Controls from "../../components/controls/Controls";
import { Form, useForm } from '../../components/useForm';
import useTable from "../../components/useTable";
import { FetchContext } from '../../context/FetchContext';
import { socketFetch } from "../../services/socket";
import Confirm from '../ChargingStations/Confirm';
import SitesSelect from '../Sites/SitesSelect';
import ChargingPeriod from './ChargingPeriod';
import ChargingStationSelect from './ChargingStationsSelect';
import SiteEVSERadioGroup from './SiteEVSERadioGroup';
import { useIsDesktop } from '../../context/DisplayContext';

const useStyles = makeStyles(theme => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  searchInput: {
    width: '75%'
  },
  newButton: {
    position: 'absolute',
    right: '10px'
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    position: 'absolute',
    left: '10px',
    '& .MuiTypography-subtitle2': {
      opacity: '0.6'
    }
  },
  actionButtonCell: {
    textAlign: 'right',
    paddingRight: '10px',
  },
}));

function formatDateToISOWithMidnight(date) {
  if (!(date instanceof Date)) {
    throw new Error('Input is not a valid JavaScript Date object');
  }

  const luxonDate = DateTime.fromJSDate(date);
  const midnightDate = luxonDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const isoFormattedDate = midnightDate.toISO({ includeOffset: false }) + 'Z';
  return isoFormattedDate;
}

const calculateSecondsFromMidnight = (specificTime) => {

  const startTimeHours = specificTime.getHours()
  const startTimeMinutes = specificTime.getMinutes()
  const startTimeSeconds = specificTime.getSeconds()
  const startTimeMillis = specificTime.getMilliseconds()

  const secondsDifference =
    startTimeHours * 3600 +
    startTimeMinutes * 60 +
    startTimeSeconds +
    Math.round(startTimeMillis / 1000);
  return secondsDifference;
}

const areTimesEqual = (time1, time2) => {
  const luxonTime1 = DateTime.fromJSDate(time1);
  const luxonTime2 = DateTime.fromJSDate(time2);

  return luxonTime1.equals(luxonTime2);
}

const areTimesUpDown = (t1, t2) => {
  const t1hours = t1.hour;
  const t1mins = t1.minute;
  const t1seconds = t1.second;

  const t2hours = t2.hour;
  const t2mins = t2.minute;
  const t2seconds = t2.second;

  if (t1hours > t2hours) {
    return true;
  } else if (t1hours === t2hours) {
    if (t1mins > t2mins) {
      return true;
    } else if (t1mins === t2mins) {
      if (t1seconds > t2seconds) {
        return true;
      }
    }
  }

  return false;
}

const generateChargingSchedulePeriod = (chargingList, scheduledDate) => {
  const responseObject = [];
  const startOfDay = DateTime.fromJSDate(scheduledDate).startOf('day');

  if (chargingList.length === 0) {
    responseObject.push({ startPeriod: 0, limit: 0 });
    return responseObject;
  }

  if (areTimesUpDown(startOfDay, DateTime.fromJSDate(chargingList[0].start_time))) {
    responseObject.push({ startPeriod: 0, limit: chargingList[0].limit });
  } else {
    responseObject.push({ startPeriod: 0, limit: 0 });
  }

  chargingList.forEach((item, index) => {
    const startPeriod = calculateSecondsFromMidnight(item.start_time);
    responseObject.push({ startPeriod, limit: parseInt(item.limit) });

    if (index < chargingList.length - 1) {
      const nextItem = chargingList[index + 1];
      if (!areTimesEqual(item.end_time, nextItem.start_time)) {
        const extraStartPeriod = calculateSecondsFromMidnight(item.end_time);
        responseObject.push({ startPeriod: extraStartPeriod, limit: 0 });
      }
    }
  });

  const lastItem = chargingList[chargingList.length - 1];
  const extraStartPeriod = calculateSecondsFromMidnight(lastItem.end_time)
  responseObject.push({ startPeriod: extraStartPeriod, limit: 0 });

  return Array.from(new Set(responseObject.map(obj => JSON.stringify(obj))))
    .map(str => JSON.parse(str));
}

const convertToOutputList = (inputList) => {
  return inputList.map((item, index, array) => {
    const nextItem = array[index + 1];
    const endPeriod = nextItem ? nextItem.startPeriod : 84600; // Use 84600 for last item
    return [(item.startPeriod / 3600), (endPeriod / 3600), item.limit];
  });
}

const convertToOutputLegendList = (inputList) => {
  return inputList.map((item, index, array) => {
    const nextItem = array[index + 1];
    const endPeriod = nextItem ? nextItem.startPeriod : 84600; // Use 84600 for last item
    return [
      Math.floor(item.startPeriod / 3600).toString() + ":" + ((item.startPeriod - Math.floor(item.startPeriod / 3600)) / 60).toString(),
      Math.floor(endPeriod / 3600).toString() + ":" + ((endPeriod - Math.floor(endPeriod / 3600)) / 60).toString(),
      item.limit];
  });
}

function validateTimeGaps(entries) {
  for (let i = 0; i < entries.length - 1; i++) {
    const currentEndTime = entries[i].end_time;
    const nextStartTime = entries[i + 1].start_time;

    if (nextStartTime < currentEndTime) {
      return false; // Gap condition not met
    }
  }
  return true; // All gaps satisfy the condition
}

function validateTimeCondition(entries) {
  for (let i = 0; i < entries.length; i++) {
    const startTime = entries[i].start_time;
    const endTime = entries[i].end_time;

    if (startTime >= endTime) {
      return false; // Condition not met for at least one object
    }
  }
  return true; // Condition met for all objects
}

const createLoadSitePayload = (list, values) => {

  if (!list || list.length === 0) {
    return null
  }

  return {
    limit: parseInt(values.max_limit),
    site_id: values.site_id,
    cluster: list.map(item => {
      return { charger_id: item.charger_id.id, max_limit: parseInt(item.limit) }
    })
  }
}

const LoadSharing = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [siteEvse, setSiteEvse] = useState("1");
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [chargingSchedulePeriod, setChargingSchedulePeriod] = useState([]);
  const [chargingSchedulePeriodGraphData, setChargingSchedulePeriodGraphData] = useState([]);
  const [chargingSchedulePeriodLegendGraphData, setChargingSchedulePeriodLegendGraphData] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [loaded, setLoaded] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [gapError, setGapError] = useState("");
  const { isDesktop } = useIsDesktop();
  const [payload, setPayload] = useState({});

  const abortController = useRef(new AbortController());

  const [chargingPeriodList, setChargingPeriodList] = useState([{
    limit: null, start_time: null, end_time: null
  }]);


  const [siteProfileList, setSiteProfileList] = useState([{
    limit: null, charger_id: null
  }]);

  let headCells = [
    { id: 'charging_profile_id', label: 'Charging Profile ID' },
    { id: 'charger_id', label: 'Charger ID' },
    { id: 'charging_schedule_period', label: 'Charging Schedule Period' },
  ]
  if (!fetchContext.isView) {
    headCells.push({ id: 'actions', label: '', disableSorting: true });
  }

  const getAllProfiles = async (fetchContext) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        '/get-charging-profile'
      );
      setLoaded(true);
      setProfiles(data.data.data);
    } catch (err) {
      console.log('the err', err);
    }
  };

  const {
    TblContainer,
    TblHead,
  } = useTable(headCells);

  const initialFValues = {
    start_schedule: ''
  }

  const validate = async (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = async (type, payload) => {
    let path = '/setChargingProfile';
    if (type) {
      path = '/clearChargingProfile';
    }

    return socketFetch
      .post(path, payload)
      .then(({ data }) => {
        if (abortController.current.signal.aborted) {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again`,
            type: "error",
          });
          return;
        }

        setLoaded(false);
        getAllProfiles(fetchContext);
        setNotify({
          isOpen: true,
          message: data.message,
          type: "success",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `There is some issue, please try again: ${err.message}`,
          type: "error",
        });
        if (abortController.current.signal.aborted) {
          return;
        }
      });
  };

  const handleSubmitLoadSite = async () => {
    let path = '/setLoadSharing';

    let payloadData = createLoadSitePayload(siteProfileList, values);

    return socketFetch
      .post(path, payloadData)
      .then(({ data }) => {
        if (abortController.current.signal.aborted) {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again`,
            type: "error",
          });
          return;
        }

        setLoaded(false);
        getAllProfiles(fetchContext);
        setNotify({
          isOpen: true,
          message: data.message,
          type: "success",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `There is some issue, please try again: ${err.message}`,
          type: "error",
        });
        if (abortController.current.signal.aborted) {
          return;
        }
      });
  };

  const handleSiteEVSE = (event) => {
    setSiteEvse(event.target.value);
  };

  const handlePeriodChange = (event, index) => {
    let { name, value } = event.target;
    const list = [...chargingPeriodList];
    if (name === 'start_time' || name === 'end_time') {
      value = new Date(value);
    }

    if (name === 'limit') {
      if (value && value > 120) {
        value = 120
      }
      if (value < 0) {
        value = 0
      }
    }
    list[index][name] = value;
    setChargingPeriodList(list);
  };

  const handlePeriodChangeSite = (event, index, id_charger) => {
    let name = event ? event.target.name : null;
    let value = event ? event.target.value : null;

    const list = [...siteProfileList];

    if (name === 'limit') {
      if (value && value > 120) {
        value = 120
      }
      if (value < 0) {
        value = 0
      }
    }

    if (!name || name === 'charger_id') {
      list[index]["charger_id"] = { id: id_charger, label: id_charger };
    } else
      list[index][name] = value;
    // console.log(list)
    setSiteProfileList(list);
  };

  useEffect(() => {
    getAllProfiles(fetchContext);
    if (validateTimeCondition(chargingPeriodList)) {
      if (validateTimeGaps(chargingPeriodList)) {
        setGapError("");
        // setGapErrorSubmit(true);
        if (!chargingPeriodList[chargingPeriodList.length - 1].end_time)
          setIsAddDisabled(true);
        else {
          setChargingSchedulePeriod(generateChargingSchedulePeriod(chargingPeriodList, values.start_date));
          setIsAddDisabled(false);
        }
      } else {
        setIsAddDisabled(true);
        // setGapErrorSubmit(false);
        setGapError("* Time should not be overlapping");
      }
    } else {
      setIsAddDisabled(true);
      // setGapErrorSubmit(false);
      setGapError("* Start time must be before end time");
    }
  }, [chargingPeriodList]);

  useEffect(() => {
    // console.log(siteProfileList)
    siteProfileList.forEach(item => {
      if (!(item.limit && item.charger_id)) {
        setIsAddDisabled(true);
      } else
        setIsAddDisabled(false);
    })

    if (values.max_limit < 0) {
      setValues({ ...values, max_limit: 0 })
    }


  }, [siteProfileList, values]);

  useEffect(() => {
    setChargingSchedulePeriodGraphData(convertToOutputList(chargingSchedulePeriod));
    setChargingSchedulePeriodLegendGraphData(convertToOutputLegendList(chargingSchedulePeriod));
  }, [chargingSchedulePeriod]);

  useEffect(() => {
    if (values) {
      setPayload({ connector_id: values.connector_id, charger_id: values.charger_id ? values.charger_id.id : null, start_date: values.start_date ? formatDateToISOWithMidnight(values.start_date) : null, charging_schedule_period: chargingSchedulePeriod })
    }
  }, [values, chargingSchedulePeriod])

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            {/* <Typography variant="h5" component="div"> */}
              Multi Stack Load Management
              {/* </Typography> */}
          </div>
        </Toolbar>
        <Form onSubmit={(e) => {
          e.preventDefault();
          if (siteEvse === "1")
            handleSubmitLoadSite()
          else
            handleSubmit(false, payload);
        }} 
        // style={{ flexGrow: 1, minWidth: '800px' }}
        >
          <Grid container spacing={0} >
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={12} >
              <SiteEVSERadioGroup
                name="userRole"
                required={true}
                value={siteEvse}
                onChange={handleSiteEVSE}
              />
            </Grid>
            {siteEvse === "1" ?
              <Grid item xs={isDesktop ? 6 :12}>
                <SitesSelect
                  value={values.site_id}
                  error={errors.site_id}
                  required={true}
                  onChange={handleInputChange}
                  style={{ width: "70%" }}
                />
              </Grid> :
              <Grid item xs={isDesktop ? 6 :12}>
                <ChargingStationSelect
                  value={values.charger_id}
                  error={errors.charger_id}
                  required={true}
                  onChange={(e) => {
                    setValues({ ...values, charger_id: e })
                  }}
                  style={{ width: "90%" }}
                />
              </Grid>}

            {siteEvse === "1" ?
              <Grid item xs={isDesktop ? 6 :12} >
                <Controls.Input
                  name="max_limit"
                  label="Site Limit"
                  required={true}
                  type="number"
                  max={1000}
                  value={values.max_limit}
                  onChange={handleInputChange}
                  style={{ width: "80%" }}
                />
              </Grid>
              : <><Grid item xs={isDesktop ? 6 :12} >
                <Controls.DatePicker
                  name="start_date"
                  label="From Date"
                  value={values.start_date ? values.start_date : null}
                  onChange={handleInputChange}
                  error={errors.start_date}
                  required={true}
                  //fullWidth
                  style={{ width: "98%" }}
                />
              </Grid>
                <Grid item xs={isDesktop ? 6 :12} >
                  <Controls.Select
                    label="Connector ID"
                    options={[{
                      "id": 1,
                      "name": 1
                    }, {
                      "id": 2,
                      "name": 2
                    }, {
                      "id": 3,
                      "name": 3
                    }, {
                      "id": 4,
                      "name": 4
                    }
                    ]}
                    name="connector_id"
                    required={true}
                    value={values.connector_id}
                    onChange={handleInputChange}
                    style={{ width: "80%" }}
                  >
                  </Controls.Select>
                </Grid></>}
            <Grid item xs={12}>&nbsp;</Grid>

            {siteEvse === "1" ? <>
              <Grid item xs={12}>
                <FormLabel>&nbsp;&nbsp;<b>Chargers</b></FormLabel></Grid>

              {siteProfileList.map((x, i) => {
                return <>
                  <Grid item xs={isDesktop ? 6 :12} >
                    <Controls.Input
                      name="limit"
                      label="Limit"
                      required={true}
                      type="number"
                      max={1000}
                      value={x.limit}
                      onChange={(e) => handlePeriodChangeSite(e, i)}
                      style={{ width: "80%" }}
                    />
                  </Grid>
                  <Grid item xs={isDesktop ? 6 :12}>
                    <ChargingStationSelect
                      name="charger_id"
                      required={true}
                      value={x.charger_id}
                      onChange={(e) => handlePeriodChangeSite(null, i, e.label)}
                      style={{ width: "90%" }}
                    />
                  </Grid>
                  <Grid item xs={isDesktop ? 6 :12}>
                    {siteProfileList.length !== 1 ?
                      <Controls.Button
                        text="X" onClick={(e) => setSiteProfileList(siteProfileList.filter((item, index) => i !== index))} />
                      : null}
                  </Grid>
                  <Grid item xs={3}></Grid>
                </>
              })}

              {siteEvse === "1" ? <></> : <p className="text-danger">{gapError}</p>}

              <Grid item xs={12}>
                <Controls.Button
                  text="Add" disabled={isAddDisabled} onClick={(e) => {
                    e.preventDefault()
                    setSiteProfileList([...siteProfileList, { limit: 0, charger_id: { id: null, label: null } }])
                  }} />
              </Grid>

              <br />
              <br />
              <br />
              <br />
              {/* <Grid item xs={12} >
              <FormLabel>&nbsp;&nbsp;<b>Chart Preview</b></FormLabel>
            </Grid> */}
              {siteEvse === "1" ? <></> : <ChargingPeriod
                chargingSchedulePeriodGraphData={chargingSchedulePeriodGraphData}
                chargingSchedulePeriod={chargingSchedulePeriodLegendGraphData} />}

            </> : null}

            {siteEvse !== "1" && values.start_date ? <>
              <Grid item xs={12}>
                <FormLabel>&nbsp;&nbsp;<b>Define Charging Periods</b></FormLabel></Grid>

              {chargingPeriodList.map((x, i) => {
                return <>
                  <Grid item xs={3} >
                    <Controls.Input
                      name="limit"
                      label="Limit"
                      required={true}
                      type="number"
                      max={1000}
                      value={x.limit}
                      onChange={(e) => handlePeriodChange(e, i)}
                      style={{ width: "80%" }}
                    />
                  </Grid>
                  <Grid item xs={3} >
                    <Controls.TimePicker
                      name="start_time"
                      label="From"
                      value={x.start_time ? x.start_time : null}
                      onChange={(e) => handlePeriodChange(e, i)}
                      required={true}
                      //fullWidth
                      style={{ width: "98%" }}
                    />
                  </Grid>
                  <Grid item xs={3} >
                    <Controls.TimePicker
                      name="end_time"
                      label="To"
                      //minDate={minDate}
                      value={x.end_time ? x.end_time : null}
                      required={true}
                      onChange={(e) => handlePeriodChange(e, i)}
                      style={{ width: "98%" }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {chargingPeriodList.length !== 1 ?
                      <Controls.Button
                        text="X" onClick={(e) => setChargingPeriodList(chargingPeriodList.filter((item, index) => i !== index))} />
                      : null}
                  </Grid>
                </>
              })}

              <p className="text-danger">{gapError}</p>

              <Grid item xs={12}>
                <Controls.Button
                  text="Add" disabled={isAddDisabled} onClick={(e) => {
                    e.preventDefault()
                    setChargingPeriodList([...chargingPeriodList, { limit: 0, start_time: chargingPeriodList[chargingPeriodList.length - 1].end_time, end_time: null }])
                  }} />
              </Grid>

              <br />
              <br />
              <br />
              <br />
              {/* <Grid item xs={12} >
              <FormLabel>&nbsp;&nbsp;<b>Chart Preview</b></FormLabel>
            </Grid> */}
              <ChargingPeriod
                chargingSchedulePeriodGraphData={chargingSchedulePeriodGraphData}
                chargingSchedulePeriod={chargingSchedulePeriodLegendGraphData} />

            </> : null}
            <Grid item xs={12}>
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit" />
                <Controls.Button
                  text="Clear"
                  color="default"
                  onClick={(e) => {
                    e.preventDefault();
                    setChargingPeriodList([{
                      limit: 0, start_time: null, end_time: null
                    }])
                    setValues({
                      connector_id: null,
                      charger_id: null,
                      start_date: null
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Form>

        <TblContainer>
          <TblHead />
          <TableBody>
            {loaded ?
              profiles.map((item) => <TableRow>
                <TableCell>{item.charging_profile_id}</TableCell>
                <TableCell>{item.charger_id}</TableCell>
                <TableCell>{item.charging_schedule_period}</TableCell>
                {!fetchContext.isView &&
                  <TableCell class="text-nowrap">
                    <Confirm
                      id={item.charging_profile_id}
                      title={'Are you sure to clear this profile?'}
                      subTitle={"You can't undo this operation"}
                      onConfirmClick={() => {
                        handleSubmit(true, { charger_id: item.charger_id, charging_profile_id: item.charging_profile_id, connector_id: item.connector_id ? item.connector_id : 1 })
                      }}
                    />

                  </TableCell>
                }
              </TableRow>)
              : <Loader />}
          </TableBody>
        </TblContainer>

      </Paper>
    </>
  )
}

export default LoadSharing;