import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import FeeTypeSelect from "./FeeTypeSelect";
import ParkingTypeSelect from "./ParkingTypeSelect";
import { useIsDesktop } from "../../context/DisplayContext";
import WeeklySchedule from "./WeeklySchedule";

// const useStyles = makeStyles(theme => ({
//   input: {
//     "&:invalid": {
//       padding: '2px',
//       border: "red solid 1px"
//     }
//   },
// }));

const PricesForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm,editslot,setEditslot } = props;
  const { isDesktop } = useIsDesktop();

  //const classes = useStyles();
  const initialFValues = {
    id: 0,
    name: "",
    fee_type: recordForEdit !== null ? recordForEdit.fee_type : "",
    parking_fee_unit:
      recordForEdit !== null ? recordForEdit.parking_fee_unit : "",
    //parking_fee: recordForEdit !== null ? recordForEdit.parking_fee : '',
  };
  // const [ selected, setSelected ] = useState((recordForEdit !== null ? recordForEdit.type : ''));
  // const [ selectedLable, setSelectedLable ] = useState('Authentication Tag');
  // const [ disabledChargingStation, setDisabledChargingStation ] = useState(false);
  // const [ disabledSite, setDisabledSite ] = useState(false);
  // ^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$  ---- decimal value greater than zero
  // ^(?:[1-9]|[12]\d)\d*(?:\.\d{1,2})?\s*$     ---- decimal value greater than or equal to 1
  //-- /^[a-zA-Z\d\s]*$/
  // ^(?:[1-9]|[12]\d)\d*$  ----  value greater than or equal to 1
  //----  /^\d*\.?\d{0,2}$/ working
  //https://stackoverflow.com/questions/9038522/regular-expression-for-any-number-greater-than-0
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = /^[a-zA-Z\d\s]*$/.test(fieldValues.name)
        ? ""
        : "Please enter correct price plan name";
    if ("fixed_fee" in fieldValues)
      //temp.fixed_fee = (/^[0-9]*\.?[0-9]{2}$/).test(fieldValues.fixed_fee)  ?  "" : "Please provide correct value for Fixed Fee."
      temp.fixed_fee = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/.test(
        fieldValues.fixed_fee
      )
        ? ""
        : "Please provide correct value for Fixed Fee.";
    if ("auth_amount" in fieldValues)
      temp.auth_amount = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(
        fieldValues.auth_amount
      )
        ? ""
        : "Please provide correct value for Auth Amount.";
    //temp.auth_amount = (/^(?:[1-9]|[12]\d)\d*(?:\.\d{1,2})?\s*$/).test(fieldValues.auth_amount)  ?  "" : "Please provide correct value for Auth Amount."
    if ("variable_fee" in fieldValues)
      temp.variable_fee = /^\s*(?=.*[1-9])\d*(?:\.\d{1,4})?\s*$/.test(
        fieldValues.variable_fee
      )
        ? ""
        : "Please provide correct value for Variable Fee.";
    if ("parking_fee" in fieldValues)
      //temp.parking_fee = (/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/).test(fieldValues.parking_fee) || fieldValues.parking_fee === "" || fieldValues.parking_fee === null ?  "" : "Please provide correct value for Parking Fee."
      temp.parking_fee =
        /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/.test(fieldValues.parking_fee) ||
        fieldValues.parking_fee === "" ||
        fieldValues.parking_fee === null
          ? ""
          : "Please provide correct value for Parking Fee.";

    // if ('buffer_time' in fieldValues)
    //     temp.buffer_time = (/^(?:[1-9]|[12]\d)\d*$/).test(fieldValues.buffer_time)  ?  null : "Please provide correct value for Buffer Time."
    // if ('buffer_time' in fieldValues)
    //     temp.buffer_time = (/^[^.]*$/).test(fieldValues.buffer_time)  ?  "" : "Please provide correct value for Buffer Time."

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const [scheduleErrors, setScheduleErrors] = useState(false);
  
const initialWeekSchedule = recordForEdit?.weekly_variable_fee?.reduce((acc, slot) => {
  const { day, start_time, end_time, variable_fee, id } = slot;
  const formattedSlot = {
    id,
    time: `${start_time} - ${end_time}`,
    fee: `$${variable_fee}`,
  };

  if (!acc[day]) acc[day] = [];
  acc[day].push(formattedSlot);

  return acc;
}, { Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: [] });

const [weekSchedule, setWeekSchedule] = useState(initialWeekSchedule || { Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: [] });
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleScheduleError = (hasError) => {
    setScheduleErrors(hasError);
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    if (scheduleErrors) {
      return;
    }

    if (validate()) {
      const payload = { ...values };
      if (!payload.parking_fee) {
        payload.parking_fee_unit = "NONE";
      }
      addOrEdit(payload, resetForm,weekSchedule);
    } else {
      if (values.parking_fee_unit && values.parking_fee_unit !== "NONE") {
        addOrEdit(values, resetForm,weekSchedule);
      }
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      // if(recordForEdit.buffer_time && recordForEdit.buffer_time === null ){
      //   recordForEdit.buffer_time = undefined;
      // }
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        // style={{ flexGrow: 1, minWidth:'890px' }}
      >
        <Grid container fullWidth>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="name"
              label="Name"
              required
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="fixed_fee"
              label="Session Fee ($)"
              required
              //type="number"
              //inputProps={{ min: 0 }}
              value={values.fixed_fee}
              onChange={handleInputChange}
              error={errors.fixed_fee}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="auth_amount"
              label="Auth Amount ($)"
              required
              //type="number"
              //inputProps={{ min: 0 }}
              value={values.auth_amount}
              onChange={handleInputChange}
              error={errors.auth_amount}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <FeeTypeSelect
              label="Fee Type"
              name="fee_type"
              value={values.fee_type}
              error={errors.fee_type}
              required
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="variable_fee"
              label="Variable Fee ($)"
              required
              //type="number"
              //inputProps={{ min: 0 }}
              value={values.variable_fee}
              onChange={handleInputChange}
              error={errors.variable_fee}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="parking_fee"
              label="Parking Fee ($)"
              //required
              //type="number"
              //inputProps={{ min: 0 }}
              value={values.parking_fee}
              onChange={handleInputChange}
              error={errors.parking_fee}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <ParkingTypeSelect
              label="Parking Fee Unit"
              name="parking_fee_unit"
              value={values.parking_fee_unit}
              error={errors.parking_fee_unit}
              required={!!values.parking_fee} // Make required if parking fee is provided
              onChange={handleInputChange}
              disabled={!values.parking_fee} // Disable if parking fee is not provided
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="buffer_time"
              label="Buffer Time (Min)"
              //required
              type="number"
              inputProps={{ min: 0 }}
              value={values.buffer_time}
              onChange={handleInputChange}
              error={errors.buffer_time}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Submit" />
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={closeForm}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
          <WeeklySchedule
            onScheduleError={handleScheduleError}
            weekSchedule={weekSchedule}
            setWeekSchedule={setWeekSchedule}
            setEditslot={setEditslot}
            editslot={editslot}
            handleSubmit={handleSubmit}
          />
    </>
  );
};

export default PricesForm;
