import React, { useState, useContext, useEffect } from 'react';
import { FetchContext } from '../../context/FetchContext';
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../components/controls/Controls";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
formControl: {
  margin: theme.spacing(1),
  width: "20vw"
},
selectEmpty: {
  marginTop: theme.spacing(2)
},
select: {
  //height: "10vh"
},
inputLabel: {
  //fontSize: "4vh",
  alignSelf: "center"
}
}));

const SitesSelect = (props) => {
  const classes = useStyles();
  //const connectorType = ['CHADEMO', 'DOMESTIC_A', 'DOMESTIC_B', 'DOMESTIC_C', 'DOMESTIC_D', 'DOMESTIC_E', 'DOMESTIC_F', 'DOMESTIC_G', 'DOMESTIC_H', 'DOMESTIC_I', 'DOMESTIC_J', 'DOMESTIC_K', 'DOMESTIC_L', 'IEC_60309_2_single_16', 'IEC_60309_2_three_16', 'IEC_60309_2_three_32', 'IEC_60309_2_three_64', 'IEC_62196_T1','IEC', 'IEC_62196_T1_COMBO', 'IEC_62196_T2','IEC', 'IEC_62196_T2_COMBO', 'IEC_62196_T3A','IEC', 'IEC_62196_T3C','IEC', 'PANTOGRAPH_BOTTOM_UP'];
  const { value, error=null, onChange  } = props;

  const fetchContext = useContext(FetchContext);
  const [ values, setValues ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
      const getAllChargingStations = async () => {
          try {
            const { data } = await fetchContext.authAxios.get(
              '/select-sites'
            );
            setValues(data.data);
            setLoaded(true);
          } catch (err) {
            console.log('the err', err);
          }
      };

      getAllChargingStations(fetchContext);

  }, [ fetchContext ]);

  return (
    <>
    {loaded 
      ? 
        <FormControl variant="standard" className={classes.formControl}
        style={{ width: "95%" }}
        {...(error && {error:true})}>
            <InputLabel className={classes.inputLabel}>Sites</InputLabel>
            <MuiSelect
                className={classes.select}
                variant="standard"
                label="Sites"
                name="site_id"
                value={value}
                //disabled={disabled}
                //options={values}
                //fullWidth={fullWidth}
                onChange={onChange}>  
                <MenuItem value='None'>None</MenuItem>  
                {
                  values.map( (item) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))
                }            
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      :
      <Controls.Input name="site_id" label="Sites" style={{ width: "95%" }}> </Controls.Input>
    }
    </>
  )
}

export default SitesSelect;
