import { Grid, makeStyles } from "@material-ui/core";
import React, { useRef, useState } from "react";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Notification from "../../components/Notification";
import FirmwareFileSelector from "./FirmwareFileSelector";
import { socketFetch } from "../../services/socket";
import { DateTime } from "luxon";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  button: {
    marginRight: theme.spacing(1), // Adjust spacing as needed
  },
  icon: {
    marginRight: theme.spacing(1), // Adjust spacing as needed
  },
  fileDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: theme.spacing(1),
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  fileDetailItem: {
    marginRight: theme.spacing(1), // Add space between paragraphs
  },

  highlightedText: {
    fontWeight: "bold", // Make text bold
    color: theme.palette.secondary.main, // Change text color to blue (you can adjust this as needed)
  },
  buttonAlign: {
    display: "flex",
    gap: "18px",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "10px",
  },
  uploadedText: {
    marginLeft: "10px",
    paddingTop: "6px",
  },
  linearProgress: {
    width: "500px",
    marginLeft: "10px",
  },
  fileError: {
    color: "red",
  },
  uploadFile: {
    display: "none",
  },
  upFirmareContainer: {
    [theme.breakpoints.between("sm", "md")]: {
      width: "550px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "800px",
    },
  },
}));

export default function FirmwareUpdateModal({ closeForm, selectedEntry }) {
  const classes = useStyles();
  const { isDesktop } = useIsDesktop();
  const abortController = useRef(new AbortController());
  const initialFValues = {
    firmware_files_id: undefined,
    password_data: null,
  };

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password_data" in fieldValues) 
      temp.password_data = fieldValues.firmware_files_id && fieldValues.password_data && (fieldValues.firmware_files_id.password !== fieldValues.password_data) ? "Your password is incorrect" : "";

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = async () => {
    const currentDateTimeUTCString = DateTime.utc().toISO({ includeOffset: true, suppressMilliseconds: true });
    let filePayload = {
      id: values.firmware_files_id.id,
      charger_id: selectedEntry?.ocpp_cbid,
      location: values.firmware_files_id.file_url,
      date: currentDateTimeUTCString,
      password: values.password_data
    }
    if (validate()) {
      closeForm();
      await socketFetch
      .post(`/updatefirmware`, filePayload)
      .then(() => {
        if (abortController.current.signal.aborted) {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again`,
            type: "error",
          });
          closeForm();
          return;
        }
        setNotify({
          isOpen: true,
          message: `Firmware file has been updated successfully`,
          type: "success",
        });
        closeForm();
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `There is some issue, please try again: ${err.message}`,
          type: "error",
        });
        closeForm();
        if (abortController.current.signal.aborted) {
          return;
        }
      });
    }
  };

  

  return (
    <div>
      <Notification notify={notify} setNotify={setNotify} />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid container spacing={0} className={classes.upFirmareContainer}>
          <Grid item xs={isDesktop ? 6 : 12}>
            <FirmwareFileSelector
              label="Firmware File"
              name="firmware_files_id"
              value={values.firmware_files_id}
              error={errors.firmware_files_id}
              required={true}
              filter={true}
              style={{ width: "95%" }}
              onChange={handleInputChange}
              selectedEntry={selectedEntry}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="password_data"
              label="Password"
              required={true}
              type="password"
              value={values.password_data}
              error={errors.password_data}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Submit" />
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={closeForm}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
