import axios from 'axios';

const socketFetch = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_URL,
  // baseURL: 'https://socketprod.rede.network',
  headers: {
    "Content-type": "application/json"
  }
});

export { socketFetch };
