import React, { useState, useContext, useEffect } from 'react';
import { FetchContext } from '../../context/FetchContext';
import Controls from "../../components/controls/Controls";
import { CircularProgress } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center"
  }
}));


const ManufacturersSelect = (props) => {
  const { value = '', error = null, onChange } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          '/select-manufacturers'
        );
        setValues(data.data);
        setLoaded(true);
      } catch (err) {
        console.log('the err', err);
      }
    };

    getAllSites(fetchContext);

  }, [fetchContext]);

  return (
    <>
      {props.filter ?
        (loaded
          ?
          <FormControl variant="standard" className={classes.formControl}
            style={{ width: "95%" }}
            {...(error && { error: true })}>
            <InputLabel className={classes.inputLabel}>Manufacturers</InputLabel>
            <MuiSelect
              className={classes.select}
              variant="standard"
              label="Manufacturers"
              name="manufacturer_id"
              value={value}
              required={props.required}
              onChange={onChange}>
              <MenuItem value='None'>None</MenuItem>
              {
                values.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))
              }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
          :
          <Controls.Input name="manufacturer_id" label="Manufacturers" style={{ width: "95%" }}><CircularProgress size="1rem" /> </Controls.Input>
        )
        : (loaded
          ?
          <Controls.Select
            name="manufacturer_id"
            label="Manufacturers"
            value={value}
            style={{ width: "95%" }}
            required={props.required}
            onChange={onChange}
            options={values}
            error={error}
          />
          :
          // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
          <Controls.Input name="manufacturer_id" label="Manufacturers" style={{ width: "95%" }}><CircularProgress size="1rem" /> </Controls.Input>
        )}



    </>

  )
}

export default ManufacturersSelect;
