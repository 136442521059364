import React from "react";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Divider from "@mui/material/Divider";
import { useIsDesktop } from "../../context/DisplayContext";

const Filter = (props) => {
  const { onFilter, oncancle, filterObject, setFilterObject } = props;

  const initialFValues = {
    search_keyword: filterObject.search_keyword,
    is_search: true,
    export: false,
    from_date: null,
    to_date: null,
  };

  const validate = (values) => {};

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);
  const { isDesktop } = useIsDesktop();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterObject(values);
    onFilter(values);
  };

  return (
    // <Box sx={{ flexGrow: 1 }}>
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Divider style={{ width: "100%", color: "#808080" }} />
        {!props.showSearch ? (
          <Grid item xs={isDesktop ? 3 : 12}>
            <Controls.Input name="search_keyword" label="Search" value={values.search_keyword} onChange={handleInputChange} style={{ width: "95%" }} />
          </Grid>
        ) : (
          <>
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.DatePicker
                name="from_date"
                label="From Date"
                value={values.from_date}
                onChange={handleInputChange}
                error={errors.from_date}
                //fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.DatePicker
                name="to_date"
                label="To Date"
                minDate={values.from_date}
                value={values.to_date}
                onChange={handleInputChange}
                error={errors.to_date}
                //fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={isDesktop ? 3 : 12}>
          <div>
            <Controls.Button type="submit" text={!props.showSearch ? "Search" : "Filter"} size="small" />
            <Controls.Button text="Cancel" color="default" onClick={oncancle} size="small" />
          </div>
        </Grid>
      </Grid>
    </Form>
    // </Box>
  );
};

export default Filter;
