import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Divider from "@mui/material/Divider";
import { DateTime } from "luxon";
import { useIsDesktop } from "../../context/DisplayContext";
import SitesSelect from "./SessionBySite/SitesSelect";
import SitesGroupSelect from "./SessionBySite/SitesGroupSelect";
import ChargerGroupSelect from "./SessionBySite/ChargerGroupSelect";
import { FetchContext } from "../../context/FetchContext";

const UpTimeFilter = (props) => {
  const { onFilter, oncancle, filterObject, setFilterObject } = props;
  const { isDesktop } = useIsDesktop();
  const [chargerGroupData, setChargerGroupData] = useState([]);
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [ withMax, setWithMax ] = useState(false);

  const initialFValues = {
    ///"with_max_uptime": withMax,
    "ocpp_cbid":filterObject.ocpp_cbid,
    "from_date":filterObject.from_date,
    "to_date":filterObject.to_date,  
  };

  const validate = (values) => {};

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    Object.assign(values, {
      max_uptime: withMax      
    });
    //setFilterObject(values);
    onFilter(values);
  };

  const onMaxUptime = (event) => {
    setWithMax((toggle) => !toggle);
    //console.log('event.target.value: ', event.target.value);
  };

  return (
    // <Box sx={{ flexGrow: 1 }}>
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Divider style={{ width: "100%", color: "#808080" }} />

          <Grid item xs={isDesktop ? 3 : 12}>
            <Controls.Input
              name="ocpp_cbid"
              label="OCPP CBID"
              //required
              //disabled={withMax}
              value={values.ocpp_cbid}
              onChange={handleInputChange}
              error={errors.ocpp_cbid}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Controls.DatePicker
              name="from_date"
              label="From Date"
              disabled={withMax}
              value={values.from_date}
              onChange={handleInputChange}
              error={errors.from_date}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Controls.DatePicker
              name="to_date"
              label="To Date"
              disabled={withMax}
              minDate={values.from_date}
              value={values.to_date}
              // maxDate={DateTime.fromJSDate(values.from_date).plus({ months: 2 }).toJSDate()}
              onChange={handleInputChange}
              error={errors.to_date}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 3 : 12}>
            <Controls.Checkbox
              name="with_max_uptime"
              label="With 100% Uptime"
              value={withMax}
              onChange={onMaxUptime}
              style={{ width: "95%" }}
            />
          </Grid>
        <Grid item xs={isDesktop ? 3 : 12}>
          <div>
            <Controls.Button type="submit" text={"Filter"} size="small" />
            <Controls.Button text="Cancel" color="default" onClick={oncancle} size="small" />
          </div>
        </Grid>
        <Grid item xs={12}>&nbsp;</Grid>
        <Divider style={{ width: "100%", color: "#808080" }} />
      </Grid>
    </Form>
    // </Box>
  );
};

export default UpTimeFilter;
