import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../../context/FetchContext";
import useTable from "../../../components/useTable";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
//import Popup from "../../components/Popup";
import Loader from "../../../components/Loader";
//import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { Paper, Table, TableBody, TableCell, TableContainer, Tooltip, Toolbar, Typography, TableHead, TableRow } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ExpandTable } from "../../../components/controls/ExpandTable";
import { makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { ArrowBack } from "@material-ui/icons";
import ExportDataGreenhouseGas from "./ExportDataGreenhouseGas";
import TimeZone from "../../../components/controls/Timezone";
import { useParams, useHistory } from "react-router-dom";
import { formatMaxPower } from "../../../components/FormatMaxPower";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  exportIcon: {},
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  headerContainer: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
}));

const SustainabilityDetails = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const history = useHistory();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  //const [openPopup, setOpenPopup] = useState(false)
  //const [transactionId, setTransactionId] = useState(0)
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [isFilter, setIsFilter] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  //const { type } = useParams();
  const { id } = useParams();
  const { csid } = useParams();
  const { bymonth } = useParams();
  const [totalRecord, setTotalRecord] = useState(0);


  const incorrectDate = bymonth.split("-");
  const correctDate = `${incorrectDate[2]}-${incorrectDate[1]}-${incorrectDate[0]}`;

  const sessionMonthYear = DateTime.fromSQL(correctDate).toFormat("LLLL yyyy");

  const headCells = [
    { id: "", label: "" },
    { id: "id", label: "ID" },
    { id: "charging_station", label: "Charging Station" },
    { id: "site", label: "Site" },
    { id: "connector_id", label: "CID" },
    { id: "energy_delivered", label: "Energy Delivered (KWH)" },
    { id: "greenhouse_gas", label: "GreenhouseGas" },
    { id: "petroleum_displacement", label: "Petroleum Displacement" },
    { id: "status", label: "Status" },
    { id: "session_start", label: "Session Start" },
    { id: "session_end", label: "Session End" },
    { id: "duration", label: "Duration" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, siteId, csId, byMonth, filterDate, isFilter) => {
    let finalPayload = isFilter
      ? {
          site_id: siteId,
          charging_station_id: csId,
          date: null,
          export: false,
          range: isFilter,
          to_date: filterDate?.to_date,
          from_date: filterDate?.from_date,
        }
      : {
          site_id: siteId,
          charging_station_id: csId,
          date: byMonth,
          export: false,
          range: isFilter,
        };

    try {
      //get-green-house-gas-by-site-id-charging-station-id
      const { data } = await fetchContext.authAxios.post(`/get-green-house-gas-by-site-id-charging-station-id?page=${page}`, finalPayload);
      setRecords(data.data.data);
      setTotalRecord(data.data.total);
      setSiteName(data.site_name);
      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  // useEffect(() => {
  //     getAll(fetchContext, page, id, csid, bymonth );
  // }, [ fetchContext, page, id, csid, bymonth ]);

  useEffect(() => {
    if (bymonth.includes("to")) {
      // let datePattern = /\d{2}-\d{2}-\d{4}/g;
      let filterString = bymonth.split("to");
      if (filterString?.length === 2) {
        setToDate(filterString[1]);
        setFromDate(filterString[0]);
        let dates = {
          to_date: filterString[1],
          from_date: filterString[0],
        };
        setIsFilter(true);
        getAll(fetchContext, page, id, csid, bymonth, dates, true);
        // getAll(fetchContext, page, id, bymonth, dates, true);
      }
    } else {
      setIsFilter(false);
      getAll(fetchContext, page, id, csid, bymonth, null, false);
      //   getAll(fetchContext, page, id, bymonth, null, false);
    }
  }, [fetchContext, page, id, csid, bymonth]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  // const openInPopup = item => {
  //     setTransactionId(item)
  //     setOpenPopup(true)
  // }
  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Sessions for {siteName} (
              {isFilter
                ? DateTime.fromFormat(fromDate, "dd-MM-yyyy").toFormat("dd LLLL yyyy") + " to " + DateTime.fromFormat(toDate, "dd-MM-yyyy").toFormat("dd LLLL yyyy")
                : sessionMonthYear}
              )
            </Typography>
          </div>
          <div className={classes.filterIcon}>
            <Tooltip title="Browse Back">
              <ArrowBack onClick={() => history.goBack()} />
            </Tooltip>
          </div>
          <div className={classes.exportIcon}>
            <ExportDataGreenhouseGas siteId={id} csId={csid} byMonth={bymonth} isFilter={isFilter} filterDate={{ to_date: toDate, from_date: fromDate }} />
          </div>
        </Toolbar> */}
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>
            Sessions for {siteName} (
            {isFilter
              ? DateTime.fromFormat(fromDate, "dd-MM-yyyy").toFormat("dd LLLL yyyy") + " to " + DateTime.fromFormat(toDate, "dd-MM-yyyy").toFormat("dd LLLL yyyy")
              : sessionMonthYear}
            )
          </div>
          <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              <div className={classes.filterIcon}>
                <Tooltip title="Browse Back">
                  <ArrowBack onClick={() => history.goBack()} />
                </Tooltip>
              </div>
              <div className={classes.exportIcon}>
                <ExportDataGreenhouseGas siteId={id} csId={csid} byMonth={bymonth} isFilter={isFilter} filterDate={{ to_date: toDate, from_date: fromDate }} />
              </div>
            </div>
          </div>
        </div>
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item, index) => (
                <ExpandTable
                  expandedContent={
                    <TableContainer>
                      {/* <ActiveSessionsValues transaction_id={item.id}/> */}
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Plug In</TableCell>
                            <TableCell>Plug Out</TableCell>
                            <TableCell>SOC Start</TableCell>
                            <TableCell>SOC End</TableCell>
                            <TableCell>Type</TableCell>
                            {/* <TableCell>Average Voltage</TableCell>
                                                <TableCell>Max Voltage</TableCell>  
                                                <TableCell>Average Power</TableCell> */}
                            <TableCell>Max Power</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{item.plug_in ? <TimeZone dateTime={item.plug_in} timezone={item.timezone} /> : "--"}</TableCell>
                            <TableCell>{item.plug_out ? <TimeZone dateTime={item.plug_out} timezone={item.timezone} /> : "--"}</TableCell>
                            <TableCell>{item.soc_start}%</TableCell>
                            <TableCell>{item.soc_end}%</TableCell>
                            <TableCell>
                              {item.type}{" "}
                              {item.type === "RFID"
                                ? item?.rfid_tag
                                  ? `(${item?.rfid_tag?.name})`
                                  : ""
                                : item.type === "REMOTE_TRANSACTION"
                                ? item?.ocpi_session
                                  ? `(${item?.ocpi_session?.party_name})`
                                  : `(${item.start_type})`
                                : ""}
                            </TableCell>
                            {/* <TableCell>{item.average_voltage}</TableCell>
                                                <TableCell>{item.max_voltage}</TableCell>   
                                                <TableCell>{item.average_power}</TableCell> */}
                            {/* <TableCell>{item.max_power}</TableCell>  */}
                            {/* {item.max_power > 0 ? <TableCell>{parseInt(item.max_power) / 1000}</TableCell> : <TableCell>0.00</TableCell>} */}
                            <TableCell>{formatMaxPower(item.max_power, item?.manufacturer_id)}</TableCell>

                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  key={index}
                >
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.charging_station_name}</TableCell>
                  <TableCell>{item.site_name}</TableCell>
                  <TableCell>{item.sequence_number}</TableCell>
                  {item.meter_start !== null && item.meter_end !== null ? (
                    <TableCell>{((item.meter_end - item.meter_start) / 1000).toFixed(2)}</TableCell>
                  ) : (
                    <TableCell>0.00</TableCell>
                  )}
                  {item.meter_start !== null && item.meter_end !== null ? (
                    <TableCell>{(((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2)}</TableCell>
                  ) : (
                    <TableCell>0.00</TableCell>
                  )}
                  {item.meter_start !== null && item.meter_end !== null ? (
                    <TableCell>{((item.meter_end - item.meter_start) / 1000 / 6).toFixed(2)}</TableCell>
                  ) : (
                    <TableCell>0.00</TableCell>
                  )}
                  <TableCell>{item.status}</TableCell>
                  {/* <TableCell><SessionStatus transaction_id={item.id} /></TableCell> */}
                  <TableCell>{item.session_start ? <TimeZone dateTime={item.session_start} timezone={item.timezone} /> : "--"}</TableCell>
                  <TableCell>{item.session_end ? <TimeZone dateTime={item.session_end} timezone={item.timezone} /> : "--"}</TableCell>
                  {item.session_start && item.session_end ? (
                    <TableCell>{DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat("hh:mm:ss")}</TableCell>
                  ) : (
                    <TableCell>--</TableCell>
                  )}
                  {/* <TableCell>
                                        <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => { openInPopup(item.id) }}>
                                            <MoreHorizRounded fontSize="small" />
                                        </Controls.ActionButton>
                                    </TableCell>                                   */}
                </ExpandTable>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      {/* <Popup
                title="Meter Values"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <SessionsPopUp transaction_id={transactionId} />
            </Popup> */}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default SustainabilityDetails;
