import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, makeStyles } from "@material-ui/core";
import ReactECharts from "echarts-for-react";
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { FetchContext } from "../../../context/FetchContext";
import useTable from "../../../components/useTable";
import Loader from "../../../components/Loader";
import { useIsDesktop } from "../../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  responsiveChartsChargerGroup: {
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
    backgroundColor: "#fff",
    [theme.breakpoints.between("sm", "md")]: {
      height: "800px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: 800,
    },
  },
}));

const SustainabilityGraph = (props) => {
  const { byMonth, filterObject } = props;
  const fetchContext = useContext(FetchContext);
  const [dataMain, setDataMain] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();
  const { isDesktop } = useIsDesktop();

  const getAll = async (fetchContext, byMonth) => {
    try {
      const { data } = await fetchContext.authAxios.post(`/get-gph-by-site-all`, {
        date: byMonth,
        export: false,
        // range: false,
        ...filterObject,
      });
      //let res = Object.values(data.data);
      if (Boolean(data.data?.length)) {
        setDataMain(data.data.reverse());
      }else{
        setDataMain([]);
      }
      setLoaded(true);
      // if (data?.data?.data?.length > 0) {
      //   let updatedArray = [
      //     data?.data?.data?.map((c) => {
      //       return Number((c?.amount).toFixed(2));
      //     }),
      //     data?.data?.data?.map((c) => {
      //       return Number(c?.energy);
      //     }),
      //     data?.data?.data?.map((item) => {
      //       return item?.timezone_duration ? Number((item.timezone_duration / 60 / item.total).toFixed(2)) : 0;
      //     }),
      //     data?.data?.data?.map((c) => {
      //       return Number(c?.total);
      //     }),
      //   ];


      //   const graphData = ["Amount (USD)", "Energy (KWH)", "Average Charging Duration (Minutes)", "Number of Sessions"].map((name, sid) => {
      //     return {
      //       name,
      //       type: "bar",
      //       stack: "total",
      //       barWidth: "50%",
      //       label: {
      //         show: true,
      //         // formatter: (params) => Math.round(params.value * 1000) / 10 + "%",
      //       },
      //       data: updatedArray[sid],
      //     };
      //   });
      //   setUpdatedGraphData(graphData);
      // }
    } catch (err) {
      console.log("the err", err);
    }
  };

  const headCells = [
    { id: "", label: "" },
    { id: "site_name", label: "Site Name" },
    { id: "amount", label: "Amount (USD)" },
    { id: "energy", label: "Energy (KWH)" },
    { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "", label: "" },
    { id: "", label: "" },
  ];

  const { TblContainer } = useTable(headCells);

  useEffect(() => {
    getAll(fetchContext, byMonth);
  }, [fetchContext, byMonth, filterObject]);

  // useEffect(() => {
  //   if (dataMain) {
  //     let totalFailedItems = 0;
  //     let totalFinishedItems = 0;

  //     dataMain.forEach((item) => {
  //       totalFailedItems += item.Failed;
  //       totalFinishedItems += item.Finished;
  //     });

  //     setTotalFailed(totalFailedItems);
  //     setTotalFinished(totalFinishedItems);
  //   }
  // }, [dataMain]);

  // const grid = {
  //   left: 100,
  //   right: 100,
  //   top: 50,
  //   bottom: 200,
  // };

  //Bar_2
  // const getOption = () => {
  //   const commonTextStyle = {
  //     color: "#000",
  //     fontFamily: "Aldrich",
  //   };
  //   const labelTextStyle = {
  //     fontFamily: "Aldrich",
  //   };

  //   return {
  //     tooltip: {
  //       trigger: "axis",
  //       axisPointer: {
  //         type: "shadow",
  //       },
  //       textStyle: commonTextStyle,
  //     },
  //     legend: {
  //       selectedMode: true,
  //     },
  //     grid,
  //     yAxis: {
  //       type: "value",
  //     },
  //     xAxis: {
  //       type: "category",
  //       axisLabel: {
  //         rotate: -45,
  //       },
  //       data:
  //         dataMain?.length > 0
  //           ? dataMain?.map((c) => {
  //               return c.charger_group_name;
  //             })
  //           : [],
  //     },
  //     series: updatedGraphData,
  //   };
  // };

  //Bar_1
  const getOption = () => {
    const commonTextStyle = {
      color: "#000",
      fontFamily: "Aldrich",
    };
    const labelTextStyle = {
      fontFamily: "Aldrich",
    };

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        textStyle: commonTextStyle,
      },
      legend: {
        textStyle: commonTextStyle,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          textStyle: commonTextStyle,
        },
        axisLabel: {
          rotate: 0,
        },
      },
      yAxis: {
        type: "category",
        data: dataMain ? dataMain.map((item) => item.site_name) : [],
        axisLabel: {
          textStyle: { ...commonTextStyle, fontWeight: "bold", fontSize: 13 },
        },
      },
      series: [
        {
          name: "Greenhouse Gas",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: "#5470C6",
            // color: '#00CC00',
          },
          data: dataMain ? dataMain.map((item) => Number(item.green_gas).toFixed(2)) : [],
        },
        {
          name: "Petroleum Displacement",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          itemStyle: {
            color: "#91CC75",
          },
          emphasis: {
            focus: "series",
          },
          data: dataMain ? dataMain.map((item) => Number(item.petroleum).toFixed(2)) : [],
        },
        {
          name: "Energy (KWH)",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: "#FAC858",
            // color: '#00CC00',
          },
          data: dataMain ? dataMain.map((item) => Number(item.energy).toFixed(2)) : [],
        },
        {
          name: "Average Charging Duration (Minutes)",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          itemStyle: {
            color: "#EE6766",
          },
          emphasis: {
            focus: "series",
          },
          data: dataMain ? dataMain.map((item) => (item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : 0)) : [],
        },
        {
          name: "Number of Sessions",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          itemStyle: {
            color: "#073852",
          },
          emphasis: {
            focus: "series",
          },
          data: dataMain ? dataMain.map((item) => Number(item.total).toFixed(2)) : [],
        },
      ],
    };
  };

  return (
    <>
      <TblContainer>
        {loaded ? (
          <div class="row my-3">
            <div class="col-md">
              <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
                <div class="card-body">
                  {/* <div className="card-header" style={{ background: "#fff" }}>
                        <div class="row">
                          <div class="col-md-6">
                            <strong class="card-title">SESSIONS</strong>{" "}
                          </div>
                          <div class="col-md-6 fw-bold text-end">{graphType} </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 totalkwhleft text-dark">Finished: {totalFinished}</div>
                          <div class="col-md-6 totalkwhright text-dark">Failed: {totalFailed}</div>
                        </div>
                      </div>
                      <div class="clearfix"></div> */}

                  <div className={classes.responsiveChartsChargerGroup}>
                    <ResponsiveContainer>
                      <ReactECharts
                        option={getOption()}
                        style={
                          isDesktop
                            ? {
                                height: "100%",
                                width: "100%",
                                paddingBottom: 100,
                              }
                            : {}
                        }
                      />
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </TblContainer>
    </>
  );
};

export default SustainabilityGraph;
