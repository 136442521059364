import React, { useContext } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { FetchContext } from "../../../context/FetchContext";
import { ImportExport } from "@material-ui/icons";
import { Tooltip } from "@mui/material";

const ExportDataByGreenhouseGas = ({ filterDate, bymonth }) => {
  const fetchContext = useContext(FetchContext);

  const asyncFnComputeData = async () => {
    const dataArr = [];
    const finalPayload = {
      ...filterDate,
      export: true,
      date: bymonth,
    };

    await fetchContext.authAxios.post(`/get-gph-by-site`, finalPayload).then(({ data }) => {
      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {
          //dataObj.sessionID = item.id;
          let obj = {
            site: item.site_name,
            green_gas: Number(item.green_gas).toFixed(2),
            petroleum: Number(item.petroleum).toFixed(2),
            energy: Number(item.energy).toFixed(2),
            duration: item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : "-",
            total: item.total,
            amount: Number(item.amount).toFixed(2),
            address: item.address?.replace(/,/g, ''),
            city: item.city,
            state: item.state,
            postal_code: item.postal_code,
            latitude: item.latitude,
            longitude: item.longitude,
          };
          dataArr.push(obj);
        }
      }
    });
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "site", displayName: "Site Name" },
    { id: "amount", displayName: "Amount (USD)" },
    { id: "green_gas", displayName: "Greenhouse Gas" },
    { id: "petroleum", displayName: "Petroleum Displacement" },
    { id: "energy", displayName: "Energy Delivered (KWH)" },
    { id: "duration", displayName: "Average Charging Duration (Minutes)" },
    { id: "total", displayName: "Number of Sessions" },
    { id: "address", displayName: "Address" },
    { id: "city", displayName: "City" },
    { id: "state", displayName: "State" },
    { id: "postal_code", displayName: "Zipcode" },
    { id: "latitude", displayName: "Latitude" },
    { id: "longitude", displayName: "Longitude" },
  ];

  return (
    <>
      <CsvDownloader
        filename="SustainabilityDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          <ImportExport style={{ fill: "#fff" }} />
        </Tooltip>
      </CsvDownloader>
    </>
  );
};

export default ExportDataByGreenhouseGas;
