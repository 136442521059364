import { TableBody, TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { useForm } from "../../components/useForm";
import useTable from "../../components/useTable";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
    //width: '75%'
  },
}));

function transformData(inputData) {
  const transformedData = [];

  // Helper function to add or update an entry in the transformedData array
  const addOrUpdateEntry = (entryName, status, value) => {
    const existingEntry = transformedData.find(
      (entry) => entry.name === entryName
    );
    if (existingEntry) {
      existingEntry[status === "FINISHED" ? "Finished" : "Failed"] += value;
    } else {
      transformedData.push({
        name: entryName,
        Finished: status === "FINISHED" ? value : 0,
        Failed: status === "FAILED" ? value : 0,
      });
    }
  };

  Object.keys(inputData.data).forEach((status) => {
    if (status !== "ACTIVE") {
      const statusData = inputData.data[status];

      Object.keys(statusData).forEach((transactionType) => {
        const transactionTypeData = statusData[transactionType];

        if (
          transactionType === "FREE" ||
          transactionType === "NAYAX" ||
          transactionType === "RFID"
        ) {
          addOrUpdateEntry(transactionType, status, transactionTypeData);
        } else {
          Object.keys(transactionTypeData).forEach((subType) => {
            if (subType === "OCPI") {
              Object.keys(transactionTypeData[subType]).forEach(
                (ocpiSubType) => {
                  const ocpiValue = transactionTypeData[subType][ocpiSubType];
                  addOrUpdateEntry(
                    `OCPI-${ocpiSubType}`,
                    status,
                    ocpiValue
                  );
                }
              );
            } else {
              addOrUpdateEntry(subType, status, transactionTypeData[subType]);
            }
          });
        }
      });
    }
  });

  return transformedData;
}


const SessionGraphTable = (props) => {
  const { sessionsCountData } = props;
  const classes = useStyles();

  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(futureDate);
  const [endDate, setEndDate] = useState(new Date());
  const [loaded, setLoaded] = useState(false);


  let data01 = [];
  let data02 = [];
  //let allTotal = 0;
  let allSuccess = 0;
  let allFailed = 0;

  const formatDateToDDMMYYYY = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  const headCells = [{ id: "", label: "" }];
  const { TblContainer } = useTable(headCells);

  const [filterData, setFilterData] = useState({
    // range: true,
    from_date: formatDateToDDMMYYYY(startDate),
    to_date: formatDateToDDMMYYYY(endDate),
  });

  const initialFValues = {
    start_date: startDate,
    end_date: endDate,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    //if ('name' in fieldValues)
    //temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    setErrors,
    errors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStartDate(DateTime.fromJSDate(values.start_date));
    setEndDate(DateTime.fromJSDate(values.end_date));
    setFilterData({
      range: true,
      from_date: formatDateToDDMMYYYY(values.start_date),
      to_date: formatDateToDDMMYYYY(values.end_date),
    });
  };

  const onClear = () => {
    setFilterData({
      range: true,
      start_date: null,
      end_date: null,
    });
  };

  useEffect(() => {
    if (sessionsCountData) {
      setLoaded(true);
    }
  }, [sessionsCountData]);


  return (
    <>
      <TblContainer>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tblCellInside}>#</TableCell>
            <TableCell className={classes.tblCellInside}>Success</TableCell>
            <TableCell className={classes.tblCellInside}>&nbsp;</TableCell>
            <TableCell className={classes.tblCellInside}>Failed</TableCell>
            <TableCell className={classes.tblCellInside}>&nbsp;</TableCell>
            {/* <TableCell className={classes.tblCellInside}>Active</TableCell>
              <TableCell className={classes.tblCellInside}>&nbsp;</TableCell> */}
          </TableRow>
        </TableHead>
        {loaded ? (
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={2}>
                {sessionsCountData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.Finished}</TableCell>
                  </TableRow>
                ))}
              </TableCell>
              <TableCell colSpan={2}>
                {sessionsCountData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.Failed}</TableCell>
                  </TableRow>
                ))}
              </TableCell>
              {/* <TableCell colSpan={2}>               
              {data02.map((item, index) => (                
                <>
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                </>
              ))} 
              </TableCell>                  */}
            </TableRow>
          </TableBody>
        ) : (
          <Loader />
        )}
      </TblContainer>
    </>
  );
};

export default SessionGraphTable;
